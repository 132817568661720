import { Button, Stack, Text } from '@chakra-ui/react';
import { Option } from 'common';
import { ISurvey, ISurveyAnswer } from 'interfaces/onlineAssessment';

interface IProps {
  data: ISurvey;
  onNextClick: VoidFunction;
  onSelectOption: (
    option: ISurveyAnswer,
    updateId: string | undefined,
    { multipleSelect }: { multipleSelect: boolean },
    { noneOfTheOptionSelect }: { noneOfTheOptionSelect: number | null },
    { allOfTheOptionSelect }: { allOfTheOptionSelect: number | null }
  ) => void;
  isDisabled?: boolean;
}

const CheckboxOptions = ({
  data,
  onSelectOption,
  onNextClick,
  isDisabled = false,
}: IProps) => {
  const { answers } = data;
  const { options } = data.metadata;
  const noneOfTheAboveOptionSelect = data.metadata.none_of_the_above_option;
  const allOfTheAboveOptionSelect = data.metadata.all_of_the_above_option;

  const isOptionSelected = answers?.length;

  // Disable other options if option like 'None of the above option' is selected
  const selectedNoneOfTheAboveOption = answers?.find(
    (item) => item.id === noneOfTheAboveOptionSelect?.toString()
  );
  const selectedAllOfTheAboveOption = answers?.find(
    (item) => item.id === allOfTheAboveOptionSelect?.toString()
  );

  return (
    <Stack spacing={4}>
      <Text size="bodyTextMedium">Select all that apply</Text>

      {options.map((item) => (
        <Option
          disabled={
            isDisabled ||
            (selectedNoneOfTheAboveOption &&
              item.id !== noneOfTheAboveOptionSelect?.toString()) ||
            (selectedAllOfTheAboveOption &&
              item.id !== allOfTheAboveOptionSelect?.toString())
          }
          isActive={answers?.some((ans) => ans.id === item.id)}
          key={item.id}
          onClick={() =>
            onSelectOption(
              { id: item.id, value: item.value },
              undefined,
              {
                multipleSelect: true,
              },
              { noneOfTheOptionSelect: noneOfTheAboveOptionSelect },
              { allOfTheOptionSelect: allOfTheAboveOptionSelect }
            )
          }
          text={item.value}
        />
      ))}

      <Button
        disabled={isDisabled || !isOptionSelected}
        onClick={onNextClick}
        variant="primaryLg"
      >
        Next
      </Button>
    </Stack>
  );
};

CheckboxOptions.defaultProps = {
  isDisabled: false,
};

export default CheckboxOptions;
