import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { sectionSubtitleFocused } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IMedicalAssistedTreatment } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const TEXT_DEFAULT = 'text.default';

interface Props {
  data: IMedicalAssistedTreatment;
}

const MedicalAssistedTreatment = (props: Props) => {
  const { data } = props;

  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;
  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingBottom={{ base: '64px' }}
        px={{ base: 3.5, md: 0 }}
      >
        <Heading
          sx={{
            ...sectionSubtitleFocused,
            color: TEXT_DEFAULT,
            fontSize: {
              base: '1.5rem', // 24px
              md: '2rem', // 32px
            },
            fontWeight: 'bold',
            marginBottom: {
              base: '24px',
            },
            textAlign: 'center',
          }}
        >
          {/* What does MAT-Rx.com do? */}
          {data?.title1}
        </Heading>
        <Heading
          color="#333333"
          fontSize={{ base: '16px' }}
          fontWeight="400"
          lineHeight={{ base: '28px' }}
          maxWidth="1097px"
          size="heading2"
          textAlign="left"
        >
          {data?.sub_title_1}
        </Heading>
        <Heading
          color="#333333"
          fontSize={{ base: '16px' }}
          fontWeight="400"
          lineHeight={{ base: '28px' }}
          maxWidth="1097px"
          mt="16px"
          size="heading2"
          textAlign="left"
        >
          {data?.sub_title_2}
        </Heading>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingBottom={{ base: '80px' }}
        px={{ base: 3.5, md: 0 }}
      >
        <Heading
          sx={{
            ...sectionSubtitleFocused,
            color: TEXT_DEFAULT,
            fontSize: {
              base: '1.5rem', // 24px
              md: '2rem', // 32px
            },
            fontWeight: 'bold',
            marginBottom: {
              base: '24px',
            },
            textAlign: 'center',
          }}
        >
          {data?.title2}
        </Heading>
        <Heading
          color="#333333"
          fontSize={{ base: '16px' }}
          fontWeight="400"
          lineHeight={{ base: '28px' }}
          maxWidth="1097px"
          size="heading2"
          textAlign="left"
        >
          {data?.sub_title_3}
        </Heading>
        <Box
          display="flex"
          justifyContent="center"
          mt={{ base: '20px', md: '48px' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          <RouterLink
            to={
              isLoggedIn
                ? uiRoutes.patientDashboard.profile
                : uiRoutes.serviceAvailability
            }
          >
            <Button variant="primaryLg">
              <Text as="span">{data?.button_text}</Text>
            </Button>
          </RouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default MedicalAssistedTreatment;
