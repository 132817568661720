import { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Heading } from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import { ISurvey, ISurveyAnswer } from 'interfaces/onlineAssessment';
import { togglePatientDetailFetchFlag } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectLoadingState, setLoadingState } from 'stores/misc';
import { getSession, setSession } from 'utils/storage';

import CheckboxOptions from './CheckboxOptions';
import FollowUpQuestionaire from './FollowUpQuestionaire';
import RadioOptions from './RadioOptions';

const { SURVEY_BEFORE_SIGNUP_LOCAL_KEY } = misc;

interface IProps {
  currentQuestionaire: ISurvey;
  onNextClick: VoidFunction;
  onBackClick: VoidFunction;
  onSelectOption: (
    option: ISurveyAnswer,
    updateId: string | undefined,
    { multipleSelect }: { multipleSelect: boolean },
    { noneOfTheOptionSelect }: { noneOfTheOptionSelect: number | null },
    { allOfTheOptionSelect }: { allOfTheOptionSelect: number | null }
  ) => void;
}

const Questionaire = ({
  currentQuestionaire,
  onNextClick,
  onBackClick,
  onSelectOption,
}: IProps) => {
  const { title, metadata } = currentQuestionaire;
  const {
    is_multiple_select: isMultipleSelect,
    none_of_the_above_option: noneOfTheAboveOption,
    all_of_the_above_option: allOfTheAboveOption,
  } = metadata;

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectLoadingState);

  // set for option with follow up question
  const [activeOption, setActiveOption] = useState<ISurveyAnswer | null>(null);
  const navigate = useNavigate();

  // Refactor this piece of beautiful code
  const onSubmitFollowUpAnswer = async (answer: string) => {
    if (!activeOption?.id) {
      return;
    }

    const option = { ...activeOption, followUpAnswer: answer };

    if (currentQuestionaire.questionId <= misc.PRE_SIGNUP_STEP) {
      onSelectOption(
        option,
        undefined,
        { multipleSelect: isMultipleSelect },
        { noneOfTheOptionSelect: noneOfTheAboveOption },
        { allOfTheOptionSelect: allOfTheAboveOption }
      );
    } else {
      const payload = {
        id: currentQuestionaire.id,
        questionId: currentQuestionaire.questionId.toString(),
        question: currentQuestionaire.question,
        answers: [{ id: option.id, value: option.value }],
        followUpQuestion: option.followUpQuestion,
        followUpAnswer: option.followUpAnswer,
      };

      try {
        dispatch(setLoadingState(true));
        const res = await patientAPI.saveSurvey(payload);
        onSelectOption(
          option,
          res?.data?.id || undefined,
          {
            multipleSelect: isMultipleSelect,
          },
          { noneOfTheOptionSelect: noneOfTheAboveOption },
          { allOfTheOptionSelect: noneOfTheAboveOption }
        );
        dispatch(togglePatientDetailFetchFlag());
      } finally {
        dispatch(setLoadingState(false));

        setActiveOption(null);
      }
    }
  };

  /**
   * Answers for the questions before sign up are persisted in session storage
   * and cleared after signup
   *
   */
  const storeAnswerInLocalStorage = (
    surveyAnswer: ISurveyAnswer,
    meta: { questionId: number }
  ) => {
    const { questionId } = meta;

    const surveyObj = {
      questionId,
      answers: [surveyAnswer],
    };

    let surveyList = getSession(SURVEY_BEFORE_SIGNUP_LOCAL_KEY) as any;

    if (surveyList) {
      try {
        surveyList = JSON.parse(surveyList);
      } catch {
        surveyList = [];
      }
      const surveyIndex = surveyList?.findIndex(
        (item: any) => item.questionId === questionId
      );

      if (surveyIndex === -1) {
        setSession(
          SURVEY_BEFORE_SIGNUP_LOCAL_KEY,
          JSON.stringify([...surveyList, surveyObj])
        );
      } else {
        surveyList[surveyIndex] = { ...surveyObj };
        setSession(SURVEY_BEFORE_SIGNUP_LOCAL_KEY, JSON.stringify(surveyList));
      }
    } else {
      setSession(SURVEY_BEFORE_SIGNUP_LOCAL_KEY, JSON.stringify([surveyObj]));
    }
  };

  // Refactor this piece of beautiful code
  const handleSelectOption = async (
    option: ISurveyAnswer,
    updateId: string | undefined,
    { multipleSelect }: { multipleSelect: boolean },
    { noneOfTheOptionSelect }: { noneOfTheOptionSelect: number | null },
    { allOfTheOptionSelect }: { allOfTheOptionSelect: number | null }
  ) => {
    // Check for High Risk Patient
    const highRiskOption = currentQuestionaire?.metadata?.options?.find(
      (answerOption) => answerOption.is_exit && answerOption.id === option.id
    );
    if (highRiskOption) {
      navigate(
        `${uiRoutes.highRiskPatient}?questionId=${currentQuestionaire.questionId}&answerId=${option.id}`
      );
      return;
    }

    if (multipleSelect) {
      onSelectOption(
        option,
        updateId,
        { multipleSelect },
        { noneOfTheOptionSelect },
        { allOfTheOptionSelect }
      );
      return;
    }

    if (!multipleSelect && option.followUpQuestion) {
      setActiveOption(option);
      return;
    }

    if (currentQuestionaire.questionId <= misc.PRE_SIGNUP_STEP) {
      onSelectOption(
        option,
        updateId,
        { multipleSelect },
        { noneOfTheOptionSelect },
        { allOfTheOptionSelect }
      );
      storeAnswerInLocalStorage(option, {
        questionId: currentQuestionaire.questionId,
      });
    } else {
      const payload = {
        id: currentQuestionaire.id,
        questionId: currentQuestionaire.questionId.toString(),
        question: currentQuestionaire.question,
        answers: [{ id: option.id, value: option.value }],
        followUpQuestion: '',
        followUpAnswer: '',
      };

      try {
        dispatch(setLoadingState(true));
        const res = await patientAPI.saveSurvey(payload);

        dispatch(togglePatientDetailFetchFlag());

        // const currentQuestionIndex = questionaires.findIndex(
        //   (question) => +question.questionId === +currentQuestionaire.questionId
        // );

        // questionaires[currentQuestionIndex].id = res.data?.id ?? undefined;
        // dispatch(setQuestionaires(questionaires));
        onSelectOption(
          option,
          res.data?.id || undefined,
          { multipleSelect },
          { noneOfTheOptionSelect },
          { allOfTheOptionSelect }
        );
      } finally {
        dispatch(setLoadingState(false));
      }
    }
  };

  // Refactor this piece of beautiful code
  const onMultipleSelectNextClick = async () => {
    if (currentQuestionaire.questionId <= misc.PRE_SIGNUP_STEP) {
      onNextClick();
      return;
    }
    const payload = {
      id: currentQuestionaire.id,
      questionId: currentQuestionaire.questionId.toString(),
      question: currentQuestionaire.question,
      answers: currentQuestionaire.answers,
    };

    try {
      dispatch(setLoadingState(true));
      await patientAPI.saveSurvey(payload);
      dispatch(togglePatientDetailFetchFlag());
    } finally {
      dispatch(setLoadingState(false));
      onNextClick();
    }
  };

  const handleBackClick = () => {
    if (activeOption) {
      setActiveOption(null);
      return;
    }

    onBackClick();
  };

  const showGoBackButton =
    currentQuestionaire?.questionId !== 1 &&
    currentQuestionaire?.questionId !== misc.PRE_SIGNUP_STEP + 1;

  return (
    <Container p={0} w="100%">
      {!activeOption?.followUpQuestion && (
        <>
          <Heading size="heading2">{title}</Heading>
          <Box my={4}>
            {isMultipleSelect ? (
              <CheckboxOptions
                data={currentQuestionaire}
                isDisabled={!!isLoading}
                onNextClick={onMultipleSelectNextClick}
                onSelectOption={handleSelectOption}
              />
            ) : (
              <RadioOptions
                data={currentQuestionaire}
                isDisabled={!!isLoading}
                onSelectOption={handleSelectOption}
              />
            )}
          </Box>
        </>
      )}

      {activeOption?.followUpQuestion && (
        <FollowUpQuestionaire
          followUpQuestion={activeOption.followUpQuestion}
          isDisabled={!!isLoading}
          onSubmitFollowUpAnswer={onSubmitFollowUpAnswer}
        />
      )}

      {showGoBackButton && (
        <Box display="flex" justifyContent="center">
          <Button
            disabled={isLoading}
            leftIcon={<ChevronLeft />}
            onClick={handleBackClick}
            variant="primaryLinkLg"
          >
            Go Back
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Questionaire;
