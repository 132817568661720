import { extendedColors } from '../colors';
import { fontWeights } from '../fonts';

const Input = {
  baseStyle: {
    field: {
      color: extendedColors.neutral.darker,
      fontWeight: fontWeights.normal,
      '&::placeholder': {
        color: 'neutral.dark',
        fontWeight: fontWeights.normal,
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: 'sm',
        shadow: 'sm',
        height: '12',
        fontWeight: 'bold',
      },
    },
  },
  variants: {
    filled: {
      field: {
        bg: 'white',
      },
    },
  },
};

export default Input;
