import * as patientAPI from 'api/patient';
import { AppDispatch } from 'stores';
import { setPatient, setUserId } from 'stores/auth';

import { setAuthToken } from './axios';
import { decodeJwtToken } from './jwt';
import { setLocal } from './storage';

export const setAuthState = async ({
  token,
  dispatch,
}: {
  token: string;
  dispatch: AppDispatch;
}) => {
  setLocal('jwtToken', token);

  setAuthToken(token);

  const decodedToken = decodeJwtToken(token);

  const response = await patientAPI.getPatientDetail();

  dispatch(setPatient(response.data));
  dispatch(setUserId(decodedToken.id));
};
