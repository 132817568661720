import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as authAPI from 'api/auth';
import { FormTextField } from 'common';
import FormPasswordField from 'common/form/FormPasswordField';
import uiRoutes from 'constants/uiRoutes';
import { IResetPasswordForm, IResetPasswordSchema } from 'interfaces/auth';
import { IError } from 'interfaces/http';
import SurveyHeader from 'layouts/SurveyHeader';
import { ResetPasswordSchema } from 'schemas/auth';
import { getLocal, removeLocal } from 'utils/storage';

const defaultValues = {
  email: '',
  newPassword: '',
  confirmNewPassword: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailDisable, setIsEmailDisable] = useState(false);
  const [searchParams] = useSearchParams();

  const methods = useForm<IResetPasswordForm>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });
  const { watch } = methods;
  const resetToken: string = getLocal('resetToken') || '';

  const onSubmit = async (data: IResetPasswordSchema) => {
    setIsSubmitting(true);
    try {
      // API here
      const payload = {
        newPassword: data.newPassword,
        confirmNewPassword: data.confirmNewPassword,
        resetToken,
      };

      await authAPI.resetPassword(payload);
      removeLocal('resetToken');
      navigate(`${uiRoutes.login}?resetPassword=success`);
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { newPassword, confirmNewPassword } = watch();

  // Reset error message whenever any of the input goes empty
  useEffect(() => {
    if (newPassword === '' || confirmNewPassword === '') {
      setErrorMessage('');
    }
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    const emailParams = searchParams.get('email') || '';
    if (emailParams) {
      methods.reset({ email: emailParams.replaceAll(' ', '+') });
      setIsEmailDisable(true);
    }
  }, [methods, searchParams]);

  return (
    <>
      <SurveyHeader />

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '150px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          bg={useColorModeValue('background.default', 'gray.800')}
          boxShadow="2xl"
          maxH="526px"
          maxW="589px"
          p={6}
          pos="relative"
          role="group"
          rounded="lg"
          w="full"
          zIndex={1}
        >
          <Box>
            <Heading
              display="flex"
              justifyContent="center"
              mb={8}
              size="heading1"
            >
              Reset Your Password
            </Heading>

            {!!errorMessage && (
              <Alert mb={4} status="error">
                <AlertIcon />
                <AlertTitle>{errorMessage}</AlertTitle>
              </Alert>
            )}

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormTextField
                    isDisabled={isEmailDisable}
                    label="Email"
                    name="email"
                    showHelperText
                    type="email"
                  />
                  <FormPasswordField
                    label="New Password"
                    name="newPassword"
                    showHelperText
                    type="password"
                  />
                  <FormPasswordField
                    label="Confirm Password"
                    name="confirmNewPassword"
                    showHelperText
                    type="password"
                  />

                  <Button
                    isLoading={isSubmitting}
                    size="lg"
                    type="submit"
                    variant="primaryLg"
                    width="100%"
                  >
                    Reset Password
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
