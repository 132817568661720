import { Inbox, LogOut, Paperclip, User } from 'react-feather';

import uiRoutes from './uiRoutes';

const MENUS = [
  {
    id: 1,
    defaultIsOpen: true,
    title: 'Profile',
    icon: User,
    iconName: 'User',
    children: [
      {
        id: 11,
        title: 'Personal Information',
        url: uiRoutes.patientDashboard.profile,
      },
      {
        id: 12,
        title: 'Change Password',
        url: uiRoutes.patientDashboard.changePassword,
      },
      {
        id: 13,
        title: 'Billing Information',
        url: uiRoutes.patientDashboard.billingInformation,
      },
      {
        id: 14,
        title: 'Notification Settings',
        url: uiRoutes.patientDashboard.notificationSettings,
      },
    ],
  },
  {
    id: 2,
    title: 'My Orders',
    icon: Paperclip,
    iconName: 'Paperclip',
    children: null,
    url: uiRoutes.patientDashboard.myOrders,
  },
  {
    id: 3,
    title: 'Documents',
    icon: File,
    iconName: 'File',
    children: null,
    url: uiRoutes.patientDashboard.documents,
  },
  {
    id: 4,
    title: 'Messages',
    icon: Inbox,
    iconName: 'Inbox',
    children: null,
    url: uiRoutes.patientDashboard.messages,
  },
  // {
  //   id: 5,
  //   title: 'All Appointments',
  //   icon: Calendar,
  //   iconName: 'Calendar',
  //   children: null,
  //   url: uiRoutes.patientDashboard.appointments,
  // },
  {
    id: 6,
    title: 'Logout',
    icon: LogOut,
    iconName: 'LogOut',
    children: null,
    url: uiRoutes.logout,
  },
];

export default MENUS;
