/**
 * Unformat date to digit only string
 * Useful for setting default value in form
 *
 * Input: 01/01/1995
 * Output: 01011995
 *
 * @param date
 * @returns
 */
export const unformatDate = (date: string) => {
  if (!date) return '';
  return date.replaceAll('/', '');
};

/**
 * Format date
 * Date in payload should be transformed in 'MM/DD/YYYY' format
 *
 * Input: 01011995
 * Output: 01/01/1995
 *
 * @param {string} date
 * @returns
 */
export const formatUnformattedDate = (date: string) => {
  if (!date) return '';
  return `${date.substring(0, 2)}/${date.substring(2, 4)}/${date.substring(
    4,
    date.length
  )}`;
};

/**
 * Check if every values of an object is empty or null
 *
 * @param {any} obj
 * @returns {boolean}
 */
export const checkIfObjHasAllEmptyOrNullValues = (obj: any) =>
  Object.values(obj).every((x) => x === null || x === '');

export const formatCurrency = (
  value: string | number,
  min = 2,
  max = 2
): string | null => {
  if (value || value === 0) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(value as number);
  }

  return '-';
};

export const getFullName = (
  firstName?: string,
  lastName?: string,
  middleName?: string
): string => {
  if (!firstName || !lastName) return '';
  return `${firstName}${middleName ? ` ${middleName} ` : ' '}${lastName}`;
};

export function getDaysInMonth(month: string, year: string) {
  return new Date(+year, +month, 0).getDate();
}

export const isSingleDigit = (value: string) => {
  if (value.length === 1) return true;
  return false;
};
