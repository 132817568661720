import { useFormContext } from 'react-hook-form';

import { Heading, SimpleGrid, Stack, useMediaQuery } from '@chakra-ui/react';
import {
  FormGoogleAutocompleteInput,
  FormMaskedZipInput,
  FormTextField,
} from 'common';
import { formSimpleGridGap } from 'constants/styles';
import { IAddress } from 'interfaces/common';
import breakpoints from 'styles/customTheme/breakpoints';
import { checkIfObjHasAllEmptyOrNullValues } from 'utils/common';

import SameAsBillingAddressCheckbox from './SameAsBillingAddressCheckbox';

const ShippingAddress = () => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  const onPopulateFields = (addr: IAddress) => {
    if (checkIfObjHasAllEmptyOrNullValues(addr)) return;

    setValue('shippingAddressLine1', addr.addressLine1?.trim());
    setValue('shippingAddressLine2', addr.addressLine2?.trim());
    setValue('shippingCity', addr.city);
    setValue('shippingState', addr.state);
    setValue('shippingZipCode', addr.zipCode);
  };

  const displayErrorMessage = (error: any) => {
    if (!isMobile) {
      if (error?.shippingCity?.message) {
        return error?.shippingCity?.message;
      }
      if (error?.shippingState?.message) {
        return error?.shippingState?.message;
      }
    }
    if (error?.shippingZipCode?.message) {
      return error?.shippingZipCode?.message;
    }
    return '';
  };
  return (
    <Stack spacing={5}>
      <Heading size="heading2">Shipping Address</Heading>
      <SameAsBillingAddressCheckbox />

      <Stack spacing={formSimpleGridGap}>
        <SimpleGrid columns={{ base: 1 }} gap={formSimpleGridGap}>
          <FormGoogleAutocompleteInput
            name="shippingAddressLine1"
            onPopulateFields={onPopulateFields}
          />
          <FormTextField label="Address Line 2" name="shippingAddressLine2" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={formSimpleGridGap}>
          <FormTextField label="City" name="shippingCity" />
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={formSimpleGridGap}>
            <FormTextField label="State" name="shippingState" />
            <FormMaskedZipInput name="shippingZipCode" />
          </SimpleGrid>
        </SimpleGrid>
        <p style={{ margin: 0, marginTop: 8, color: 'red' }}>
          {displayErrorMessage(errors)}
        </p>
      </Stack>
    </Stack>
  );
};

export default ShippingAddress;
