import { useState } from 'react';

import { IDateArray } from 'interfaces/common';

export const useCalendarDate = () => {
  const [dynamicDays, setDynamicDays] = useState('');
  const monthArray: number[] = [];
  const dayArray: number[] = [];
  const yearArray: number[] = [];

  for (let i = 1; i <= 12; i += 1) {
    monthArray.push(i);
  }

  for (let j = 1; j <= +dynamicDays; j += 1) {
    dayArray.push(j);
  }

  const currentYear = new Date().getFullYear();
  // Getting years
  const hundredTwentyYearsBack = currentYear - 120;

  for (let k = hundredTwentyYearsBack; k <= currentYear; k += 1) {
    yearArray.push(k);
  }

  const hundredTwentyYearsBackReversed = yearArray.reverse();

  const dateArray: IDateArray[] = [
    { id: 1, name: 'month', placeholder: 'Month', rawTimeArray: monthArray },
    { id: 2, name: 'day', placeholder: 'Day', rawTimeArray: dayArray },
    {
      id: 3,
      name: 'year',
      placeholder: 'Year',
      rawTimeArray: hundredTwentyYearsBackReversed,
    },
  ];
  return { dateArray, setDynamicDays };
};
