/* eslint-disable react/no-unescaped-entities */
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Heading, Text } from '@chakra-ui/react';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import SurveyLayout from 'layouts/SurveyLayout';

const AccountCreationSuccess = () => {
  const navigate = useNavigate();

  const onContinueClick = () => {
    navigate(
      `${uiRoutes.onlineAssessment.replace(
        ':id',
        (misc.PRE_SIGNUP_STEP + 1).toString()
      )}`
    );
  };

  const onBackClick = () => {
    navigate(uiRoutes.optInSms);
  };

  return (
    <SurveyLayout>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <Heading size="heading1" textAlign="center">
          Continue Online Assessment
        </Heading>
        <Text mt={2} size="bodyTextLarge" textAlign="center">
          You have successfully created your account. You can continue with your
          assessment.
        </Text>
        <Grid maxW="536px" mt={4} width="100%">
          <Button
            mt={8}
            onClick={onContinueClick}
            type="button"
            variant="primaryBlock"
          >
            Continue
          </Button>
          <Button
            leftIcon={<ChevronLeft />}
            mt={2}
            onClick={onBackClick}
            type="button"
            variant="primaryLinkLg"
            width="100%"
          >
            Go back
          </Button>
        </Grid>
      </Box>
    </SurveyLayout>
  );
};

export default AccountCreationSuccess;
