import FileSaver from 'file-saver';

const downloadBinaryObject = (
  bufferData: [],
  documentType: string,
  documentName: string
) => {
  const mainData = bufferData.reduce(
    (acc: any[], chunk: any) => [...acc, ...chunk.data],
    []
  );

  const blob = new Blob([Uint8Array.from(mainData)], {
    type: documentType,
  });

  FileSaver.saveAs(blob, documentName);
};

export default downloadBinaryObject;
