import { useLocation } from 'react-router-dom';

import { Container } from '@chakra-ui/react';
import uiRoutes from 'constants/uiRoutes';
import { selectFooterContent } from 'stores/cms-content';
import { useAppSelector } from 'stores/hooks';

import SurveyFooter from './SurveyFooter';
import SurveyHeader from './SurveyHeader';

interface IProps {
  children: React.ReactNode;
}

const SurveyLayout = ({ children }: IProps) => {
  const location = useLocation();

  const footerContent = useAppSelector(selectFooterContent);

  const showFooter = location.pathname === uiRoutes.checkout;

  return (
    <>
      <SurveyHeader />
      <Container
        maxW={{
          '2xl': '536px',
          sm: '350px',
          md: '536px',
          lg: '536px',
          xl: '536px',
        }}
        pb={100}
        px="0px"
        sx={{ overflow: 'hidden' }}
      >
        {children}
      </Container>
      {showFooter && footerContent && <SurveyFooter data={footerContent} />}
    </>
  );
};

export default SurveyLayout;
