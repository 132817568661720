import { Controller, useFormContext } from 'react-hook-form';

import { Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import { GENDERS } from 'constants/gender';
import validationMessage from 'constants/validationMessage';

const PRIMARY_DEFAULT_COLOR = 'primary.default';

const FormGenderInput = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div>
      <Text color="gray.600" fontSize="sm" mb="1.5" pt="0.5">
        Sex assigned at birth
      </Text>
      <Controller
        control={control}
        name="gender"
        render={({ field: { value, onChange } }) => (
          <ButtonGroup size="lg" width="100%">
            <Stack direction="row" spacing={2} width="100%">
              {GENDERS.map((gender) => (
                <Button
                  _focus={{
                    outline: 'none',
                    borderColor: PRIMARY_DEFAULT_COLOR,
                    color: PRIMARY_DEFAULT_COLOR,
                  }}
                  _hover={{
                    borderColor: PRIMARY_DEFAULT_COLOR,
                    color: PRIMARY_DEFAULT_COLOR,
                  }}
                  bg={value === gender.value ? 'blue.50' : 'white'}
                  borderColor={
                    value === gender.value ? PRIMARY_DEFAULT_COLOR : 'inherit'
                  }
                  boxShadow="sm"
                  color={
                    value === gender.value
                      ? PRIMARY_DEFAULT_COLOR
                      : 'text.faded'
                  }
                  fontWeight="normal"
                  key={gender.value}
                  mr="-px"
                  onClick={() => onChange(gender.value)}
                  rounded="4"
                  variant="outline"
                  width="50%"
                >
                  {gender.name}
                </Button>
              ))}
            </Stack>
          </ButtonGroup>
        )}
        rules={{ required: validationMessage.GENDER_REQUIRED }}
      />
      {errors.gender && (
        <Text color="error.light" fontSize="sm" mt="1.5">
          {errors.gender?.message}
        </Text>
      )}
    </div>
  );
};

export default FormGenderInput;
