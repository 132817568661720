import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Heading, Image } from '@chakra-ui/react';
import check from 'assets/images/svg/check.svg';
import uiRoutes from 'constants/uiRoutes';
import SurveyLayout from 'layouts/SurveyLayout';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const MessageSentSuccess = () => {
  const navigate = useNavigate();

  const patient = useAppSelector(selectPatient);

  const onBackClick = () => {
    if (patient?.id) {
      navigate(uiRoutes.patientDashboard.profile);
      return;
    }

    navigate(uiRoutes.home);
  };

  return (
    <SurveyLayout>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '90vh',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxW="497px"
        >
          <Image height="60px" src={check} width="60px" />
          <Box
            as={Heading}
            color="neutral.dark"
            mt={8}
            size="heading1"
            textAlign="center"
          >
            Your message has been sent
          </Box>
          <Box
            as={Heading}
            color="neutral.dark"
            size="heading1"
            textAlign="center"
          >
            successfully.
          </Box>
          <Button
            leftIcon={<ChevronLeft />}
            mt={6}
            onClick={onBackClick}
            variant="primaryLinkLg"
            width="100%"
          >
            Back to home
          </Button>
        </Box>
      </Box>
    </SurveyLayout>
  );
};

export default MessageSentSuccess;
