import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';

type Props = {
  name: string;
  label: string;
  showHelperText?: boolean;
  required?: boolean;
} & InputProps;

const FormTextField = ({
  name,
  label,
  showHelperText,
  required,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <FormLabel>{required ? `${label}*` : label}</FormLabel>
          <Input {...others} {...field} />
          {!!errors[name] && showHelperText && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

FormTextField.defaultProps = {
  showHelperText: false,
  required: false,
};

export default FormTextField;
