import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { zipCodeSchema } from 'schemas';
import { validateMinAgeFromYear } from 'utils/date';
import * as Yup from 'yup';

import { emailSchema } from './auth';

const {
  REQUIRED,
  INVALID_EMAIL,
  PASSWORD_NOT_MATCHED,
  PASSWORD_REQUIREMENT,
  INVALID,
  DOB_MIN_AGE_VALIDATION,
  LEGAL_FIRST_NAME_REQUIRED,
  LEGAL_LAST_NAME_REQUIRED,
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_REQUIRED,
  GENDER_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  BILLING_CITY_REQUIRED,
  SHIPPING_CITY_REQUIRED,
  BILLING_STATE_RQUIRED,
  SHIPPING_STATE_REQUIRED,
} = validationMessage;

export const PatientProfileSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(LEGAL_FIRST_NAME_REQUIRED),
  lastName: Yup.string().trim().required(LEGAL_LAST_NAME_REQUIRED),
  month: Yup.string().required('Month is required'),
  day: Yup.string().required('Day is required'),
  year: Yup.string()
    .required('Year is required')
    .test('checkIfDobIsValid', DOB_MIN_AGE_VALIDATION, (value) => {
      if (!value) return true;
      return validateMinAgeFromYear(value, 18);
    }),
  email: emailSchema,
  phone: Yup.string().nullable().required(PHONE_NUMBER_REQUIRED),
  gender: Yup.string().trim().required(GENDER_REQUIRED),
});

export const SignupSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(LEGAL_FIRST_NAME_REQUIRED),
  lastName: Yup.string().trim().required(LEGAL_LAST_NAME_REQUIRED),
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL_REQUIRED),
  password: Yup.string()
    .trim()
    .required(PASSWORD_REQUIRED)
    .matches(regex.PASSWORD, PASSWORD_REQUIREMENT),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], PASSWORD_NOT_MATCHED)
    .required(CONFIRM_PASSWORD_REQUIRED),
});

export const EssentialsSchema = Yup.object().shape({
  gender: Yup.string().trim().required(GENDER_REQUIRED),
  zipCode: zipCodeSchema,
  month: Yup.string().required('Month is required'),
  day: Yup.string().required('Day is required'),
  year: Yup.string()
    .required('Year is required')
    .test('checkIfDobIsValid', DOB_MIN_AGE_VALIDATION, (value) => {
      if (!value) return true;
      return validateMinAgeFromYear(value, 18);
    }),
  phone: Yup.string().required(PHONE_NUMBER_REQUIRED),
});

export const CheckoutSchema = Yup.object().shape({
  // Shipping address
  shippingAddressLine1: Yup.string().trim().required(REQUIRED),
  shippingAddressLine2: Yup.string(),
  shippingCity: Yup.string().trim().required(SHIPPING_CITY_REQUIRED),
  shippingState: Yup.string().trim().required(SHIPPING_STATE_REQUIRED),
  shippingZipCode: zipCodeSchema,
  // Shipping contact
  shippingEmail: Yup.string()
    .nullable()
    .trim()
    .email(INVALID_EMAIL)
    .required(REQUIRED),
  shippingPhone: Yup.string().required(REQUIRED).matches(regex.PHONE, {
    excludeEmptyString: false,
    message: INVALID,
  }),
  // Billing address
  billingAddressLine1: Yup.string().trim().required(REQUIRED),
  billingAddressLine2: Yup.string(),
  billingCity: Yup.string().trim().required(BILLING_CITY_REQUIRED),
  billingState: Yup.string().trim().required(BILLING_STATE_RQUIRED),
  billingZipCode: zipCodeSchema,
  // Billing contact
  billingEmail: Yup.string()
    .nullable()
    .trim()
    .email(INVALID_EMAIL)
    .required(REQUIRED),
  billingPhone: Yup.string().required(REQUIRED).matches(regex.PHONE, {
    excludeEmptyString: false,
    message: INVALID,
  }),
});

export const AddNewCard = Yup.object().shape({
  cardToken: Yup.string().required(REQUIRED),
});
