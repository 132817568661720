import { useEffect } from 'react';

import {
  AlertIcon,
  AlertProps,
  AlertTitle,
  Alert as ChakraUIAlert,
  CloseButton,
  Fade,
} from '@chakra-ui/react';
import { AlertStatusType } from 'interfaces/alert';

interface IProps extends AlertProps {
  text: string;
  onClose(): void;
  autoClose?: boolean;
  status?: AlertStatusType;
}

const Alert = ({
  autoClose = false,
  text,
  onClose,
  status = 'error',
  ...others
}: IProps) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (autoClose) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => onClose(), 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoClose, onClose]);
  return (
    <Fade delay={0.1} in={!!text} unmountOnExit>
      <ChakraUIAlert {...others} status={status}>
        <AlertIcon />
        <AlertTitle>{text}</AlertTitle>
        <CloseButton ml="auto" onClick={onClose} />
      </ChakraUIAlert>
    </Fade>
  );
};

Alert.defaultProps = {
  autoClose: false,
  status: 'error',
};

export default Alert;
