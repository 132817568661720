import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import AppWrapper from 'common/AppWrapper';
import AxiosInterceptor from 'common/axios-interceptor';
import Router from 'routes';
import { store } from 'stores';
import theme from 'styles/customTheme/index';

const App = (): JSX.Element => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AxiosInterceptor>
          <AppWrapper>
            <Router />
          </AppWrapper>
        </AxiosInterceptor>
      </BrowserRouter>
    </ChakraProvider>
  </Provider>
);

export default App;
