import { useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';

const AlwaysScrollToBottom = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth' });
  });
  return <Box marginTop="0px !important" ref={ref} />;
};

export default AlwaysScrollToBottom;
