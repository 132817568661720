import { useState } from 'react';

import { Box, Fade, Heading, Stack } from '@chakra-ui/react';
import Alert from 'common/Alert';
import { INotificationSettings } from 'interfaces/patient';

import { SettingCard } from './SettingCard';

const settings: INotificationSettings[] = [
  {
    id: 1,
    title: 'Account/Treatment Updates',
    body: 'I agree to receive text updates from MATRx to {{phone}} that may result in a transaction. I understand that agreeing is not required to purchase MATRx products & services.',
    apiSignatureKey: 'notifyAccountUpdates',
  },
  {
    id: 2,
    title: 'Special Offers from MATRx',
    body: 'I agree to receive text updates from MATRx to {{phone}} that may be considered marketing materials. I understand that agreeing is not required to purchase MATRx products & services.',
    apiSignatureKey: 'notifySpecialOffers',
  },
];

const NotificationSettings = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const clearError = () => setErrorMessage('');

  return (
    <Box as={Fade} height="100%" in unmountOnExit>
      <Stack spacing={10}>
        <Heading as="h2" size="heading2">
          Notification Settings
        </Heading>

        <Alert autoClose onClose={clearError} text={errorMessage} />

        <Stack spacing={10}>
          {settings.map((setting) => (
            <SettingCard
              key={setting.id}
              setErrorMessage={setErrorMessage}
              setting={setting}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
export default NotificationSettings;
