import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Box, Center, Fade, Heading, Stack, Text } from '@chakra-ui/react';
import { getOrders } from 'api/order';
import { LoadingIndicator } from 'common';
import { PatientStatusType } from 'enum/PatientStatus';
import useAPIService from 'hooks/useAPIService';
import { IOrdersListResponse } from 'interfaces/order';
import { selectPatient } from 'stores/auth';

import OrderCard from './OrderCard';

const MyOrder = () => {
  const patientData = useSelector(selectPatient);

  const isHighRiskPatient = patientData?.status === PatientStatusType.HIGH_RISK;

  const { loading, data } = useAPIService<IOrdersListResponse>({
    api: useCallback(() => getOrders(), []),
    isEnabled: (patientData && !isHighRiskPatient) || false,
  });

  if (loading) return <LoadingIndicator containerHeight="30rem" />;

  if (!data?.data?.orderList?.rows?.length) {
    return (
      <Center height="30%">
        <Text color="text.faded" size="bodyTextSmall">
          There are no orders to display
        </Text>
      </Center>
    );
  }

  return (
    <Box as={Fade} height="100%" in unmountOnExit>
      <Stack spacing={8}>
        <Heading as="h2" size="heading2">
          My Orders
        </Heading>
        <Stack spacing={10}>
          {data?.data?.orderList?.rows.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              subscription={data?.data?.subscription}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default MyOrder;
