import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';
import { LoadingIndicator } from 'common';
import { spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { PatientStatusType } from 'enum/PatientStatus';
import ProfileHeader from 'layouts/ProfileHeader';
import { selectPatient } from 'stores/auth';
import { selectQuestionaires } from 'stores/online-assessment';

import SideNavigation from './SideNavigation';

const { MAX_WIDTH } = spacing;

const PatientDashboard = () => {
  const navigate = useNavigate();

  const patientData = useSelector(selectPatient);
  const totalQuestionaires = useSelector(selectQuestionaires);

  const onContinueAssessmentClick = () => {
    if (!patientData || !totalQuestionaires) {
      return;
    }

    if (patientData.surveys.length === totalQuestionaires.length) {
      navigate(uiRoutes.checkout);
      return;
    }

    if (patientData?.status) {
      // eslint-disable-next-line default-case
      switch (patientData.status) {
        case PatientStatusType.NEW:
        case PatientStatusType.UNVERIFIED:
          navigate(uiRoutes.essentials);
          break;
        case PatientStatusType.VERIFIED:
          navigate(uiRoutes.optInSms);
          break;
        case PatientStatusType.IN_PROGRESS:
          navigate(
            `${uiRoutes.onlineAssessment.replace(
              ':id',
              `${patientData.surveys.length + 1}`
            )}`
          );
          break;
      }
    }
  };

  if (!patientData) return <LoadingIndicator />;

  return (
    <>
      <ProfileHeader />
      <Container
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
        justifyContent="flex-end"
        maxWidth={MAX_WIDTH}
        pb={1}
        pt={120} // setting margin-top raises an issue with google autocomplete offset
      >
        {[PatientStatusType.HIGH_RISK as string].includes(
          patientData.status
        ) && (
          <Box
            alignItems="center"
            backgroundColor="#FFDEDC"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            p={3}
          >
            <Heading color="text.default" mr={6} size="heading3">
              Your assessment answers reveal a need for a direct consultation.
            </Heading>
            <Text color="text.default" mr={6} size="bodyTextMediumSemibold">
              Please contact MAT-Rx support
            </Text>
          </Box>
        )}
        {[
          PatientStatusType.NEW as string,
          PatientStatusType.IN_PROGRESS as string,
          PatientStatusType.UNVERIFIED as string,
          PatientStatusType.VERIFIED as string,
        ].includes(patientData.status) && (
          <Box
            alignItems="center"
            backgroundColor="primary.lighter"
            display="flex"
            justifyContent="center"
            p={3}
          >
            <Text
              color="primary.default"
              fontSize="20px"
              mr={6}
              size="heading2"
            >
              Your assessment is incomplete
            </Text>
            <Button onClick={onContinueAssessmentClick} variant="secondaryLg">
              <Text size="bodyTextLargeSemibold">Continue</Text>
            </Button>
          </Box>
        )}
        <SideNavigation />
      </Container>
    </>
  );
};

export default PatientDashboard;
