import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import cmsContentReducer from './cms-content';
import miscReducer from './misc';
import onlineAssessmentReducer from './online-assessment';
import messagesReducer from './patient/messages';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    messages: messagesReducer,
    misc: miscReducer,
    onlineAssessment: onlineAssessmentReducer,
    cmsContent: cmsContentReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
