import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
} from '@chakra-ui/react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import stripeLogo from 'assets/images/svg/stripeLogo.svg';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { extendedColors } from 'styles/customTheme/colors';
import { fontWeights } from 'styles/customTheme/fonts';
import { getFullName } from 'utils/common';

interface ICardError {
  code: string;
  type: string;
  message: string;
}

interface ICardFieldData {
  empty: boolean;
  complete: false;
  error: ICardError | null;
}

interface ICardData {
  cardNumber: ICardFieldData | null;
  cardExpiry: ICardFieldData | null;
  cardCvc: ICardFieldData | null;
}

const OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: fontWeights.bold,
      color: extendedColors.neutral.darker,
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
        fontWeight: fontWeights.normal,
      },
    },
    invalid: {
      color: extendedColors.error.default,
    },
  },
};

const CardPaymentForm = ({
  hideTitle,
  resetForm,
}: {
  hideTitle?: boolean;
  resetForm?: boolean;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setValue } = useFormContext();

  const patient = useAppSelector(selectPatient);

  // These don't contain the actual values
  const [cardData, setCardData] = useState<ICardData>({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });

  const onChangeHandler = (event: any) => {
    setCardData((prevState) => ({
      ...prevState,
      [event.elementType]: {
        empty: event.empty,
        complete: event.complete,
        error: event.error || null,
      },
    }));
  };

  useEffect(() => {
    // Implement Reset the Form Values of Stripe
    setCardData({ cardNumber: null, cardExpiry: null, cardCvc: null });
    elements?.getElement(CardNumberElement)?.clear();
    elements?.getElement(CardExpiryElement)?.clear();
    elements?.getElement(CardCvcElement)?.clear();
  }, [elements, resetForm]);

  useEffect(() => {
    const generateToken = async () => {
      const cardNumberElement = elements?.getElement(CardNumberElement);
      if (!cardNumberElement) return;

      const result = await stripe?.createToken(cardNumberElement, {
        name: getFullName(patient?.firstName, patient?.lastName),
      });

      setValue('cardToken', result?.token?.id || '');
    };

    const { cardNumber, cardExpiry, cardCvc } = cardData;

    if (
      cardNumber?.complete &&
      cardExpiry?.complete &&
      cardCvc?.complete &&
      stripe &&
      elements &&
      patient
    ) {
      // Generate token
      generateToken();
    } else {
      setValue('cardToken', '');
    }
  }, [cardData, elements, stripe, patient, setValue]);

  return (
    <Stack className="stripe-container" spacing={5}>
      {/* {!hideTitle ? <Heading size="heading2">Card Information</Heading> : ''} */}
      {!hideTitle && <Heading size="heading2">Card Information</Heading>}
      <FormControl isInvalid={!!cardData.cardNumber?.error}>
        <FormLabel>Card Number</FormLabel>
        <Input
          as={CardNumberElement}
          onChange={onChangeHandler}
          options={OPTIONS}
        />
        {cardData.cardNumber?.error?.message && (
          <FormErrorMessage>
            {cardData.cardNumber?.error?.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <Stack direction="row" spacing={4}>
        <FormControl isInvalid={!!cardData.cardExpiry?.error}>
          <FormLabel>Expiration Date</FormLabel>
          <Input
            as={CardExpiryElement}
            onChange={onChangeHandler}
            options={OPTIONS}
          />
          {cardData.cardExpiry?.error?.message && (
            <FormErrorMessage>
              {cardData.cardExpiry?.error?.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!cardData.cardCvc?.error}>
          <FormLabel>CVC</FormLabel>
          <Input
            as={CardCvcElement}
            onChange={onChangeHandler}
            options={OPTIONS}
          />
          {cardData.cardCvc?.error?.message && (
            <FormErrorMessage>
              {cardData.cardCvc?.error?.message}
            </FormErrorMessage>
          )}
          <Box display="flex" justifyContent="flex-end" pt={4}>
            <Image src={stripeLogo} width="80px" />
          </Box>
        </FormControl>
      </Stack>
    </Stack>
  );
};

CardPaymentForm.defaultProps = {
  hideTitle: false,
  resetForm: false,
};

export default CardPaymentForm;
