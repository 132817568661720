const misc = {
  PRE_SIGNUP_STEP: 3,
  SOURCE: 'NPSS', // TODO: Make source dynamic according to the subdomain,
  ALL_PAGINATION_DATA: {
    limit: 999,
    offset: 0,
  },
  SELECT_NONE_OPTION: 'none apply to me',
  MESSAGE_MENU_TEXT: 'Messages',
  SURVEY_BEFORE_SIGNUP_LOCAL_KEY: 'surveyBeforeSignup'
};

export default misc;
