import { RefObject, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, FormLabel, Input, others } from '@chakra-ui/react';
import { IAddress } from 'interfaces/common';

interface IProps {
  label?: string;
  name: string;
  onPopulateFields: (addr: IAddress) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
}

/** An object for creating google places autocomplete instance */
let autoCompleteObj: Record<string, any>;

async function handlePlaceSelect(
  callbackFunc: (addressObj: any) => void,
  autoComplete: any
) {
  const addressObject = autoComplete.getPlace();
  callbackFunc(addressObject);
}

function handleScriptLoad(
  autoCompleteRef: RefObject<any>,
  callbackFunc: (addressObj: any) => void
) {
  const searchOptions = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'formatted_address', 'geometry.location'],
  };
  /** Original `autoCompleteObj` object should not be mutated.
   *  If mutated, the google autocomplete will only be initialized for the latest object.
   *  This can cause issues like google autocomplete not working for multiple addresses on the same page
   */
  let autoCompleteInstance = autoCompleteObj;
  autoCompleteInstance = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    searchOptions
  );
  autoCompleteInstance.setFields(['address_components', 'formatted_address']);
  autoCompleteInstance.addListener('place_changed', () =>
    handlePlaceSelect(callbackFunc, autoCompleteInstance)
  );
}

const FormGoogleAutocompleteInput = (props: IProps) => {
  const { name, onPopulateFields, label, isDisabled = false } = props;

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    const populateFields = (addressObj: any) => {
      const address = addressObj?.address_components;
      if (!address) return;
      const addr = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
      };
      address.forEach((x: any) => {
        if (x.types[0] === 'street_number' || x.types[0] === 'route') {
          addr.addressLine1 += `${x.long_name} `;
        }
        if (x.types[0] === 'locality') {
          addr.city = `${x.long_name}`;
        }
        if (x.types[0] === 'administrative_area_level_1') {
          addr.state = `${x.short_name}`;
        }
        if (x.types[0] === 'postal_code') {
          addr.zipCode = `${x.long_name}`;
        }
      });
      onPopulateFields(addr);
    };

    handleScriptLoad(autoCompleteRef, populateFields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <FormLabel>{label}</FormLabel>
          <Input
            {...others}
            {...field}
            disabled={isDisabled}
            ref={autoCompleteRef}
          />
        </FormControl>
      )}
    />
  );
};

FormGoogleAutocompleteInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  label: 'Address Line 1',
};

export default FormGoogleAutocompleteInput;
