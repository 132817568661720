/**
 * Build cosmic object slug with actual client and cosmic environment
 *
 * @param {string} objectSlug
 * @param {client: string, cosmicEnv: string} obj
 * @returns {string}
 */
export const buildObjectSlug = (
  objectSlug: string,
  { client, cosmicEnv }: { client: string; cosmicEnv: string }
) => objectSlug.replace('{client}', client).replace('{env}', cosmicEnv);

/**
 * Get cosmic environment from the NEXT_ENVIRONMENT env variable
 *
 * Cosmic only has two separate environments ('DEV' and 'PROD')
 *
 * @param {string} env :: "DEV" | "QA" | "UAT" | "PROD"
 * @returns {string} :: "dev" | "prod"
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCosmicEnvFromEnv = (env: string) => {
  if (env === 'DEV' || env === 'QA') return 'dev';
  if (env === 'UAT' || env === 'PROD') return 'prod';
  return '';
};
