const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1400px',
  '3xl': '1600px',
};

export default breakpoints;
