import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as drugOfferingAPI from 'api/drugOffering';
import * as merchantAPI from 'api/merchant';
import * as patientAPI from 'api/patient';
import { LoadingIndicator } from 'common';
import { formSimpleGridGap } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { AddressType } from 'enum/AddressType';
import { IDrugOffering } from 'interfaces/drugOffering';
import { IError } from 'interfaces/http';
import { IMerchantKey } from 'interfaces/merchant';
import { ICheckoutForm, ICheckoutSchema } from 'interfaces/patient';
import SurveyLayout from 'layouts/SurveyLayout';
import { CheckoutSchema } from 'schemas/patient';
import { togglePatientDetailFetchFlag } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectSource } from 'stores/online-assessment';

import BillingAddress from './BillingAddress';
import BillingContact from './BillingContact';
import CardPaymentForm from './CardPaymentForm';
import DrugInfo from './DrugInfo';
import ShippingAddress from './ShippingAddress';
import ShippingContact from './ShippingContact';
import SubscriptionPlanCard from './SubscriptionPlanCard';

const defaultValues = {
  // Card
  cardToken: '',
  // Shipping address
  shippingAddressLine1: '',
  shippingAddressLine2: '',
  shippingCity: '',
  shippingState: '',
  shippingZipCode: '',
  // Shipping contact
  shippingEmail: '',
  shippingPhone: '',
  // Billing address
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  // Billing contact
  billingEmail: '',
  billingPhone: '',
};

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const source = useAppSelector(selectSource);

  const methods = useForm<ICheckoutForm>({
    defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(CheckoutSchema),
  });
  const { handleSubmit } = methods;

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [drugOffering, setDrugOffering] = useState<IDrugOffering | null>(null);
  const [merchantKey, setMerchantKey] = useState<IMerchantKey | null>(null);

  const subscriptionContainerRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  // Fetch drug offerings
  useEffect(() => {
    const fetchDrugOfferings = async () => {
      setIsLoading(true);
      const response = await drugOfferingAPI.fetchDrugOfferings({
        client: source,
      });
      if (response.data?.rows?.length) {
        setDrugOffering(response.data?.rows[0]);
        setIsLoading(false);
      }
    };

    if (source) {
      fetchDrugOfferings();
    }
  }, [source]);

  // Fetch merchant key (for stripe)
  useEffect(() => {
    const fetchMerchantKey = async () => {
      setIsLoading(true);
      const response = await merchantAPI.getMerchantKey(source);
      setMerchantKey(response.data);
      setIsLoading(false);
    };

    if (source) {
      fetchMerchantKey();
    }
  }, [source]);

  const stripePromise = useMemo(() => {
    if (merchantKey?.publishableKey) {
      return loadStripe(merchantKey.publishableKey);
    }
    return null;
  }, [merchantKey]);

  const onSubmit = async (data: ICheckoutForm) => {
    if (!data.cardToken) {
      // Scroll the user to the end of subscription container
      (subscriptionContainerRef?.current as any)?.scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }

    const billingAddress = {
      addressType: AddressType.BILLING,
      isDefault: true,
      addressLine1: data.billingAddressLine1,
      addressLine2: data.billingAddressLine2,
      city: data.billingCity,
      state: data.billingState,
      zipCode: data.billingZipCode,
      phone: data.billingPhone,
      email: data.billingEmail,
    };

    const shippingAddress = {
      addressType: AddressType.SHIPPING,
      isDefault: true,
      addressLine1: data.shippingAddressLine1,
      addressLine2: data.shippingAddressLine2,
      city: data.shippingCity,
      state: data.shippingState,
      zipCode: data.shippingZipCode,
      phone: data.shippingPhone,
      email: data.shippingEmail,
    };

    const payload: ICheckoutSchema = {
      drugOfferingId: drugOffering?.id || '',
      isSubscription: true,
      cardToken: data.cardToken,
      patientAddress: {
        billingAddress: [billingAddress],
        shippingAddress: [shippingAddress],
      },
    };

    setIsSubmitting(true);
    try {
      await patientAPI.checkout(payload);
      dispatch(togglePatientDetailFetchFlag());
      navigate(uiRoutes.checkoutSuccess);
    } catch (err) {
      setErrorMessage((err as IError)?.message);
      window.scrollTo(0, 0);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading || !drugOffering || !merchantKey || !stripePromise) {
    return <LoadingIndicator />;
  }

  return (
    <SurveyLayout>
      <Stack mt={100} spacing={8}>
        <VStack mb={3} spacing={3}>
          {!!errorMessage && (
            <Alert mb={4} status="error">
              <AlertIcon />
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          )}

          <Heading size="heading1">Your Treatment Plan</Heading>
          <Text size="bodyTextMediumSemibold">
            Based on your responses to the assessment questions, we believe you
            could benefit from our FDA-approved medication.
          </Text>
        </VStack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={8}>
              <DrugInfo data={drugOffering?.drug} />
              <Stack direction="column" spacing="8">
                <Box mb={4} ref={subscriptionContainerRef}>
                  <SubscriptionPlanCard data={drugOffering} />
                </Box>
                <Box mb={4}>
                  {/* TODO: Figure out why there is error on the console if this element provider is kept in AppWrapper.tsx */}
                  <Elements stripe={stripePromise}>
                    <CardPaymentForm />
                  </Elements>
                </Box>
                <Stack spacing={formSimpleGridGap}>
                  <BillingAddress />
                  <BillingContact />
                </Stack>
                <Stack spacing={formSimpleGridGap}>
                  <ShippingAddress />
                  <ShippingContact />
                </Stack>
              </Stack>
              <Button
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
                variant="primaryLg"
                width={{ base: '100%', md: 'auto' }}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </SurveyLayout>
  );
};
export default Payment;
