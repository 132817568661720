import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { passwordSchema } from 'schemas';
import * as Yup from 'yup';

const {
  PASSWORD_NOT_MATCHED,
  INVALID_EMAIL,
  PASSWORD_REQUIREMENT,
  EMAIL_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
} = validationMessage;

export const emailSchema = Yup.string()
  .trim()
  .email(INVALID_EMAIL)
  .nullable()
  .required(EMAIL_REQUIRED);

export const LoginSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
  rememberMe: Yup.bool(),
});

export const ForgotPasswordEmailSchema = Yup.object().shape({
  email: emailSchema,
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().trim().required(CURRENT_PASSWORD_REQUIRED),
  newPassword: Yup.string()
    .trim()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(regex.PASSWORD, {
      excludeEmptyString: false,
      message: PASSWORD_REQUIREMENT,
    }),
  confirmNewPassword: Yup.string()
    .trim()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([Yup.ref('newPassword')], PASSWORD_NOT_MATCHED),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: emailSchema,
  newPassword: passwordSchema.matches(regex.PASSWORD, {
    excludeEmptyString: false,
    message: PASSWORD_REQUIREMENT,
  }),
  confirmNewPassword: passwordSchema.oneOf(
    [Yup.ref('newPassword')],
    PASSWORD_NOT_MATCHED
  ),
});
