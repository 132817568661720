import validationMessage from 'constants/validationMessage';
import { store } from 'stores';
import * as Yup from 'yup';
import ZipCodes from 'zipcodes';

const {
  REQUIRED,
  INVALID_EMAIL,
  SERVICE_NOT_AVAILABLE,
  ZIP_CODE_REQUIRED,
  PASSWORD_REQUIRED,
} = validationMessage;

const validateZipCode = (value: string) => {
  const { availableStates }: any = store.getState().misc;

  const states = availableStates?.map((state: any) =>
    state.state_abbreviation?.toUpperCase()
  );

  const area: { state: string } | undefined = ZipCodes.lookup(value);

  // If the zipcode is not valid
  if (!area) {
    return false;
  }

  const { state } = area;
  // if the service is available for the zipcode provided
  if (states.includes(state)) {
    return true;
  }

  return false;
};

export const stringSchema = Yup.string().trim().nullable();
export const stringRequiredSchema = stringSchema.required(REQUIRED);
export const emailSchema = stringRequiredSchema.email(INVALID_EMAIL);
export const passwordSchema = stringSchema.required(PASSWORD_REQUIRED);

export const zipCodeSchema = Yup.string()
  .nullable()
  .required(ZIP_CODE_REQUIRED)
  .test('checkIfZipCodeValid', SERVICE_NOT_AVAILABLE, (value) =>
    validateZipCode(value as string)
  );
