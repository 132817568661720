/**
 * {client} should be replaced by actual client before fetching.
 * {env} should be replaced by either 'dev' or 'prod'
 * (dev, qa -> dev & uat,prod -> prod)
 *
 * For eg: for NPSS's HOME_PAGE -> "home_page_npss_dev"
 *
 *
 */
export const objectSlug = {
  HOME_PAGE: 'home-page-{client}-{env}',
  HEADER: 'header-{client}-{env}',
  FOOTER: 'footer-{client}-{env}',
  SEO_INFO: 'seo-info-{client}-{env}',
  ORDER_CONFIRMATION_PAGE: 'order-confirmation-page-{client}-{env}',
  AVAILABLE_STATES: 'available-states-{client}-{env}',
  EXCEPTION_PAGE: 'exception-page-{client}-{env}',
  THEME_COLOR: 'theme-{client}-{env}',
  FAQ: 'faq-{client}-{env}',
};
