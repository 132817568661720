import { extendedColors } from '../colors';
import { fontWeights } from '../fonts';

const commonPrimaryBtnStyles = {
  borderRadius: '40px',
  backgroundColor: extendedColors.primary.default,
  color: extendedColors.white.default,
  boxShadow: 'none',
  fontWeight: fontWeights.semibold,
  height: 'auto',

  _hover: {
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },

  _disabled: {
    opacity: 0.4,
    _hover: {
      boxShadow: 'none',
      backgroundColor: `${extendedColors.primary.default} !important`,
    },
  },

  _active: {
    boxShadow: 'inset 0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
};

const commonTextBtnStyles = {
  boxShadow: 'none',
  backgroundColor: extendedColors.white.default,
  color: extendedColors.primary.default,
  _hover: {
    boxShadow: 'none',
    backgroundColor: extendedColors.primary.light,
  },
};

const commonOutlinedBtnStyles = {
  boxShadow: 'none',
  backgroundColor: extendedColors.white.default,
  color: extendedColors.primary.default,
  border: `1px solid ${extendedColors.primary.default}`,
  _hover: {
    boxShadow: 'none',
    backgroundColor: extendedColors.primary.light,
  },
};

const commonLinkBtnStyles = {
  boxShadow: 'none',
  backgroundColor: 'none',
  color: extendedColors.primary.default,
  _hover: {
    boxShadow: 'none',
    textDecoration: 'underline',
  },
  _active: {
    boxShadow: 'none',
  },
};

const commonLgBtnStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  padding: '12px 32px',
};

const commonSmBtnStyles = {
  fontSize: '12px',
  lineHeight: '18px',
  padding: '8px 16px',
};

const commonBlockBtnStyles = {
  fontSize: '16px',
  lineHeight: '28px',
  padding: '12px 80px',
  width: '100%',
};

const Button = {
  // Depreciate these (not included in the style guide)
  baseStyle: {
    borderRadius: '4px',
    boxShadow: '0px 0px 6px #00000029',
  },
  sizes: {
    md: {
      fontSize: 'sm',
      paddingX: '2rem',
    },
    lg: {
      fontSize: '1rem',
      paddingX: '2.5rem',
      paddingY: '1rem',
    },
    xl: {
      fontSize: 'xl',
      paddingX: '5rem',
      paddingY: '1rem',
    },
  },
  // ---------------------------------------------------
  variants: {
    primaryLg: {
      ...commonPrimaryBtnStyles,
      ...commonLgBtnStyles,
    },

    primarySm: {
      ...commonPrimaryBtnStyles,
      ...commonSmBtnStyles,
    },

    primaryTextLg: {
      ...commonPrimaryBtnStyles,
      ...commonTextBtnStyles,
      ...commonLgBtnStyles,
    },

    primaryTextSm: {
      ...commonPrimaryBtnStyles,
      ...commonTextBtnStyles,
      ...commonSmBtnStyles,
    },

    primaryOutlinedLg: {
      ...commonPrimaryBtnStyles,
      ...commonOutlinedBtnStyles,
      ...commonLgBtnStyles,
    },

    primaryOutlinedSm: {
      ...commonPrimaryBtnStyles,
      ...commonOutlinedBtnStyles,
      ...commonSmBtnStyles,
    },

    primaryLinkLg: {
      ...commonPrimaryBtnStyles,
      ...commonLinkBtnStyles,
      ...commonLgBtnStyles,
    },

    primaryBlock: {
      ...commonPrimaryBtnStyles,
      ...commonBlockBtnStyles,
    },

    primaryBlockText: {
      ...commonPrimaryBtnStyles,
      ...commonTextBtnStyles,
      ...commonBlockBtnStyles,
    },

    primaryBlockOutlined: {
      ...commonPrimaryBtnStyles,
      ...commonOutlinedBtnStyles,
      ...commonBlockBtnStyles,
    },

    secondaryLg: {
      ...commonPrimaryBtnStyles,
      ...commonLgBtnStyles,
      bg: extendedColors.secondary.default,
    },

    // Depreciate these (not included in the style guide)
    solid: {
      bg: 'accent.default',
      color: 'accentText.default',
      fontWeight: 'medium',
      textTransform: 'uppercase',
      '&:hover': {
        bg: 'accent.hovered',
        color: 'accentText.default',
        boxShadow: '0px 3px 9px #00000029',
      },
    },
    // ---------------------------------------------------
  },
};

export default Button;
