import apiRoutes from 'constants/apiRoutes';
import misc from 'constants/misc';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IHighRiskPatientSchema,
  ISurveySchema,
} from 'interfaces/onlineAssessment';
import {
  ICheckoutSchema,
  IEssentialsSchema,
  IOptInSmsSchema,
  IPatient,
  IPatientAddNewCardSchema,
  IPatientAddressUpdateSchema,
  IPatientPaymentDetail,
  IPatientProfileSchema,
  ISignupResponse,
  ISignupSchema,
} from 'interfaces/patient';
import { baseRequest } from 'utils/axios';

const { patient } = apiRoutes;

export const editPatientDetail = async ({
  data,
}: {
  data: IPatientProfileSchema;
}): Promise<IResponse<IPatient>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: patient.editDetail,
    data,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};

export const signupPatient = async (
  data: ISignupSchema
): Promise<IResponse<ISignupResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: patient.signup,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getPatientDetail = async (): Promise<IResponse<IPatient>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: patient.getDetail,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const savePatientEssentials = async (
  data: IEssentialsSchema
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: patient.saveEssentials,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveOptInSms = async (
  data: IOptInSmsSchema
): Promise<IResponse<IOptInSmsSchema>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: patient.patients,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

// save/update single questionaire
export const saveSurvey = async (
  data: ISurveySchema
): Promise<
  IResponse<{
    id: string;
  }>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: patient.surveys,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveHighRiskPatient = async (
  data: IHighRiskPatientSchema
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: patient.highRisk,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const checkout = async (
  data: ICheckoutSchema
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: patient.checkout,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editPatientBillingShipping = async (
  data: IPatientAddressUpdateSchema
) => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: patient.editPatientBillingshipping,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getPaymentDetails = async (): Promise<
  IResponse<IListResponse<IPatientPaymentDetail>>
> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: patient.paymentDetails.getAll,
    params: {
      offset: misc.ALL_PAGINATION_DATA.offset,
      limit: misc.ALL_PAGINATION_DATA.limit,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addPatientNewCard = async (data: IPatientAddNewCardSchema) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: patient.paymentDetails.addNewCard,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const deletePatientCard = async (merchantCardId: string) => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: patient.paymentDetails.deleteCard.replace(
      ':merchantCardId',
      merchantCardId
    ),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const updatePatientCardState = async (merchantCardId: string) => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: patient.paymentDetails.updatePrimary.replace(
      ':merchantCardId',
      merchantCardId
    ),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const uploadDocument = async (data: any) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: patient.patientDocuments.uploadDocument,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const getAllDocuments = async (isUploaded: boolean) => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: patient.patientDocuments.getAllUploadedDocuments,
    params: {
      isUploaded,
    },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const shareDocument = async (data: any) => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: patient.patientDocuments.shareDocumentToAdmin,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const deleteDocument = async (documentId: any) => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: patient.patientDocuments.deleteDocument.replace(':id', documentId),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const fetchDocumentBufferData = async (documentId: string) => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: patient.patientDocuments.downloadDocument.replace(':id', documentId),
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
