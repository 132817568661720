import { getSubDomain } from 'utils/misc';

const buildApplicationId = (source: string) =>
  `NPSS_${source.toUpperCase()}_ALCOHOL`;

// If subDomain(signifies brandname) changes you need to refactor this code
export const getApplicationIdBasedOnSubdomain = () => {
  const subdomain = getSubDomain();

  if (subdomain.includes('local')) {
    return buildApplicationId('local');
  }
  if (subdomain.includes('dev')) {
    return buildApplicationId('dev');
  }
  if (subdomain.includes('qa')) {
    return buildApplicationId('qa');
  }
  if (subdomain.includes('uat')) {
    return buildApplicationId('uat');
  }
  return buildApplicationId('prod');
};
