/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISurvey, ISurveyAnswer } from 'interfaces/onlineAssessment';
import { RootState } from 'stores';

interface IState {
  survey: ISurvey[];
  source: string;
}

const initialState: IState = {
  survey: [],
  source: '',
};

export const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setQuestionaires: (state, action: PayloadAction<ISurvey[]>) => {
      state.survey = [...action.payload];
    },

    // set answer as the user goes through the questionaire
    setAnswer: (
      state,
      action: PayloadAction<{
        questionId: number;
        updateId: string | undefined;
        answers: ISurveyAnswer[];
        multipleSelect: boolean;
        selectNone?: boolean;
        allSelect?: boolean;
      }>
    ) => {
      const {
        questionId,
        answers,
        multipleSelect,
        updateId,
        selectNone,
        allSelect,
      } = action.payload;

      state.survey = state.survey.map((item) => {
        // Leave other questions as it is
        if (item.questionId !== questionId) {
          return item;
        }

        // In case of single answer, just update the question with the provided answer
        if (!multipleSelect) {
          return {
            ...item,
            id: updateId,
            answers,
          };
        }

        // Below are the cases for multiple select

        // Check if the provided option was selected previously.
        // In such case, deselect the option
        const isPreviouslySelected = item.answers?.some(
          (ans) => ans.id === answers[0].id
        );
        if (isPreviouslySelected) {
          if (allSelect) {
            return {
              ...item,
              id: updateId,
              answers: [],
            };
          }

          return {
            ...item,
            id: updateId,
            answers: item.answers?.filter((ans) => ans.id !== answers[0].id),
          };
        }

        // All options should be selected if option like 'All of the above' is selected
        if (allSelect) {
          return {
            ...item,
            id: updateId,
            answers: item.metadata?.options
              // Remove 'None of the above' option when 'All of the above' is selected
              ?.filter(
                (option) =>
                  option.id !==
                  item.metadata.none_of_the_above_option?.toString()
              )
              ?.map((option) => ({
                id: option.id,
                value: option.value,
              })),
          };
        }

        // All others options should be deselected if option like 'None of the above' is selected
        if (selectNone) {
          return {
            ...item,
            id: updateId,
            answers: [...answers],
          };
        }

        return {
          ...item,
          id: updateId,
          answers: item.answers ? [...item.answers, ...answers] : [],
        };
      });
    },

    setSource: (state, action: PayloadAction<string>) => {
      state.source = action.payload;
    },
  },
});

// Actions
export const { setQuestionaires, setAnswer, setSource } = slice.actions;

// Selectors
export const selectQuestionaires = (state: RootState) =>
  state.onlineAssessment.survey;
export const selectSource = (state: RootState) => state.onlineAssessment.source;

// Reducer
export default slice.reducer;
