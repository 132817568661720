import { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Text,
  Textarea,
} from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import uiRoutes from 'constants/uiRoutes';
import { IError } from 'interfaces/http';
import { IHighRiskPatientSchema } from 'interfaces/onlineAssessment';
import SurveyLayout from 'layouts/SurveyLayout';
import { togglePatientDetailFetchFlag } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectQuestionaires } from 'stores/online-assessment';
import { fontWeights } from 'styles/customTheme/fonts';

const HighRiskPatient = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const questionId = searchParams.get('questionId');
  const answerId = searchParams.get('answerId');

  const questionaires = useAppSelector(selectQuestionaires);

  const [answer, setAnswer] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    const questionaire = questionaires?.find(
      (item) => item.questionId.toString() === questionId
    );

    if (!questionaire) {
      return;
    }

    const option = questionaire.metadata.options?.find(
      (item) => item.id === answerId
    );

    if (!option) {
      return;
    }

    const answers = [
      {
        id: option.id,
        value: option.value,
      },
    ];

    const payload: IHighRiskPatientSchema = {
      message: answer.trim(),
      surveyDetails: {
        questionId: questionaire.questionId.toString(),
        question: questionaire.question,
        answers,
      },
    };

    setIsSubmitting(true);
    try {
      await patientAPI.saveHighRiskPatient(payload);
      await dispatch(togglePatientDetailFetchFlag());
      navigate(uiRoutes.messageSentSuccess);
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <SurveyLayout>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxW="535px"
        paddingTop="120px"
      >
        {!!errorMessage && (
          <Alert mb={4} status="error">
            <AlertIcon />
            <AlertTitle>{errorMessage}</AlertTitle>
          </Alert>
        )}
        <Heading
          color="text.default"
          mt={8}
          size={{ base: 'heading2', md: 'heading1' }}
          sx={{ fontWeight: `${fontWeights.bold} !important` }}
          textAlign="center"
        >
          Your assessment answers reveal a need for a direct consultation.
        </Heading>
        <Text mt={2} size="bodyTextLarge">
          Send us a message about how best to contact you.
        </Text>
        <Textarea
          mt={8}
          onChange={(event) => setAnswer(event.target.value)}
          placeholder="Compose your message"
          rows={8}
          value={answer}
        />
        <Button
          disabled={!answer?.trim() || isSubmitting}
          isLoading={isSubmitting}
          mt={6}
          onClick={onSubmit}
          variant="primaryLg"
          width="100%"
        >
          Send Message
        </Button>
        <Button
          leftIcon={<ChevronLeft />}
          mt={2}
          onClick={() => navigate(-1)}
          type="button"
          variant="primaryLinkLg"
          width="100%"
        >
          Go Back
        </Button>
      </Box>
    </SurveyLayout>
  );
};

export default HighRiskPatient;
