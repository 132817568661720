import { useLocation, useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Drawer,
  DrawerContent,
  Text,
} from '@chakra-ui/react';
import misc from 'constants/misc';
import MENUS from 'constants/patientPortalMenus';
import { useAppSelector } from 'stores/hooks';
import { selectUnreadMessageCount } from 'stores/patient/messages';
import { extendedColors } from 'styles/customTheme/colors';

const { MESSAGE_MENU_TEXT } = misc;

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const PatientPortalDrawer = ({ isOpen, onClose }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const unreadMessageCount = useAppSelector(selectUnreadMessageCount);

  const defaultIndex = MENUS.reduce((acc, { defaultIsOpen }, index) => {
    if (defaultIsOpen) {
      acc.push(index);
    }
    return acc;
  }, [] as number[]);

  const navigateToUrl = (url: string) => {
    navigate(url);
    onClose();
  };

  const handleMenuSelect = (menu: any) => () => {
    if (menu?.children?.[0]) {
      navigate(menu.children[0].url);
      return;
    }
    navigateToUrl(menu.url);
  };

  const handleMenuItemSelect = (url: string) => () => {
    navigateToUrl(url);
  };

  const checkIsMenuActive = (url: string) => location.pathname === url;

  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onClose}
      placement="left"
      returnFocusOnClose={false}
      size="full"
    >
      <DrawerContent height="calc(100vh-80px)" mt="80px" overflowY="auto">
        <Box
          borderRight={`1px solid ${extendedColors.neutral.light} `}
          display={{ base: 'block', md: 'none' }}
          height="100%"
        >
          <Accordion
            allowToggle
            borderBottomWidth="none"
            boxShadow="none"
            defaultIndex={defaultIndex}
            mt={5}
            pb={10}
          >
            {MENUS.map((menu) => (
              <AccordionItem border="none" key={menu.id}>
                <AccordionButton
                  _focus={{
                    boxShadow: 'none',
                  }}
                  onClick={handleMenuSelect(menu)}
                  py="4"
                  sx={{
                    backgroundColor:
                      menu.url && checkIsMenuActive(menu.url)
                        ? 'primary.lighter'
                        : '',
                    borderRight:
                      menu.url && checkIsMenuActive(menu.url)
                        ? `2px solid ${extendedColors.primary.default}`
                        : '',
                    outline: 'none',
                  }}
                >
                  <Text
                    noOfLines={1}
                    size="bodyTextLargeSemibold"
                    textAlign="left"
                    title={menu.title}
                    width="100%"
                  >
                    {menu.title === MESSAGE_MENU_TEXT ? (
                      <>
                        {menu.title}
                        {unreadMessageCount > 0 && (
                          <Badge
                            alignItems="center"
                            borderRadius="50%"
                            colorScheme="red"
                            display="inline-flex"
                            fontSize="10px"
                            height="15px"
                            justifyContent="center"
                            ml={1}
                            variant="subtle"
                            width="15px"
                          >
                            {unreadMessageCount}
                          </Badge>
                        )}
                      </>
                    ) : (
                      menu.title
                    )}
                  </Text>
                </AccordionButton>
                {!!menu.children?.length && (
                  <AccordionPanel
                    sx={{
                      p: 0,
                      my: 2,
                    }}
                  >
                    {menu.children?.map((item) => (
                      <Box
                        key={item.id}
                        onClick={handleMenuItemSelect(item.url)}
                        sx={{
                          backgroundColor: checkIsMenuActive(item.url)
                            ? 'primary.lighter'
                            : '',
                          borderRight: checkIsMenuActive(item.url)
                            ? `2px solid ${extendedColors.primary.default}`
                            : '',
                          py: 4,
                          cursor: 'pointer',
                        }}
                      >
                        <Text
                          color="neutral.default"
                          key={item.id}
                          marginLeft="40px"
                          noOfLines={2}
                          size="bodyTextMediumSemibold"
                        >
                          {item.title}
                        </Text>
                      </Box>
                    ))}
                  </AccordionPanel>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default PatientPortalDrawer;
