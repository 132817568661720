import { SimpleGrid } from '@chakra-ui/react';
import { FormTextField } from 'common';
import FormMaskedPhoneInput from 'common/form/FormMaskedPhoneInput';
import { formSimpleGridGap } from 'constants/styles';

const ShippingContact = () => (
  <SimpleGrid columns={{ base: 1 }} gap={formSimpleGridGap}>
    <FormTextField label="Email Address" name="shippingEmail" />
    <FormMaskedPhoneInput label="Phone Number" name="shippingPhone" />
  </SimpleGrid>
);

export default ShippingContact;
