import { Mail } from 'react-feather';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import LogoWithTagline from 'common/LogoWithTagline';
import { spacing } from 'constants/styles';

const { CONTAINER_X_PADDING } = spacing;

const SurveyHeader = () => (
  <Box
    as="header"
    bg="white"
    boxShadow="md"
    position="fixed"
    top="0"
    width="full"
    zIndex="10"
  >
    <Flex
      align="center"
      height="5rem"
      justifyContent="space-between"
      marginX="auto"
      maxWidth="896px"
      paddingX={CONTAINER_X_PADDING}
      sx={{
        a: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <LogoWithTagline />

      <Popover placement="auto-start">
        <PopoverTrigger>
          <Box
            as={Text}
            color="primary.default"
            cursor="pointer"
            size="bodyTextLargeSemibold"
          >
            Help?
          </Box>
        </PopoverTrigger>
        <Box
          as={PopoverContent}
          bg="primary.default"
          borderColor="primary.default"
          width="auto"
        >
          <PopoverArrow />
          <PopoverCloseButton color="white" />
          <PopoverHeader border="0">
            <Box as={Heading} color="white" size="heading3">
              We are here to help
            </Box>
          </PopoverHeader>
          <PopoverBody>
            <Grid display="flex" flexDirection="column" gap={4}>
              <GridItem alignItems="center" display="flex">
                <Mail color="white" height="18px" width="20px" />
                <Box as={Text} color="white" ml={2} size="bodyTextLarge">
                  Inquire via email at support@mat-rx.com
                </Box>
              </GridItem>
              {/* <GridItem alignItems="center" display="flex">
                <Image height="20px" src={cellphone} width="20px" />
                <Box as={Text} color="white" ml={2} size="bodyTextLarge">
                  Text us at (000) 000-0000
                </Box>
              </GridItem> */}
            </Grid>
          </PopoverBody>
          <PopoverFooter border="0" mt={1}>
            <Box as={Text} color="white" size="bodyTextLarge">
              Message and data rates may apply.
            </Box>
          </PopoverFooter>
        </Box>
      </Popover>
    </Flex>
  </Box>
);
export default SurveyHeader;
