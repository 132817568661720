import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { LoadingIndicator } from 'common';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import { ISurveyAnswer } from 'interfaces/onlineAssessment';
import SurveyLayout from 'layouts/SurveyLayout';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectQuestionaires, setAnswer } from 'stores/online-assessment';

import Questionaire from './Questionaire';
import QuestionIndicator from './QuestionIndicator';

const OnlineAssessment = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const params = useParams();
  const questionId = params.id ? +params.id : -1;
  // These are the questions from cosmicJS
  const questionaires = useAppSelector(selectQuestionaires);
  const totalQuestions = questionaires?.length;

  // Current questionaire (based on question id route param)
  const currentQuestionaire = useMemo(() => {
    if (!questionId) return null;

    return questionaires.find((item) => item.questionId === questionId);
  }, [questionId, questionaires]);

  // Next Click is triggered only for multiple select
  const onNextClick = () => {
    if (questionId === misc.PRE_SIGNUP_STEP) {
      navigate(uiRoutes.preSignup);
      return;
    }

    if (questionId >= questionaires.length) {
      navigate(uiRoutes.surveySuccess);
      return;
    }

    const nextQuestionId = questionId + 1;
    navigate(
      `${uiRoutes.onlineAssessment.replace(':id', nextQuestionId.toString())}`
    );
  };

  const onBackClick = () => {
    if (questionId < 2) return;
    const prevQuestionId = questionId - 1;

    // Temporary Solution but please fix it later - Sujan
    if (questionId !== 4)
      navigate(
        `${uiRoutes.onlineAssessment.replace(':id', prevQuestionId.toString())}`
      );
  };

  const onSelectOption = (
    option: ISurveyAnswer,
    updateId: string | undefined,
    { multipleSelect }: { multipleSelect: boolean },
    { noneOfTheOptionSelect }: { noneOfTheOptionSelect: number | null },
    { allOfTheOptionSelect }: { allOfTheOptionSelect: number | null }
  ) => {
    const noneSelected =
      multipleSelect && option.id === noneOfTheOptionSelect?.toString();

    const allSelected =
      multipleSelect && option.id === allOfTheOptionSelect?.toString();

    dispatch(
      setAnswer({
        questionId,
        updateId,
        answers: [option],
        multipleSelect,
        selectNone: noneSelected,
        allSelect: allSelected,
      })
    );

    if (multipleSelect) {
      return;
    }

    onNextClick();
  };

  if (!currentQuestionaire) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  return (
    <SurveyLayout>
      <Box mt={120}>
        <QuestionIndicator
          currentQuestion={questionId}
          totalQuestions={totalQuestions}
        />
        <Questionaire
          currentQuestionaire={currentQuestionaire}
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          onSelectOption={onSelectOption}
        />
      </Box>
    </SurveyLayout>
  );
};

export default OnlineAssessment;
