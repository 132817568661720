import { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { Lock } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import * as otpAPI from 'api/otp';
import regex from 'constants/regex';
import uiRoutes from 'constants/uiRoutes';
import { PatientStatusType } from 'enum/PatientStatus';
import { IError } from 'interfaces/http';
import { IGenerateOtpSchema, IVerifyOtpSchema } from 'interfaces/otp';
import SurveyLayout from 'layouts/SurveyLayout';
import { selectPatient, updatePatient } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import breakpoints from 'styles/customTheme/breakpoints';
import { extendedColors } from 'styles/customTheme/colors';
import { getLocal, setLocal } from 'utils/storage';

const OtpPin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isLargerThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  const [otpCode, setOtpCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const patient = useAppSelector(selectPatient);

  const handlePinChange = (data: string) => {
    setOtpCode(data);
  };

  useEffect(() => {
    const test = regex.OTP.test(otpCode);
    if (!test) {
      setErrorMessage('');
      return;
    }

    const otpToken = getLocal('otpToken');
    if (!otpToken) {
      return;
    }

    if (!patient?.phone) {
      return;
    }

    const verifyOtpToken = async () => {
      const payload: IVerifyOtpSchema = {
        token: otpToken,
        phone: patient.phone || '',
        otpCode,
      };
      try {
        setIsSubmitting(true);
        const response = await otpAPI.verifyOtpToken(payload);
        if (response.data?.isVerified) {
          dispatch(updatePatient({ status: PatientStatusType.VERIFIED }));
          navigate(uiRoutes.optInSms);
        }
      } catch (err) {
        setErrorMessage((err as IError)?.message);
      } finally {
        setIsSubmitting(false);
      }
    };

    verifyOtpToken();
  }, [otpCode, patient, dispatch, navigate]);

  const onResendCodeClick = async () => {
    if (!patient?.id || !patient.phone) {
      return;
    }

    const generateOtpPayload: IGenerateOtpSchema = {
      patientId: patient.id,
      phone: patient.phone,
    };
    setIsResendingCode(true);
    const generateOtpResponse = await otpAPI.generateOtpToken(
      generateOtpPayload
    );
    setIsResendingCode(false);
    if (generateOtpResponse.data?.otpToken) {
      setLocal('otpToken', generateOtpResponse.data.otpToken);
    }
    toast({
      title: 'We have sent a new OTP code. Please check.',
      status: 'success',
      isClosable: true,
    });
  };
  const pinInputDimension = isLargerThanMd ? '70px' : '50px';

  return (
    <SurveyLayout>
      <Box marginTop="200px" marginX="auto">
        <Box display="flex" justifyContent="center">
          <Lock height="72px" style={{ marginRight: 16 }} width="58px" />
        </Box>
        <Heading mt={4} size="heading1" textAlign="center">
          To ensure our mutual security...
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          display="flex"
          justifyContent="center"
          style={{ marginTop: 8 }}
        >
          <Text size="bodyTextLarge">
            We have sent a code to {patient?.phone},
          </Text>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          display="flex"
          justifyContent="center"
          mb={4}
        >
          <Text size="bodyTextLarge">please enter it in the field below.</Text>
        </SimpleGrid>

        <Box width="100%">
          <Flex flexWrap="wrap" mt="6" width="100%">
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              display="flex"
              justifyContent="center"
              spacing={4}
              width="100%"
            >
              <ReactCodeInput
                disabled={isSubmitting}
                fields={6}
                inputMode="numeric"
                inputStyle={{
                  fontFamily: 'monospace',
                  margin: '4px',
                  width: pinInputDimension,
                  height: pinInputDimension,
                  borderRadius: '3px',
                  fontSize: '32px',
                  borderSpacing: '16px',
                  backgroundColor: extendedColors.primary.light,
                  textAlign: 'center',
                  border: `1px solid ${extendedColors.primary.light}`,
                }}
                inputStyleInvalid={{
                  fontFamily: 'monospace',
                  margin: '4px',
                  width: pinInputDimension,
                  height: pinInputDimension,
                  borderRadius: '3px',
                  fontSize: '32px',
                  backgroundColor: extendedColors.primary.light,
                  color: extendedColors.error.default,
                  border: `1px solid ${extendedColors.error.default}`,
                  textAlign: 'center',
                }}
                isValid={!errorMessage}
                name="sms"
                onChange={handlePinChange}
                type="number"
                value={otpCode}
              />
            </SimpleGrid>
          </Flex>
        </Box>

        {!!errorMessage && (
          <Box mt={8}>
            <Text color="error.default" size="bodyTextLarge" textAlign="center">
              {errorMessage}
            </Text>
          </Box>
        )}

        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          display="flex"
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <Text size="bodyTextLarge">Did not receive code?</Text>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          display="flex"
          justifyContent="center"
        >
          <Text color="primary.default" size="bodyTextLarge">
            {isResendingCode ? (
              <Box display="inline-block">Sending...</Box>
            ) : (
              <Box
                _hover={{ textDecoration: 'underline' }}
                cursor="pointer"
                display="inline-block"
                onClick={onResendCodeClick}
                tabIndex={0}
              >
                Resend OTP Code
              </Box>
            )}{' '}
            or{' '}
            <Box
              _hover={{ textDecoration: 'underline' }}
              as={Link}
              to={uiRoutes.essentials}
            >
              Change Phone number
            </Box>
          </Text>
        </SimpleGrid>
      </Box>
    </SurveyLayout>
  );
};

export default OtpPin;
