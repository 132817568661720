import { Box, Spinner, SpinnerProps } from '@chakra-ui/react';

interface IProps {
  containerHeight?: string;
  size?: string;
  spinnerProps?: SpinnerProps;
}

const LoadingIndicator = ({ containerHeight, size, spinnerProps }: IProps) => (
  <Box
    sx={{
      width: '100%',
      height: containerHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner
      color="primary.default"
      size={size}
      thickness="4px"
      {...spinnerProps}
    />
  </Box>
);

LoadingIndicator.defaultProps = {
  containerHeight: '20rem',
  size: 'xl',
  spinnerProps: undefined,
};

export default LoadingIndicator;
