import { ISurvey } from 'interfaces/onlineAssessment';
import {
  IEssentialsSchema,
  IPatientProfileSchema,
  ISignupForm,
  ISignupSchema,
} from 'interfaces/patient';

import { formatDateForPayload } from './date';

export const formatPatientSignupPayload = ({
  formData,
  source,
  survey,
}: {
  formData: ISignupForm;
  source: string;
  survey: ISurvey[];
}): ISignupSchema => {
  const surveyToSend = survey
    .filter((item) => item.answers?.length)
    .map((item) => ({
      id: item.id ?? undefined,
      questionId: item.questionId.toString(),
      question: item.question,
      answers: item.answers?.map((ans) => ({
        id: ans.id,
        value: ans.value,
      })),
      followUpQuestion: item.followUpQuestion ?? '',
      followUpAnswer: item.followUpAnswer ?? '',
    }));
  return {
    firstName: formData.firstName,
    middleName: formData.middleName,
    lastName: formData.lastName,
    email: formData.email,
    password: formData.password,
    source,
    surveys: surveyToSend,
  };
};

export const formatPatientEssentialsPayload = (
  data: IEssentialsSchema
): IEssentialsSchema => ({
  ...data,
  dob: formatDateForPayload(data.dob),
});

export const formatPatientProfilePayload = (
  data: IPatientProfileSchema
): IPatientProfileSchema => ({
  ...data,
  dob: formatDateForPayload(data.dob),
});
