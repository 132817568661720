import { ArrowRight } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { sectionSubtitleFocused, spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IHeroBanner } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { CONTAINER_X_PADDING, MAX_WIDTH } = spacing;

interface Props {
  data: IHeroBanner;
}

const HeroBanner = (props: Props) => {
  const { data } = props;

  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={{
        base: '100vh',
        md: '75vh',
        lg: '100vh',
      }}
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <Box
        bgImage={data?.background_image?.url}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        height="100vh"
        marginTop="110px"
      >
        <Box
          marginX="auto"
          maxWidth={MAX_WIDTH}
          paddingX={CONTAINER_X_PADDING}
          width="100%"
        >
          <Heading
            color="white.default"
            fontSize={{
              base: '3rem',
              sm: '3rem',
              md: '4rem',
              '2xl': '4rem',
              '3xl': '5rem',
            }}
            fontWeight="bold"
            mb="4"
            pt={{ base: '140px', sm: '140px', md: '160px' }}
          >
            {data?.title?.map((item) => (
              <Text key={item.text}>{item.text}</Text>
            ))}
          </Heading>
          <RouterLink
            to={
              isLoggedIn
                ? uiRoutes.patientDashboard.profile
                : uiRoutes.serviceAvailability
            }
          >
            <Button mt={{ base: '10', xl: '12' }} variant="primaryLg">
              <Text as="span" mr="3">
                {data?.button_text}
              </Text>
              <ArrowRight />
            </Button>
          </RouterLink>
        </Box>
        <Box
          alignItems="center"
          background="#01183A"
          display="flex"
          height={{ base: '68px', md: '56px' }}
          left="0"
          position="absolute"
          right="0"
          top={{ base: '144', md: '88' }}
        >
          <Box
            marginX="auto"
            maxWidth={MAX_WIDTH}
            paddingX={CONTAINER_X_PADDING}
            textAlign="center"
            width="100%"
          >
            <Heading
              sx={{
                ...sectionSubtitleFocused,
                color: 'white',
                marginBottom: '0',
                fontWeight: 'semibold',
                fontSize: {
                  base: '1.1rem',
                  md: '1.4rem',
                  lg: '1.4rem',
                },
              }}
            >
              {data?.subtitle}
            </Heading>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroBanner;
