export const POLICIES_IFRAME_SOURCE = {
  cookiePolicy:
    'https://app.termly.io/document/cookie-policy/ac9da3df-e655-43e3-90a0-3ed30b674dd7',
  disclaimer:
    'https://app.termly.io/document/disclaimer/57faf68f-18f2-4017-88df-4ff3eec3ff67',
  privacyPolicy:
    'https://app.termly.io/document/privacy-policy/c8b4f1ea-afc8-4bf0-805b-1a1c379ceb86',
  returnPolicy:
    'https://app.termly.io/document/return-policy/bef58641-527c-494f-9d90-ed4add900ef6',
  shippingPolicy:
    'https://app.termly.io/document/shipping-policy/f678549c-bebd-44de-a19a-811c98b0f099',
  termsOfService:
    'https://app.termly.io/document/terms-of-use-for-ecommerce/3d56c9aa-12ab-41fb-8840-100e62af914c',
};
