import { useState } from 'react';
import { Mail } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import { Option } from 'common';
import uiRoutes from 'constants/uiRoutes';
import { IError } from 'interfaces/http';
import SurveyLayout from 'layouts/SurveyLayout';

enum OptInSmsOption {
  enable = 'enable',
  disable = 'disable',
}

const OptIn = () => {
  const navigate = useNavigate();

  const [optInSms, setOptnInSms] = useState<OptInSmsOption | ''>('');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const options = [
    {
      id: 1,
      label: 'Yes! Text me valuable updates',
      value: OptInSmsOption.enable,
    },
    {
      id: 2,
      label: "I don't want text updates",
      value: OptInSmsOption.disable,
    },
  ];

  const handleContinue = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        isOptInSms: optInSms === OptInSmsOption.enable,
      };
      await patientAPI.saveOptInSms(payload);
      navigate(uiRoutes.accountCreationSuccess);
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <SurveyLayout>
      <Box mt={120}>
        {!!errorMessage && (
          <Alert mb={4} status="error">
            <AlertIcon />
            <AlertTitle>{errorMessage}</AlertTitle>
          </Alert>
        )}
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box
            as={Mail}
            color="primary.default"
            height="64px"
            // style={{ marginRight: 32 }}
            width="50px"
          />
          <Heading mt={4} size="heading1" textAlign="center">
            Opt-in for SMS notification
          </Heading>
        </Box>

        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          display="flex"
          justifyContent="center"
          style={{ marginTop: 16 }}
        >
          <Text size="bodyTextLarge" textAlign="center">
            Stay updated via text on all activity related to your MATRx account.
            Get news and new information regarding MATRx products or services.
          </Text>
        </SimpleGrid>

        <Box width="100%">
          <Flex flexWrap="wrap" mt={8} width="100%">
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              spacing={4}
              width="100%"
            >
              {options.map((item) => (
                <Option
                  isActive={item.value === optInSms}
                  key={item.id}
                  onClick={() => setOptnInSms(item.value)}
                  text={item.label}
                />
              ))}
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              style={{ marginTop: 32 }}
            >
              <Text size="bodyTextLarge">
                By choosing “Yes? Text me valuable updates”, you agree to
                receive text notifications from MAT-Rx to your registered phone
                number.
              </Text>
              <Text size="bodyTextLarge">
                You do not need to opt-in to receive treatment from MAT-Rx.
                Message data rates may apply. Message frequency will vary.
              </Text>
              <Text size="bodyTextLarge">
                Reply HELP for more info. Reply STOP to opt out.
              </Text>
              <Text mt={6} size="bodyTextLarge">
                Read MAT-Rx’s SMS
                <Box as={Link} color="primary.default" to="#">
                  {' '}
                  policy
                </Box>
              </Text>
            </SimpleGrid>

            <Button
              disabled={!optInSms || isSubmitting}
              isLoading={isSubmitting}
              mt={8}
              onClick={handleContinue}
              size="lg"
              type="button"
              variant="primaryBlock"
              width="100%"
            >
              Continue
            </Button>
          </Flex>
        </Box>
      </Box>
    </SurveyLayout>
  );
};

export default OptIn;
