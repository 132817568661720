import { useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { AlertStatusType } from 'interfaces/alert';

import { DocumentDragandDrop } from './DocumentDragandDrop';

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
  setToggleFetchDocumentDetails: (value: React.SetStateAction<boolean>) => void;
  setAlertMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      status: AlertStatusType;
    }>
  >;
}

const UploadDocumentDialog = ({
  isOpen,
  onClose,
  setToggleFetchDocumentDetails,
  setAlertMessage,
}: IProps) => {
  const [isSubmittingForModalCloseButton, setIsSubmittingForModalCloseButton] =
    useState<boolean>(false);
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="primary.default">Upload document</ModalHeader>
        <hr />
        <ModalCloseButton disabled={isSubmittingForModalCloseButton} />
        <ModalBody>
          <Text mb={4} size="bodyTextLarge">
            Attach your Documents
          </Text>

          <DocumentDragandDrop
            onClose={onClose}
            setAlertMessage={setAlertMessage}
            setIsSubmittingForModalCloseButton={
              setIsSubmittingForModalCloseButton
            }
            setToggleFetchDocumentDetails={setToggleFetchDocumentDetails}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadDocumentDialog;
