import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Text } from '@chakra-ui/react';
import uiRoutes from 'constants/uiRoutes';
import { setAuthToken } from 'utils/axios';
import { clearLocal, clearSession } from 'utils/storage';

const Logout = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    clearLocal();
    clearSession();
    setAuthToken('');
    navigate(uiRoutes.login);
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <Text variant="bodyTextLarge">Logging out...</Text>
    </Box>
  );
};

export default Logout;
