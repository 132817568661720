/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage } from 'interfaces/message';
import { RootState } from 'stores';

interface MessageState {
  messages: IMessage[] | [];
  unreadMessageCount: number;
  unreadMessageFetchToggle: boolean;
}

const initialState: MessageState = {
  messages: [],
  unreadMessageCount: 0,
  unreadMessageFetchToggle: false, // Toggle this flag to fetch unread message and update the store
};

export const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setMessages: (state, action: PayloadAction<IMessage[] | null>) => {
      state.messages = action.payload ?? [];
    },
    setMessage: (state, action: PayloadAction<IMessage>) => {
      if (!state.messages) return;
      state.messages.push(action.payload as never);
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setUnreadMessageCount: (state, action: PayloadAction<number>) => {
      state.unreadMessageCount = action.payload;
    },
    toggleUnreadMessageFetchFlag: (state) => {
      state.unreadMessageFetchToggle = !state.unreadMessageFetchToggle;
    },
  },
});

// Actions
export const {
  setMessages,
  setMessage,
  clearMessages,
  setUnreadMessageCount,
  toggleUnreadMessageFetchFlag,
} = slice.actions;

// Selectors
export const selectMessages = (state: RootState) => state.messages.messages;
export const selectUnreadMessageCount = (state: RootState) =>
  state.messages.unreadMessageCount;
export const selectUnreadMessageFetchToggle = (state: RootState) =>
  state.messages.unreadMessageFetchToggle;

// Reducer
export default slice.reducer;
