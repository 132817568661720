import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import check from 'assets/images/svg/check.svg';
import uiRoutes from 'constants/uiRoutes';
import SurveyLayout from 'layouts/SurveyLayout';
import { useAppSelector } from 'stores/hooks';
import { selectQuestionaires } from 'stores/online-assessment';

const SurveySuccess = () => {
  const navigate = useNavigate();

  const questionaires = useAppSelector(selectQuestionaires);
  const lastQuestionId = questionaires.length;

  const onProceedClick = () => {
    navigate(uiRoutes.checkout);
  };

  const onBackClick = () => {
    navigate(
      `${uiRoutes.onlineAssessment.replace(':id', lastQuestionId?.toString())}`
    );
  };

  return (
    <SurveyLayout>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '90vh',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxW="497px"
        >
          <Image height="60px" src={check} width="60px" />
          <Box
            as={Heading}
            color="text.default"
            mt={8}
            size="heading1"
            textAlign="center"
          >
            Well done!
          </Box>
          <Box
            as={Text}
            color="text.faded"
            mt={5}
            px={{ base: 0, md: 5 }}
            size="bodyTextLargeSemibold"
            textAlign="center"
          >
            Based on your responses to the assessment questions, we believe you
            could benefit from our FDA-approved medication. Once you complete
            checkout, we can get your medication on its way to you - without a
            trip to the doctor and/or pharmacy, but delivered quickly,
            discreetly and conveniently - directly to your door.
          </Box>
          <Stack mt={10} spacing={2} width="100%">
            <Button
              onClick={onProceedClick}
              type="button"
              variant="primaryBlock"
            >
              Proceed to Checkout
            </Button>
            <Button
              leftIcon={<ChevronLeft />}
              onClick={onBackClick}
              type="button"
              variant="primaryLinkLg"
              width="100%"
            >
              Go Back
            </Button>
          </Stack>
        </Box>
      </Box>
    </SurveyLayout>
  );
};

export default SurveySuccess;
