import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  InputProps,
  Select,
} from '@chakra-ui/react';

type Props = {
  timeArray: number[];
  placeholder: string;
  name: string;
  showHelperText?: boolean;
} & InputProps;

const DropdownMonthDayYear = ({
  timeArray,
  name,
  showHelperText,
  placeholder,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <Select
            cursor="pointer"
            fontWeight={700}
            placeholder={placeholder}
            size="lg"
            {...field}
            fontSize="16px"
          >
            {timeArray.map((item: number) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
          {!!errors[name] && showHelperText && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

DropdownMonthDayYear.defaultProps = {
  showHelperText: false,
};

export default DropdownMonthDayYear;
