import apiRoutes from 'constants/apiRoutes';
import { IListResponse, IResponse } from 'interfaces/http';
import { IMessage } from 'interfaces/message';
import { baseRequest } from 'utils/axios';

export const getMessages = async (): Promise<
  IResponse<IListResponse<IMessage>>
> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: apiRoutes.patient.messages.getAll,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};

export const sendMessage = async (
  message: string
): Promise<IResponse<IMessage>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: apiRoutes.patient.messages.sendMessage,
    data: { message },
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};

export const getUnreadMessageCount = async (): Promise<IResponse<number>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: apiRoutes.patient.messages.getUnreadMessageCount,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};

export const changeMessageStatus = async (
  userId: string
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: apiRoutes.patient.messages.changeMessageStatus,
    params: { userId },
    data: {
      isRead: true,
    },
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
