const Textarea = {
  sizes: {
    md: {
      field: {
        borderRadius: 'md',
        shadow: 'sm',
      },
    },
  },
  variants: {
    filled: {
      bg: 'white',
    },
  },
};

export default Textarea;
