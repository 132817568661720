import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { baseRequest } from 'utils/axios';

const { drugOfferings } = apiRoutes;

export const fetchDrugOfferings = async (params: {
  client: string;
}): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: drugOfferings.get,
    params,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
