import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  LogEventType,
  LogType,
  saveActivityLogs,
  Severity,
} from 'api/activityLogs';
import * as cosmicAPI from 'api/cosmic';
import { LoadingIndicator } from 'common';
import { objectSlug } from 'constants/cosmic';
import uiRoutes from 'constants/uiRoutes';
import validationMessage from 'constants/validationMessage';
import SurveyLayout from 'layouts/SurveyLayout';
import { buildObjectSlug, getCosmicEnvFromEnv } from 'utils/cosmic';
import { getClient, getSubDomain } from 'utils/misc';
import ZipCodes from 'zipcodes';

export interface IState {
  state_abbreviation: string;
}

interface IFormSchema {
  zipCode: string;
}

const defaultValues = {
  zipCode: '',
};

const ServiceAvailablity = () => {
  const navigate = useNavigate();

  const [availableStates, setAvailableStates] = useState<IState[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm<IFormSchema>({ defaultValues });

  const { ZIP_CODE_REQUIRED } = validationMessage;

  /** Stores an array of US States */
  const [states, setStates] = useState<string[]>([]);

  const proceedToAssessment = () => {
    navigate(uiRoutes.onlineAssessment.replace(':id', '1'));
  };

  /** Updates the `states` array with the list of states fetched from CMS */
  useEffect(() => {
    setStates(
      availableStates.map((state) => state.state_abbreviation?.toUpperCase())
    );
  }, [availableStates]);

  const onSubmit = (data: IFormSchema) => {
    const area: { state: string } | undefined = ZipCodes.lookup(data.zipCode);

    // If the zipcode is not valid
    if (!area) {
      setError('zipCode', {
        message: 'Sorry, our service is not available in your area.',
      });
      saveActivityLogs({
        user: {
          id: 'NA',
          name: 'Anonymous',
        },
        event: {
          type: LogEventType.GET_STARTED,
        },
        log: {
          module: 'Patient',
          note: `New user tried to get started from zip code ${data.zipCode}`,
          type: LogType.INFO,
        },
        additionalInfo: { zipcode: `${data.zipCode}` },
        severity: Severity.MEDIUM,
        status: 'Active',
      });
      return;
    }

    const { state } = area;
    // if the service is available for the zipcode provided
    if (states.includes(state)) {
      saveActivityLogs({
        user: {
          id: 'NA',
          name: 'Anonymous',
        },
        event: {
          type: LogEventType.GET_STARTED,
        },
        log: {
          module: 'Patient',
          note: `New user get started from zip code ${data.zipCode}`,
          type: LogType.INFO,
        },
        additionalInfo: { zipcode: `${data.zipCode}` },
        severity: Severity.MEDIUM,
        status: 'Active',
      });
      proceedToAssessment();
      return;
    }

    // If the service is not available for the zipcode provided
    setError('zipCode', {
      message: 'Sorry, our service is not available in your area.',
    });
    saveActivityLogs({
      user: {
        id: 'NA',
        name: 'Anonymous',
      },
      event: {
        type: LogEventType.GET_STARTED,
      },
      log: {
        module: 'Patient',
        note: `New user tried to get started from zip code ${data.zipCode}`,
        type: LogType.INFO,
      },
      additionalInfo: { zipcode: `${data.zipCode}` },
      severity: Severity.MEDIUM,
      status: 'Active',
    });
  };

  useEffect(() => {
    const getContent = async () => {
      const subdomain = getSubDomain();
      const { client, cosmicObjectTypeSlug } = getClient(subdomain);
      const clientLowerCased = client.toLowerCase();
      const cosmicEnv = getCosmicEnvFromEnv(
        process.env.REACT_APP_ENVIRONMENT || 'DEV'
      );

      const data = await cosmicAPI.getContent({ type: cosmicObjectTypeSlug });

      if (!data?.objects?.length) {
        return;
      }

      const content: any = {};

      data?.objects?.forEach((item: any) => {
        switch (item.slug) {
          case buildObjectSlug(objectSlug.AVAILABLE_STATES, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.availableStatesContent = item;
            break;

          default:
            break;
        }
      });

      setAvailableStates(content?.availableStatesContent?.metadata?.states);
    };

    getContent();
  }, []);

  if (!setAvailableStates.length) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  return (
    <SurveyLayout>
      <Box bg="white" minHeight="100vh" width="100%">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginX="auto"
          maxWidth="700px"
          minHeight="100vh"
          pb={{ base: '10', '3xl': '20' }}
        >
          <Heading mb={2} size="heading1" textAlign="center">
            Online Assessment
          </Heading>
          <Text size="bodyTextMediumSemibold" textAlign="center">
            Please enter your zip code to see if our service is available in
            your area.
          </Text>

          <Box as="form" mt={8} onSubmit={handleSubmit(onSubmit)} width="100%">
            <Stack spacing={6}>
              <FormControl isInvalid={!!errors?.zipCode} width="100%">
                <Controller
                  control={control}
                  name="zipCode"
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      customInput={Input}
                      format="#####"
                      id="zip-code"
                      mask="_"
                      placeholder="Enter Zipcode"
                      style={{ backgroundColor: 'white' }}
                    />
                  )}
                  rules={{
                    required: ZIP_CODE_REQUIRED,
                    validate: (value) => {
                      if (!value) return true;
                      return (
                        !value.includes('_') || 'Please enter a valid zip code'
                      );
                    },
                  }}
                />
                <FormErrorMessage>
                  {errors?.zipCode && errors?.zipCode?.message}
                </FormErrorMessage>
              </FormControl>
              <Button type="submit" variant="primaryBlock">
                Get Started
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </SurveyLayout>
  );
};

export default ServiceAvailablity;
