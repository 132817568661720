import apiRoutes from 'constants/apiRoutes';
import { INotificationSettingsSchema } from 'interfaces/patient';
import { baseRequest } from 'utils/axios';

export const editNotificationSetting = async (
  data: INotificationSettingsSchema
) => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: apiRoutes.patient.notificationSettings.update,
    data,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
