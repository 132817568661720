import React, { useState } from 'react';
import { FileText } from 'react-feather';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { fetchDocumentBufferData } from 'api/patient';
import downloadBinaryObject from 'utils/fileSaver';
import { parseHtml } from 'utils/parseHtml';

interface IProps {
  filename: string;
  documentId: string;
  isDocumentFileName?: boolean;
}

export const ConfirmDownloadDocumentDialog = ({
  filename,
  documentId,
  isDocumentFileName,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [bufferData, setBufferData] = useState<[]>([]);
  const [contentType, setContentType] = useState<string>('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const downloadDocument = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetchDocumentBufferData(documentId);
      setBufferData(response?.data?.bufferData);

      setContentType(response?.data?.contentType);

      // downloadBinaryObject (util which uses FileSaver dependency to download files using three arguments bufferData, contentType and filename )
      downloadBinaryObject(bufferData, contentType, filename);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <>
      {isDocumentFileName ? (
        <Box cursor="pointer" display="flex" onClick={onOpen} p={1}>
          <FileText />
          <Text fontSize="14px" ml={2} size="bodyTextLargeSemibold">
            {parseHtml(filename, 40)}
          </Text>
        </Box>
      ) : (
        <Text
          _hover={{ textDecoration: 'underline' }}
          color="primary.default"
          cursor="pointer"
          fontSize="14px"
          onClick={onOpen}
          size="bodyTextMediumSemibold"
        >
          Download
        </Text>
      )}

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={{ base: 'sm', md: 'md', lg: 'lg' }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Document ?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to download {`${filename}`} document?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef} variant="primaryLinkLg">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                ml={3}
                onClick={downloadDocument}
                variant="primaryLg"
              >
                Download
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

ConfirmDownloadDocumentDialog.defaultProps = {
  isDocumentFileName: false,
};
