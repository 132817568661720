import apiRoutes from 'constants/apiRoutes';
import misc from 'constants/misc';
import { IResponse } from 'interfaces/http';
import { IOrdersListResponse } from 'interfaces/order';
import { baseRequest } from 'utils/axios';

const { ALL_PAGINATION_DATA } = misc;

export const getOrders = async (): Promise<IResponse<IOrdersListResponse>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: apiRoutes.patient.orders.getAll,
    params: {
      limit: ALL_PAGINATION_DATA.limit,
      offset: ALL_PAGINATION_DATA.offset,
    },
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
