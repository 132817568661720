import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Image, Text } from '@chakra-ui/react';
import * as cosmicAPI from 'api/cosmic';
import { objectSlug } from 'constants/cosmic';
import uiRoutes from 'constants/uiRoutes';
import { buildObjectSlug, getCosmicEnvFromEnv } from 'utils/cosmic';
import { getClient, getSubDomain } from 'utils/misc';

const LogoWithTagline = () => {
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const getContent = async () => {
      const subdomain = getSubDomain();
      const { client, cosmicObjectTypeSlug } = getClient(subdomain);
      const clientLowerCased = client.toLowerCase();
      const cosmicEnv = getCosmicEnvFromEnv(
        process.env.REACT_APP_ENVIRONMENT || 'DEV'
      );

      const data = await cosmicAPI.getContent({ type: cosmicObjectTypeSlug });

      if (!data?.objects?.length) {
        return;
      }

      data?.objects?.forEach((item: any) => {
        switch (item.slug) {
          case buildObjectSlug(objectSlug.HEADER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            setLogo(item.metadata?.logo?.url || '');
            break;

          default:
            break;
        }
      });
    };

    getContent();
  }, []);

  if (!logo) {
    return <Box>...</Box>;
  }

  return (
    <Box>
      <Box as={RouterLink} to={uiRoutes.patientDashboard.profile}>
        <Image
          alt="matrx-logo"
          height="40px"
          objectFit="contain"
          py={0.5}
          src={logo}
        />
      </Box>
      <Box display="flex">
        <Text
          color="primary.default"
          fontSize={{ base: '12px', sm: '12px' }}
          size="bodyTextSmall"
        >
          Your trusted source for <strong>M</strong>edication <strong>A</strong>
          ssisted <strong>T</strong>reatment
        </Text>
      </Box>
    </Box>
  );
};

export default LogoWithTagline;
