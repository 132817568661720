import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import config from 'config';

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format={config.zip}
        getInputRef={ref}
        isNumericString
        mask="_"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

type Props = {
  name?: string;
  label?: string;
  isRequired?: boolean;
  showHelperText?: boolean;
} & InputProps;

const FormMaskedZipInput = ({
  name = 'zipCode',
  label,
  isRequired,
  showHelperText,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <FormLabel>{label}</FormLabel>
          <Input {...others} {...field} as={CustomNumberFormat} />
          {!!errors[name] && showHelperText && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

FormMaskedZipInput.defaultProps = {
  name: 'zipCode',
  label: 'Zip Code',
  isRequired: false,
  showHelperText: false,
};

export default FormMaskedZipInput;
