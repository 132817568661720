import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Input } from '@chakra-ui/react';
import { sendMessage } from 'api/message';
import { selectPatient, selectUserId } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { setMessage } from 'stores/patient/messages';

export interface IMessageForm {
  message: string;
}

const defaultValues: IMessageForm = {
  message: '',
};

const MessageInput = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const patientData = useAppSelector(selectPatient);
  const methods = useForm<IMessageForm>({
    defaultValues,
  });
  const {
    formState: { isDirty, isSubmitting },
    control,
  } = methods;

  const onSubmit = async (data: IMessageForm) => {
    if (!patientData || !userId) return;
    const { success, data: newMessage } = await sendMessage(data.message);
    if (success) {
      dispatch(
        setMessage({
          ...newMessage,
          createdByUser: {
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            id: userId,
          },
        })
      );
      methods.reset({
        message: '',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <Input
              _focus={{
                boxShadow: 'none',
              }}
              as="textarea"
              height="100%"
              id="message"
              minHeight="135px"
              placeholder="Compose Message"
              px={4}
              py={5}
              required
              resize="none"
              {...field}
            />
          )}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={!isDirty || isSubmitting}
            isLoading={isSubmitting}
            mt={{ base: '1rem' }}
            type="submit"
            variant="primaryLg"
            width={{ base: '100%', md: 'auto' }}
          >
            Send
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default MessageInput;
