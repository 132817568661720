import { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

type Props = {
  name: string;
  label: string;
  showHelperText?: boolean;
  required?: boolean;
} & InputProps;

const FormPasswordField = ({
  name,
  label,
  showHelperText,
  required,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <FormLabel>{required ? `${label}*` : label}</FormLabel>
          <InputGroup size="md">
            <Input {...others} {...field} type={show ? 'text' : 'password'} />
            <InputRightElement>
              <Icon
                _hover={{
                  color: 'gray.500',
                }}
                as={show ? Eye : EyeOff}
                color="gray.300"
                cursor="pointer"
                fontSize="xl"
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
          {!!errors[name] && showHelperText && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

FormPasswordField.defaultProps = {
  showHelperText: false,
  required: false,
};

export default FormPasswordField;
