/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPatient } from 'interfaces/patient';
import { RootState } from 'stores';

interface AuthState {
  userId: string | null;
  patient: IPatient | null;
  patientDetailFetchToggle: boolean; // Toggle this flag to fetch patient detail and update the store
}

const initialState: AuthState = {
  userId: null,
  patient: null,
  patientDetailFetchToggle: false,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setPatient: (state, action: PayloadAction<IPatient | null>) => {
      state.patient = action.payload;
    },

    updatePatient: (state, action: PayloadAction<Partial<IPatient>>) => {
      if (state.patient) {
        state.patient = { ...state.patient, ...action.payload };
      }
    },

    togglePatientDetailFetchFlag: (state) => {
      state.patientDetailFetchToggle = !state.patientDetailFetchToggle;
    },

    clearPatient: (state) => {
      state.patient = null;
    },

    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
  },
});

// Actions
export const {
  setPatient,
  clearPatient,
  setUserId,
  updatePatient,
  togglePatientDetailFetchFlag,
} = slice.actions;

// Selectors
export const selectPatient = (state: RootState) => state.auth.patient;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectPatientDetailFetchToggle = (state: RootState) =>
  state.auth.patientDetailFetchToggle;

// Reducer
export default slice.reducer;
