import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  sectionSubtitle,
  sectionSubtitleFocused,
  spacing,
} from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IDrugInformation } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { MAX_WIDTH } = spacing;

const TEXT_DEFAULT = 'text.default';

const TEXT_ALIGN = {
  base: 'center',
  sm: 'center',
  md: 'left',
};

interface Props {
  data: IDrugInformation;
}

const DrugInformationPage = (props: Props) => {
  const { data } = props;

  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;
  return (
    <Box
      backgroundColor="#eeefff"
      py={{ base: '60px', md: '120px' }}
      width="100%"
    >
      <Box display="flex" marginX="auto" maxWidth={MAX_WIDTH}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          display={{ base: 'flex', lg: 'flex' }}
          flexDirection={{ base: 'column-reverse', md: 'initial' }}
          justifyContent={{ base: 'flex-start', lg: 'space-between' }}
          spacingX={{ base: '0px', xl: '50px' }}
          width="100%"
        >
          <Box
            flex={{ base: '0', md: '9999' }}
            mt={{ base: '30px' }}
            p={{ base: 3.5, md: 0 }}
          >
            <Heading
              sx={{
                ...sectionSubtitleFocused,
                color: TEXT_DEFAULT,
                fontSize: {
                  base: '22px',
                  md: '28px',
                  lg: '32px',
                },
                fontWeight: 'bold',
                marginBottom: {
                  base: '0.875rem', // 14px
                },
                textAlign: TEXT_ALIGN,
              }}
            >
              {data?.title1}
            </Heading>

            <Text
              sx={{
                ...sectionSubtitle,
                color: '#333333',
                fontWeight: '400',
                textAlign: TEXT_ALIGN,
                lineHeight: { base: '28px', md: '32px' },
                marginTop: '16px',
                fontSize: { base: '0.9rem', md: '1rem' },
              }}
            >
              {data?.sub_title1}
            </Text>

            <Heading
              sx={{
                ...sectionSubtitleFocused,
                color: TEXT_DEFAULT,
                fontSize: {
                  base: '22px',
                  md: '28px',
                  lg: '32px',
                },
                fontWeight: 'bold',
                marginBottom: {
                  base: '0.875rem', // 14px
                },
                marginTop: '32px',
                textAlign: TEXT_ALIGN,
              }}
            >
              {data?.title2}
            </Heading>

            <Text
              sx={{
                ...sectionSubtitle,
                color: '#333333',
                fontWeight: '400',
                textAlign: TEXT_ALIGN,
                lineHeight: { base: '28px', md: '32px' },
                marginTop: '16px',
                fontSize: { base: '0.9rem', md: '1rem' },
              }}
            >
              {data?.sub_title2}
            </Text>
            <Box
              display="flex"
              justifyContent={{ base: 'center', md: 'flex-start' }}
              mt={{ base: '20px', xl: '40px' }}
              textAlign={{ base: 'center', md: 'left' }}
            >
              <RouterLink
                to={
                  isLoggedIn
                    ? uiRoutes.patientDashboard.profile
                    : uiRoutes.serviceAvailability
                }
              >
                <Button variant="primaryLg">
                  <Text as="span" mr="3">
                    {data?.button_text}
                  </Text>
                </Button>
              </RouterLink>
            </Box>
          </Box>

          <Box
            marginTop={{ base: '20px', md: 0 }}
            marginX={{ base: 'auto', md: '0' }}
            maxWidth={{ base: '100px', md: '240px' }}
          >
            <Image
              alt="supervisor"
              boxSize="100%"
              objectFit="unset"
              objectPosition="center"
              src={data?.image?.url}
            />
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default DrugInformationPage;
