import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@chakra-ui/react';

const SameAsBillingAddressCheckbox = () => {
  const { getValues, reset, watch } = useFormContext();

  const {
    shippingAddressLine1,
    shippingAddressLine2,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingEmail,
    shippingPhone,
    billingAddressLine1,
    billingAddressLine2,
    billingCity,
    billingState,
    billingZipCode,
    billingEmail,
    billingPhone,
  } = watch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;

    if (!value) {
      return;
    }

    const formValues = getValues();

    reset({
      ...formValues,
      shippingAddressLine1: formValues.billingAddressLine1,
      shippingAddressLine2: formValues.billingAddressLine2,
      shippingCity: formValues.billingCity,
      shippingState: formValues.billingState,
      shippingZipCode: formValues.billingZipCode,
      shippingEmail: formValues.billingEmail,
      shippingPhone: formValues.billingPhone,
    });
  };

  const isBillingAndShippingAddressSame = useMemo(
    () =>
      shippingAddressLine1 === billingAddressLine1 &&
      shippingAddressLine1 !== '' &&
      shippingAddressLine2 === billingAddressLine2 &&
      shippingCity === billingCity &&
      shippingCity !== '' &&
      shippingState === billingState &&
      shippingState !== '' &&
      shippingZipCode === billingZipCode &&
      shippingZipCode !== '' &&
      shippingEmail === billingEmail &&
      shippingEmail !== '' &&
      shippingPhone === billingPhone &&
      shippingPhone !== '',
    [
      shippingAddressLine1,
      shippingAddressLine2,
      shippingCity,
      shippingState,
      shippingZipCode,
      shippingEmail,
      shippingPhone,
      billingAddressLine1,
      billingAddressLine2,
      billingCity,
      billingState,
      billingZipCode,
      billingEmail,
      billingPhone,
    ]
  );

  return (
    <Checkbox
      isChecked={isBillingAndShippingAddressSame}
      my={4}
      onChange={onChange}
    >
      Same as Billing Address
    </Checkbox>
  );
};

export default SameAsBillingAddressCheckbox;
