import { Link } from 'react-router-dom';

import { Box, Button, Heading, Image } from '@chakra-ui/react';
import pageNotFound from 'assets/images/svg/404.svg';
import uiRoutes from 'constants/uiRoutes';
import ProfileHeader from 'layouts/ProfileHeader';
import SurveyHeader from 'layouts/SurveyHeader';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const PagenotFound = () => {
  const patient = useAppSelector(selectPatient);

  return (
    <>
      {patient ? <ProfileHeader /> : <SurveyHeader />}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        h="80vh"
        justifyContent="center"
        mb={8}
        w="full"
      >
        <Image src={pageNotFound} w={{ sm: '268px', md: '468px' }} />
        <Heading size="heading2" textAlign="center">
          Sorry, we couldn&apos;t find the page you are looking for
        </Heading>
        <Link to={patient ? uiRoutes.patientDashboard.profile : uiRoutes.home}>
          <Button mt={8} type="button" variant="primaryLg">
            Go to home
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default PagenotFound;
