import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Heading,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import Alert from 'common/Alert';
import { AlertStatusType } from 'interfaces/alert';

import DocumentDetailListItem from './DocumentDetailListItem';
import DocumentFromAdminListItem from './DocumentFromAdminListItem';
import UploadDocumentDialog from './UploadDocumentDialog';

const Documents = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documentDetails, setDocumentDetails] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });
  const [documentFromAdminDetails, setDocumentFromAdminDetails] = useState<any>(
    []
  );
  const [toggleFetchDocumentDetails, setToggleFetchDocumentDetails] =
    useState<boolean>(false);

  const [toggleFetchDocumentFromAdmin, setToggleFetchDocumentFromAdmin] =
    useState<boolean>(false);

  useEffect(() => {
    const getAllDocuments = async () => {
      const response: any = await patientAPI.getAllDocuments(true);
      setDocumentDetails(response?.data?.rows);
    };
    getAllDocuments();
  }, [toggleFetchDocumentDetails]);

  useEffect(() => {
    const getAllDocumentsFromAdmin = async () => {
      const response: any = await patientAPI.getAllDocuments(false);
      setDocumentFromAdminDetails(response?.data?.rows);
    };
    getAllDocumentsFromAdmin();
  }, [toggleFetchDocumentFromAdmin]);

  const clearAlertMessage = () => {
    setAlertMessage({
      message: '',
      status: 'error',
    });
  };

  return (
    <div>
      <Heading size="heading2">Documents</Heading>
      <Alert
        autoClose
        mb={2}
        mt={5}
        onClose={clearAlertMessage}
        status={alertMessage.status}
        text={alertMessage.message}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onOpen} variant="primaryLg">
          Upload
        </Button>
      </Box>
      <UploadDocumentDialog
        isOpen={isOpen}
        onClose={onClose}
        setAlertMessage={setAlertMessage}
        setToggleFetchDocumentDetails={setToggleFetchDocumentDetails}
      />
      <Tabs>
        <TabList>
          <Tab onClick={() => setToggleFetchDocumentDetails((prev) => !prev)}>
            My Files
          </Tab>
          <Tab onClick={() => setToggleFetchDocumentFromAdmin((prev) => !prev)}>
            Shared by mat-rx
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {documentDetails.length < 1 ? (
              'No Documents'
            ) : (
              <TableContainer mt={8}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>
                        <Text size="bodyTextMediumSemibold">Document Name</Text>
                      </Th>
                      <Th colSpan={3}>
                        <Text size="bodyTextMediumSemibold">Date</Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {documentDetails?.map((item: any) => (
                      <DocumentDetailListItem
                        documentId={item.id}
                        filedate={item?.createdAt}
                        filename={item?.originalName}
                        key={item?.id}
                        setAlertMessage={setAlertMessage}
                        setToggleFetchDocumentDetails={
                          setToggleFetchDocumentDetails
                        }
                        status={item?.isShared}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel>
            {documentFromAdminDetails.length < 1 ? (
              'No Documents'
            ) : (
              <TableContainer mt={8}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>
                        <Text size="bodyTextMediumSemibold">Document Name</Text>
                      </Th>
                      <Th colSpan={3}>
                        <Text size="bodyTextMediumSemibold">Date</Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {documentFromAdminDetails?.map((item: any) => (
                      <DocumentFromAdminListItem
                        documentId={item?.id}
                        filedate={item?.createdAt}
                        filename={item?.originalName}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Documents;
