import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import LogoWithTagline from 'common/LogoWithTagline';
import { spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IHeader } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { CONTAINER_X_PADDING, MAX_WIDTH } = spacing;

interface Props {
  data: IHeader;
  isLogoOnly?: boolean;
  isLargerScreen: boolean;
}

const Header = ({ data, isLogoOnly = false, isLargerScreen }: Props) => {
  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;

  const renderedMenus = !isLogoOnly && !isLoggedIn && (
    <Box display="flex" justifyContent="space-between">
      <RouterLink to={uiRoutes.serviceAvailability}>
        <Button variant="primaryLg"> {data?.metadata?.button_text}</Button>
      </RouterLink>
      <RouterLink to={uiRoutes.login}>
        <Button ml="2" variant="primaryTextLg">
          Log In
        </Button>
      </RouterLink>
    </Box>
  );

  return (
    <Box
      as="header"
      bg="white"
      boxShadow="sm"
      position="fixed"
      top="0"
      width="full"
      zIndex="10"
    >
      <Flex
        align="center"
        height="5.5rem"
        justifyContent="space-between"
        marginX="auto"
        maxWidth={MAX_WIDTH}
        paddingX={CONTAINER_X_PADDING}
        sx={{
          a: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <LogoWithTagline />
        {!isLogoOnly && !isLoggedIn && isLargerScreen && renderedMenus}
        {isLoggedIn && (
          <Menu>
            <MenuButton as={Box} cursor="pointer">
              <Avatar
                bg="teal.500"
                color="white.default"
                name={`${patientData?.firstName} ${patientData?.lastName}`}
              />
            </MenuButton>
            <MenuList>
              <MenuItem as={RouterLink} to={uiRoutes.patientDashboard.profile}>
                My Profile
              </MenuItem>
              <MenuItem as={RouterLink} to={uiRoutes.logout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      {!isLogoOnly && !isLoggedIn && !isLargerScreen && (
        <Box pb={2} px={4}>
          {renderedMenus}
        </Box>
      )}
    </Box>
  );
};

Header.defaultProps = {
  isLogoOnly: false,
};

export default Header;
