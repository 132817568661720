import { getApplicationIdBasedOnSubdomain } from 'common/getSubdomain';
import apiRoutes from 'constants/apiRoutes';
import { baseRequest } from 'utils/axios';

const { endpoints } = apiRoutes;

export enum LogEventType {
  GET_STARTED = 'Get Started',
}

export enum Severity {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum LogType {
  INFO = 'Info',
  WARN = 'Warn',
  ERROR = 'Error',
}

interface ISaveActivityLogs {
  user: {
    id: string;
    name: string;
  };
  event: {
    type: LogEventType;
  };
  log: {
    module: string;
    note: string;
    type: LogType;
  };
  additionalInfo: any;
  severity: Severity;
  status: string;
}

export const saveActivityLogs = async (data: ISaveActivityLogs) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: endpoints.activityLogs.save,
    data,
    headers: {
      applicationId: getApplicationIdBasedOnSubdomain(),
    },
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
