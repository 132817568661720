// import apiRoutes from 'constants/';
import Cosmic from 'cosmicjs';
// import { IResponse } from 'interfaces/http';
// import { baseRequest } from 'utils/axios';

const api = Cosmic();

// Set these values, found in Bucket > Settings after logging in at https://app.cosmicjs.com/login
const bucket = api.bucket({
  slug: process.env.REACT_APP_COSMIC_BUCKET_SLUG,
  read_key: process.env.REACT_APP_COSMIC_BUCKET_READ_KEY,
});

export const getContent = async ({ type }: { type: string }) => {
  const content = await bucket.getObjects({
    query: {
      type,
    },
    props: 'title,slug,metadata',
  });

  return content;
};
