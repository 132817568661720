import { useEffect, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as patientAPI from 'api/patient';
import { FormTextField } from 'common';
import FormPasswordField from 'common/form/FormPasswordField';
import misc from 'constants/misc';
import { formSimpleGridGap, signUpSpacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IError } from 'interfaces/http';
import { ISignupForm } from 'interfaces/patient';
import SurveyHeader from 'layouts/SurveyHeader';
import { SignupSchema } from 'schemas/patient';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectQuestionaires, selectSource } from 'stores/online-assessment';
import { setAuthState } from 'utils/auth';
import { formatPatientSignupPayload } from 'utils/patient';
import { removeSession } from 'utils/storage';

const { SURVEY_BEFORE_SIGNUP_LOCAL_KEY } = misc;

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const Signup = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [check, setCheck] = useState(false);

  const dispatch = useAppDispatch();

  const survey = useAppSelector(selectQuestionaires);
  const source = useAppSelector(selectSource);

  const methods = useForm<ISignupForm>({
    defaultValues,
    resolver: yupResolver(SignupSchema),
  });
  const { watch } = methods;

  const { firstName, middleName, lastName, email, password, confirmPassword } =
    watch();

  const onSubmit = async (data: ISignupForm) => {
    setIsSubmitting(true);
    try {
      const payload = formatPatientSignupPayload({
        formData: data,
        source,
        survey,
      });

      const response = await patientAPI.signupPatient(payload);
      if (response.data?.token) {
        setAuthState({
          token: response.data.token,
          dispatch,
        });
        // Answers for the questions before sign up are persisted in session storage
        // and cleared after signup
        removeSession(SURVEY_BEFORE_SIGNUP_LOCAL_KEY);
        navigate(uiRoutes.essentials);
      }
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset error message whenever any of the input goes empty
  useEffect(() => {
    if (
      firstName === '' ||
      middleName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      confirmPassword === ''
    ) {
      setErrorMessage('');
    }
  }, [firstName, middleName, lastName, email, password, confirmPassword]);

  return (
    <>
      <SurveyHeader />
      <Container maxW={signUpSpacing.MAX_WIDTH} mt="120px">
        <Box width="100%">
          <Heading size="heading1" textAlign="center">
            Set up your account
          </Heading>
          <Box display="flex" justifyContent="center">
            <Text size="bodyTextLarge">Already a member?</Text>
            <Text
              _hover={{
                textDecoration: 'underline',
              }}
              as={Link}
              color="primary.default"
              ml="2"
              size="bodyTextLarge"
              to={uiRoutes.login}
            >
              Login and continue
            </Text>
          </Box>
          {!!errorMessage && (
            <Alert mb={4} status="error">
              <AlertIcon />
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          )}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack mt={6} spacing={2}>
                <Grid
                  gap={formSimpleGridGap}
                  templateColumns={{
                    base: '1fr',
                    md: '2fr 1.7fr 2fr',
                  }}
                >
                  <GridItem>
                    <FormTextField
                      label="Legal First Name"
                      name="firstName"
                      showHelperText
                    />
                  </GridItem>
                  <GridItem>
                    <FormTextField
                      label="Legal Middle Name"
                      name="middleName"
                      showHelperText
                    />
                  </GridItem>
                  <GridItem>
                    <FormTextField
                      label="Legal Last Name"
                      name="lastName"
                      showHelperText
                    />
                  </GridItem>
                </Grid>

                <FormTextField
                  label="Email Address"
                  name="email"
                  showHelperText
                />
                <FormPasswordField
                  label="Password"
                  name="password"
                  showHelperText
                  type="password"
                />
                <Stack pt={2} spacing={0}>
                  <Text color="#848484" size="bodyTextSmall">
                    • Password must be at least 8 characters long
                  </Text>
                  <Text color="#848484" size="bodyTextSmall">
                    • Password must have at least 1 digit or 1 special character
                  </Text>
                </Stack>

                <FormPasswordField
                  label="Confirm Password"
                  name="confirmPassword"
                  showHelperText
                  type="password"
                />
                <Checkbox
                  onChange={(e: any) => {
                    const { checked } = e.target;
                    setCheck(checked);
                  }}
                  pt={2}
                  size="lg"
                >
                  <Text size="bodyTextSmall" w={{ base: '80%', md: '100%' }}>
                    I agree to the
                    <Text
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      as={Link}
                      color="primary.default"
                      target="_blank"
                      to={uiRoutes.termsOfService}
                    >
                      {' '}
                      terms{' '}
                    </Text>
                    and
                    <Text
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      as={Link}
                      color="primary.default"
                      target="_blank"
                      to={uiRoutes.privacyPolicy}
                    >
                      {' '}
                      privacy policy{' '}
                    </Text>
                    and consent to
                    <Text
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      as={Link}
                      color="primary.default"
                      target="_blank"
                      to="#"
                    >
                      {' '}
                      telehealth{' '}
                    </Text>
                  </Text>
                </Checkbox>
                <Stack pt={4} spacing={1}>
                  <Button
                    isDisabled={!check}
                    isLoading={isSubmitting}
                    size="lg"
                    type="submit"
                    variant="primaryLg"
                    width="100%"
                  >
                    Signup & Continue
                  </Button>
                  <Button
                    as={Link}
                    isDisabled={isSubmitting}
                    leftIcon={<ChevronLeft />}
                    to={uiRoutes.preSignup}
                    variant="primaryLinkLg"
                    width="100%"
                  >
                    Go Back
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};

export default Signup;
