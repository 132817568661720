import apiRoutes from 'constants/apiRoutes';
import {
  IForgotPasswordResponse,
  IForgotPasswordSchema,
  ILoginForm,
  ILoginResponse,
  IResetPasswordSchema,
} from 'interfaces/auth';
import { IResponse } from 'interfaces/http';
import { IChangePasswordForm } from 'interfaces/patient';
import { baseRequest } from 'utils/axios';

const { auth } = apiRoutes;

export const changePassword = async (
  data: IChangePasswordForm
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.changePassword,
    data,
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};

export const loginPatient = async (
  data: ILoginForm
): Promise<IResponse<ILoginResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.login,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const forgetPassword = async (
  queryParams: IForgotPasswordSchema
): Promise<IResponse<IForgotPasswordResponse>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: auth.forgetPassword,
    params: queryParams,
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const resetPassword = async (data: IResetPasswordSchema) => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.resetPassword,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
