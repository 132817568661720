import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import config from 'config';

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format={config.phone}
        getInputRef={ref}
        isNumericString
        mask="_"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        placeholder={config.phone}
      />
    );
  }
);

type Props = {
  name: string;
  label: string;
  showHelperText?: boolean;
  required?: boolean;
} & InputProps;

const FormMaskedPhoneInput = ({
  name,
  label,
  showHelperText,
  required,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl isInvalid={!!errors[name]}>
          <FormLabel>{required ? `${label}*` : label}</FormLabel>
          <Input as={CustomNumberFormat} {...others} {...field} />
          {!!errors[name] && showHelperText && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};

FormMaskedPhoneInput.defaultProps = {
  showHelperText: false,
  required: false,
};

export default FormMaskedPhoneInput;
