import { useCallback, useEffect, useRef, useState } from 'react';

import { useToast } from '@chakra-ui/react';
import { IComposeError, IResponse } from 'interfaces/http';

interface IProps<S> {
  isEnabled?: boolean;
  api: () => Promise<IResponse<S>>;
}

function useAPIService<S>({ api, isEnabled = true }: IProps<S>) {
  const initialMount = useRef(true);
  const toast = useToast();
  const [data, setData] = useState<IResponse<S> | null>(null);
  const [error, setError] = useState<IComposeError | null>(null);
  const [loading, setLoading] = useState(!!isEnabled);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api();
      setData(res);
    } catch (err: any) {
      setError(err as IComposeError);
      toast({
        title: err?.message || err?.response?.statusText || 'Network error',
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [api, toast]);

  useEffect(() => {
    if (initialMount.current && isEnabled) {
      fetchData();
    }
    return () => {
      initialMount.current = false;
    };
  }, [fetchData, isEnabled]);

  return { data, error, loading };
}

export default useAPIService;
