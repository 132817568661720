import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { bodyText, sectionTitleSmall, spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IHowItWorks } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { CONTAINER_X_PADDING, MAX_WIDTH } = spacing;

interface Props {
  data: IHowItWorks;
}

const HowItWorks = (props: Props) => {
  const { data } = props;

  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;

  const renderedHowItWorksGrid = (
    <Box
      backgroundColor="#001432"
      display="flex"
      justifyContent="center"
      marginX="auto"
      mt={{ base: '40px', md: '-500px', xl: '-600px' }}
      paddingX={CONTAINER_X_PADDING}
      width="100%"
    >
      <Box
        pt={{
          base: '80px',
          md: '80px',
          lg: '80px',
        }}
        px="8"
        py="12"
        width={MAX_WIDTH}
      >
        <Heading
          sx={{
            ...sectionTitleSmall,
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '32px',
            color: 'white',
            paddingBottom: '80px',
          }}
        >
          {data?.title}
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {data?.how_it_works_repeater?.map((item) => (
            <Stack alignItems="center" key={item.text} spacing="6">
              <Image
                alt="value"
                objectFit="cover"
                src={item?.image?.url}
                width={{ base: '200px', md: '300px' }}
              />
              <Text
                sx={{ ...bodyText, fontWeight: 'medium', color: 'white' }}
                textAlign="center"
                width="80%"
              >
                {item?.text}
              </Text>
            </Stack>
          ))}
        </SimpleGrid>
        <Flex justifyContent="center" mt={{ base: '10', xl: '16' }}>
          <RouterLink
            to={
              isLoggedIn
                ? uiRoutes.patientDashboard.profile
                : uiRoutes.serviceAvailability
            }
          >
            <Button variant="primaryLg">
              <Text as="span" mr="3">
                Get Started
              </Text>
            </Button>
          </RouterLink>
        </Flex>
      </Box>
    </Box>
  );

  return (
    <Box>
      {/* <Box>
        <Box display={{ base: 'block', md: 'none' }}>
          {renderedHowItWorksGrid}
        </Box>
        <Box display={{ base: 'none', md: 'block' }} h="200px" />
      </Box> */}
      <Box display={{ base: 'none', md: 'block' }} h="200px" />
      <Box
        backgroundColor="#eeefff"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display={{ base: 'none', md: 'block' }}
        height={{ base: '400px', md: '350px', lg: '450px' }}
      />
      <Box>{renderedHowItWorksGrid}</Box>
    </Box>
  );
};

export default HowItWorks;
