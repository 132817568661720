import { Box, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { IAppListing } from 'interfaces/cosmic';

interface IProps {
  data: IAppListing;
}

const AppsListPage = (props: IProps) => {
  const { data } = props;

  return (
    <Box>
      <Box
        alignItems="center"
        backgroundColor="#eeefff"
        display="flex"
        flexDirection="column"
        px={{ base: 3.5, md: 0 }}
        py={{ base: '60px', md: '120px' }}
      >
        <Heading
          fontSize={{ base: '22px', md: '32px', lg: '32px' }}
          textAlign="center"
        >
          Tools can make a big difference in reaching your goals
        </Heading>
        <Text
          fontSize={{ base: '14px', md: '14px', lg: '16px' }}
          pt="12px"
          textAlign="center"
        >
          Apps, when used consistently can be a great tool to use in pursuing
          recovery and improving your life and health.
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} mt="32px" spacing={10}>
          {data?.app_listing_repeater.map((item) => (
            <a
              href={item?.link}
              key={item.title1}
              rel="noreferrer"
              target="_blank"
            >
              <Box
                alignItems="center"
                backgroundColor="white"
                display="flex"
                flexDirection="column"
                h="250px"
                justifyContent="center"
                w="274px"
              >
                <Box display="flex" justifyContent="center" w="100%">
                  <Image alt="value" objectFit="cover" src={item?.image?.url} />
                </Box>
                <Text
                  fontSize="14px"
                  pt="32px"
                  size="bodyTextLargeSemibold"
                  textAlign="center"
                >
                  {item.title1}
                </Text>
                <Text
                  fontSize="14px"
                  size="bodyTextLargeSemibold"
                  textAlign="center"
                >
                  {item?.title2}
                </Text>
              </Box>
            </a>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default AppsListPage;
