import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Alert as ErrorAlert,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as authAPI from 'api/auth';
import { FormTextField } from 'common';
import Alert from 'common/Alert';
import FormPasswordField from 'common/form/FormPasswordField';
import uiRoutes from 'constants/uiRoutes';
import { AlertStatusType } from 'interfaces/alert';
import { ILoginForm } from 'interfaces/auth';
import { IError } from 'interfaces/http';
import SurveyHeader from 'layouts/SurveyHeader';
import { LoginSchema } from 'schemas/auth';
import { useAppDispatch } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import { getCurrentYear } from 'utils/date';

import ForgetPasswordModal from './ForgetPasswordModal';

const defaultValues = {
  email: '',
  password: '',
  rememberMe: false,
};

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordParam = searchParams.get('resetPassword');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });

  const clearAlertMessage = () => {
    setAlertMessage({
      message: '',
      status: 'error',
    });
    navigate(uiRoutes.login);
  };

  const dispatch = useAppDispatch();

  const methods = useForm<ILoginForm>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { watch } = methods;

  const onSubmit = async (data: ILoginForm) => {
    setIsSubmitting(true);
    try {
      const response = await authAPI.loginPatient(data);
      if (response.data?.token) {
        setAuthState({
          token: response.data?.token,
          dispatch,
        });
      }
      navigate(uiRoutes.patientDashboard.profile);
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { email, password } = watch();

  // Reset error message whenever any of the input goes empty
  useEffect(() => {
    if (email === '' || password === '') {
      setErrorMessage('');
    }
  }, [email, password]);

  useEffect(() => {
    if (resetPasswordParam === 'success') {
      setAlertMessage({
        message: 'Password updated successfully. Please login to continue!',
        status: 'success',
      });
    }
  }, [resetPasswordParam]);

  return (
    <>
      <SurveyHeader />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box maxW="589px" mb={6}>
          <Alert
            onClose={clearAlertMessage}
            status={alertMessage.status}
            text={alertMessage.message}
          />
        </Box>

        <Box
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow="2xl"
          maxW="589px"
          p={6}
          pos="relative"
          role="group"
          rounded="lg"
          w="full"
          zIndex={1}
        >
          <Box>
            <Heading
              display="flex"
              fontSize={{ base: '22px', sm: '24px', md: '30px' }}
              justifyContent="center"
              size="heading1"
            >
              Log in to your account
            </Heading>
            <Box display="flex" justifyContent="center" mb={8}>
              <Text size="bodyTextLarge">Don&apos;t have an account?</Text>
              <Text
                _hover={{
                  textDecoration: 'underline',
                }}
                as={Link}
                color="primary.default"
                ml="2"
                size="bodyTextLarge"
                to={uiRoutes.serviceAvailability}
              >
                Signup
              </Text>
            </Box>

            {!!errorMessage && (
              <ErrorAlert mb={4} status="error">
                <AlertIcon />
                <AlertTitle>{errorMessage}</AlertTitle>
              </ErrorAlert>
            )}

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormTextField
                    label="Email Address"
                    name="email"
                    showHelperText
                  />
                  <FormPasswordField
                    label="Password"
                    name="password"
                    showHelperText
                    type="password"
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Controller
                      control={methods.control}
                      name="rememberMe"
                      render={({ field }) => (
                        <Checkbox
                          isChecked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          size="lg"
                        >
                          <Text ml={2} size="bodyTextSmall">
                            Remember Me
                          </Text>
                        </Checkbox>
                      )}
                    />
                    <Text
                      _hover={{
                        textDecoration: 'underline',
                      }}
                      color="primary.default"
                      cursor="pointer"
                      ml="2"
                      onClick={onOpen}
                      size="bodyTextSmall"
                    >
                      Forgot Password?
                    </Text>
                  </Box>
                  <Stack spacing={2}>
                    <Button
                      isLoading={isSubmitting}
                      mb={8}
                      mt={4}
                      size="lg"
                      type="submit"
                      variant="primaryLg"
                      width="100%"
                    >
                      Log In
                    </Button>
                    <Text
                      align="center"
                      color="primary.default"
                      size="bodyTextSmall"
                    >
                      Copyright © {getCurrentYear()}- MAT-Rx LLC
                    </Text>
                    <Box display="flex" justifyContent="center">
                      <Text
                        _hover={{
                          textDecoration: 'underline',
                        }}
                        align="center"
                        as={Link}
                        color="primary.default"
                        size="bodyTextSmall"
                        target="_blank"
                        to={uiRoutes.termsOfService}
                      >
                        Terms of Use
                      </Text>
                      <Text size="bodyTextSmall">|</Text>
                      <Text
                        _hover={{
                          textDecoration: 'underline',
                        }}
                        align="center"
                        as={Link}
                        color="primary.default"
                        size="bodyTextSmall"
                        target="_blank"
                        to={uiRoutes.privacyPolicy}
                      >
                        Privacy Policy
                      </Text>
                    </Box>
                  </Stack>
                </Stack>
              </form>
            </FormProvider>
            <ForgetPasswordModal isOpen={isOpen} onClose={onClose} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
