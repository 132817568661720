import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Heading, SimpleGrid, Stack, useMediaQuery } from '@chakra-ui/react';
import {
  FormGoogleAutocompleteInput,
  FormMaskedZipInput,
  FormTextField,
} from 'common';
import { formSimpleGridGap } from 'constants/styles';
import { IAddress } from 'interfaces/common';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import breakpoints from 'styles/customTheme/breakpoints';
import { checkIfObjHasAllEmptyOrNullValues } from 'utils/common';

const BillingAddress = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const patient = useAppSelector(selectPatient);
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  // Populate billing zip code if not populated alreaddy
  useEffect(() => {
    if (!patient?.zipCode) {
      return;
    }

    const { billingZipCode } = getValues();

    setValue('billingZipCode', billingZipCode || patient.zipCode);
  }, [getValues, patient?.zipCode, setValue]);

  const onPopulateFields = (addr: IAddress) => {
    if (checkIfObjHasAllEmptyOrNullValues(addr)) return;

    setValue('billingAddressLine1', addr.addressLine1?.trim());
    setValue('billingAddressLine2', addr.addressLine2?.trim());
    setValue('billingCity', addr.city);
    setValue('billingState', addr.state);
    setValue('billingZipCode', addr.zipCode);
  };

  const displayErrorMessage = (error: any) => {
    if (!isMobile) {
      if (error?.billingCity?.message) {
        return error?.billingCity?.message;
      }
      if (error?.billingState?.message) {
        return error?.billingState?.message;
      }
    }
    if (error?.billingZipCode?.message) {
      return error?.billingZipCode?.message;
    }
    return '';
  };

  return (
    <Stack spacing={5}>
      <Heading size="heading2">Billing Address</Heading>
      <Stack spacing={formSimpleGridGap}>
        <SimpleGrid columns={{ base: 1 }} gap={formSimpleGridGap}>
          <FormGoogleAutocompleteInput
            name="billingAddressLine1"
            onPopulateFields={onPopulateFields}
          />
          <FormTextField label="Address Line 2" name="billingAddressLine2" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={formSimpleGridGap}>
          <FormTextField label="City" name="billingCity" />
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={formSimpleGridGap}>
            <FormTextField label="State" name="billingState" />
            <FormMaskedZipInput name="billingZipCode" />
          </SimpleGrid>
        </SimpleGrid>
        <p style={{ margin: 0, marginTop: 8, color: 'red' }}>
          {displayErrorMessage(errors)}
        </p>
      </Stack>
    </Stack>
  );
};

export default BillingAddress;
