import { Box, Container, Heading, Progress, Text } from '@chakra-ui/react';

interface IProps {
  totalQuestions: number;
  currentQuestion: number;
}

const QuestionIndicator = ({ totalQuestions, currentQuestion }: IProps) => (
  <Container mb={8} p={0} w="100%">
    <Heading size="heading1" textAlign="center">
      Online Assessment
    </Heading>
    <Box
      as={Progress}
      borderRadius="8px"
      colorScheme="blue"
      height="5px"
      mt={6}
      value={(currentQuestion / totalQuestions) * 100}
      width="100%"
    />

    <Text fontWeight="600" mt={4} size="bodyTextLarge" textAlign="end">
      {currentQuestion}/{totalQuestions} Questions
    </Text>
  </Container>
);

export default QuestionIndicator;
