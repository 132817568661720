/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFooter } from 'interfaces/cosmic';
import { RootState } from 'stores';

interface IState {
  footer: IFooter | null;
}

const initialState: IState = {
  footer: null,
};

export const slice = createSlice({
  name: 'cmsContent',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setFooterContent: (state, action: PayloadAction<IFooter>) => {
      state.footer = { ...action.payload };
    },
  },
});

// Actions
export const { setFooterContent } = slice.actions;

// Selectors
export const selectFooterContent = (state: RootState) =>
  state.cmsContent.footer;

// Reducer
export default slice.reducer;
