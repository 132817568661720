import { useEffect, useState } from 'react';
import { Lock } from 'react-feather';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as authAPI from 'api/auth';
import { FormTextField } from 'common';
import { IForgotPasswordForm, IForgotPasswordSchema } from 'interfaces/auth';
import { IError } from 'interfaces/http';
import { ForgotPasswordEmailSchema } from 'schemas/auth';
import { useAppSelector } from 'stores/hooks';
import { selectSource } from 'stores/online-assessment';
import { setLocal } from 'utils/storage';

import PasswordRecoveryConfirmPage from './PasswordRecoveryConfirmPage';

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const defaultValues = {
  email: '',
};

const ForgetPasswordModal = ({ isOpen, onClose }: IProps) => {
  const [isForgotSubmitting, setIsForgotSubmitting] = useState(false);
  const [forgotErrorMessage, setForgotErrorMessage] = useState('');
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(true);

  const methods = useForm<IForgotPasswordForm>({
    defaultValues,
    resolver: yupResolver(ForgotPasswordEmailSchema),
  });
  const { watch } = methods;
  const source = useAppSelector(selectSource);

  const onForgotPasswordSubmit = async (data: IForgotPasswordForm) => {
    setIsForgotSubmitting(true);
    try {
      const payload: IForgotPasswordSchema = {
        email: data.email,
        source,
      };

      // API for forgotPassword
      const response = await authAPI.forgetPassword(payload);
      const resetToken = response?.data?.resetToken;
      setLocal('resetToken', resetToken);
      setShowForgotPasswordForm(false);
    } catch (err) {
      setForgotErrorMessage((err as IError)?.message);
    } finally {
      setIsForgotSubmitting(false);
    }
  };

  const { email } = watch();

  useEffect(() => {
    if (email === '') {
      setForgotErrorMessage('');
    }
  }, [email]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      {/* Todo: Make ModalComponent reusable */}
      <ModalContent sx={{ maxWidth: 'auto' }} w="584px">
        <ModalCloseButton
          onClick={() => {
            setForgotErrorMessage('');
          }}
        />
        <ModalBody>
          {showForgotPasswordForm ? (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                as={Lock}
                color="primary.default"
                height="64px"
                mt={4}
                width="50px"
              />
              <Heading mb={2} mt={4} size="heading1">
                Forgot Password?
              </Heading>
              {!!forgotErrorMessage && (
                <Alert mb={4} status="error">
                  <AlertIcon />
                  <AlertTitle>{forgotErrorMessage}</AlertTitle>
                </Alert>
              )}
              <Text mb={6} mt={2} size="bodyTextLarge">
                Please enter your email address to reset your password
              </Text>
              <Box w="100%">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onForgotPasswordSubmit)}>
                    <FormTextField
                      label="Email Address"
                      name="email"
                      showHelperText
                    />
                    <Button
                      isLoading={isForgotSubmitting}
                      mb={12}
                      mt={6}
                      type="submit"
                      variant="primaryLg"
                      width="100%"
                    >
                      Send Email
                    </Button>
                  </form>
                </FormProvider>
              </Box>
            </Box>
          ) : (
            <PasswordRecoveryConfirmPage />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgetPasswordModal;
