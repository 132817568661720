import { Box, Button, HStack, Image, Stack, Text } from '@chakra-ui/react';
import drugFallbackImage from 'assets/images/png/drug-fallback.png';
import config from 'config';
import { IOrder, ISubscription } from 'interfaces/order';
import { addNDaysToDate } from 'utils/date';

interface IProps {
  order: IOrder;
  subscription: ISubscription;
}

const OrderCard = ({ order, subscription }: IProps) => {
  const drugOfferingData = order.orderDetails?.[0]?.drugOffering || null;
  const drugData = order.orderDetails?.[0]?.drug || null;

  const refillDate = addNDaysToDate(
    subscription.startDate,
    subscription.recurringDays,
    {
      inputFormat: 'YYYY-MM-DD',
      outputFormat: config.dateViewFormat,
    }
  );

  return (
    <Box bg="primary.lighter" p={3}>
      <HStack
        alignItems="flex-start"
        display="flex"
        justifyContent="flex-start"
        spacing={4}
      >
        <Image
          alt={drugData?.genericName}
          bg="background.default"
          border="1px solid"
          borderColor="primary.light"
          boxSize="125px"
          fallbackSrc={drugFallbackImage}
          objectFit="cover"
          src={drugData?.imageUrl}
        />

        <Stack noOfLines={4}>
          <Text size="bodyTextLargeSemibold" title={drugData?.brandName}>
            {drugData?.brandName}
          </Text>
          <Text size="bodyTextSmallSemibold" title={drugOfferingData?.name}>
            {drugOfferingData?.name}
          </Text>
          <Text
            color="error.default"
            noOfLines={1}
            size="bodyTextSmallSemibold"
          >
            Prescription due for refill: {refillDate}
          </Text>
          <Button
            disabled
            variant="primarySm"
            width={{ base: '100%', md: '50%' }}
          >
            Cancel Order
          </Button>
        </Stack>
      </HStack>
    </Box>
  );
};

export default OrderCard;
