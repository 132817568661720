import config from 'config';
import moment from 'moment';

import { isSingleDigit } from './common';

/**
 * Get current year
 *
 * @returns {number}
 */
export function getCurrentYear(): number {
  return new Date().getFullYear();
}

/**
 * Validate if the given string is a valid date or not
 *
 * @param {String} date
 * @param {String} dateFormat Use valid date-fns format
 * @returns {Boolean} true if valid
 */
export function checkIfDateIsValid(
  date: string,
  format = config.dateViewFormat
): boolean {
  if (format === config.dateViewFormat && date?.length !== 8) {
    return false;
  }
  return moment(date, format).isValid();
}

/**
 * It takes a date of birth and returns the age
 * @param {string} dob - The date of birth in the format specified by dateFormat.
 * @param dateFormat - The format of the date you're passing in.
 */
export const getAge = (dob: string, dateFormat = config.dateViewFormat) =>
  moment().diff(moment(dob, dateFormat), 'years');

/**
 * It takes a date string and returns a formatted date string
 * @param {string} date - The date string to be formatted.
 * @param dateFormat - The format of the date you want to display.
 * @returns formatted date
 */
export const formatDate = (
  date: string,
  dateFormat = config.dateViewFormat
) => {
  if (date && !moment(date).isValid()) return date;

  if (date) {
    return moment(date).format(dateFormat);
  }

  return null;
};

export const getFullDateFormat = (date: string) =>
  moment(date).format('dddd DD MMMM YYYY');

/**
 * Format date
 * Date in payload should be transformed in 'MM/DD/YYYY' format
 *
 * Input: 01011995
 * Output: 01/01/1995
 *
 * @param {string} date
 * @returns
 */
export const formatDateForPayload = (date: string) => {
  if (!date) return '';
  return `${date.substring(0, 2)}/${date.substring(2, 4)}/${date.substring(
    4,
    date.length
  )}`;
};

/**
 * Unformat date to digit only string
 * Useful for setting default value in form
 *
 * Input: 01/01/1995
 * Output: 01011995
 *
 * @param date
 * @returns
 */
export const unformatDate = (date: string) => {
  if (!date) return '';
  return date.replaceAll('/', '');
};

/**
 * Add n days to the given date
 */
export const addNDaysToDate = (
  date: string,
  daysToAdd: number,
  { inputFormat = config.dateViewFormat, outputFormat = config.dateViewFormat }
) => {
  if (!date) return '';
  const newDate = moment(date, inputFormat).add(daysToAdd, 'days');
  return moment(newDate).format(outputFormat);
};

export const validateMinAge = (
  dob: string,
  minAge: number,
  dateFormat = config.dateViewFormat
) => {
  let isValidDob = false;

  if (minAge > 0 && moment().diff(moment(dob, dateFormat), 'years') >= minAge) {
    isValidDob = true;
  }

  return isValidDob;
};

export const validateMinAgeFromYear = (year: string, minAge: number) => {
  let isValidYear = false;
  const minYear = new Date().getFullYear() - minAge;

  if (minAge > 0 && +year <= minYear) {
    isValidYear = true;
  }
  return isValidYear;
};

export const validateMaxAge = (
  dob: string,
  maxAge: number,
  dateFormat = config.dateViewFormat
) => {
  let isValidDob = false;

  if (maxAge > 0 && moment().diff(moment(dob, dateFormat), 'years') < maxAge) {
    isValidDob = true;
  }
  return isValidDob;
};

export const getMonthDayYearFromDob = (dob: string | null) => {
  const array: string[] | undefined = dob?.split('/');

  let month = array?.splice(0, 1)[0];

  if (month?.charAt(0).includes('0')) {
    month = month.replace('0', '');
  }

  let day = array?.splice(0, 1)[0];
  if (day?.charAt(0).includes('0')) {
    day = day.replace('0', '');
  }

  const year = array?.splice(0, 1)[0];

  return { month, day, year };
};

export const getDobFromMonthDayYear = ({
  month,
  day,
  year,
}: {
  month: string;
  day: string;
  year: string;
}) => {
  let newMonth = month;
  let newDay = day;
  const isMonthSingleDigit = isSingleDigit(month);
  const isDaySingleDigit = isSingleDigit(day);

  if (isMonthSingleDigit) {
    newMonth = `0${month}`;
  }
  if (isDaySingleDigit) {
    newDay = `0${day}`;
  }

  const dob = `${newMonth}${newDay}${year}`;

  return { dob };
};
