import { Box } from '@chakra-ui/react';
import { POLICIES_IFRAME_SOURCE } from 'constants/policiesIFrameSource';

const Disclaimer = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    h="100vh"
    justifyContent="center"
    w="100%"
  >
    <iframe
      height="100%"
      src={POLICIES_IFRAME_SOURCE.disclaimer}
      title="MATRx | Disclaimer"
      width="100%"
    />
  </Box>
);

export default Disclaimer;
