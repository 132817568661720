import { useState } from 'react';

import { Button, Textarea } from '@chakra-ui/react';

interface IProps {
  followUpQuestion: string;
  onSubmitFollowUpAnswer: (answer: string) => void;
  isDisabled?: boolean;
}

const FollowUpQuestionaire = ({
  followUpQuestion,
  onSubmitFollowUpAnswer,
  isDisabled,
}: IProps) => {
  const [answer, setAnswer] = useState('');

  const onNextClick = () => {
    onSubmitFollowUpAnswer(answer);
  };

  return (
    <>
      <p>{followUpQuestion}</p>

      <Textarea
        mb={4}
        mt={4}
        onChange={(event) => setAnswer(event.target.value)}
        placeholder="Write your answer here, being as specific as you can."
        rows={8}
        value={answer}
      />

      <Button
        disabled={!answer?.trim() || isDisabled}
        onClick={onNextClick}
        size="lg"
        type="button"
        variant="primaryLg"
        width="100%"
      >
        Next
      </Button>
    </>
  );
};

FollowUpQuestionaire.defaultProps = {
  isDisabled: false,
};

export default FollowUpQuestionaire;
