import { useEffect, useState } from 'react';
// import { Camera } from 'react-feather';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  Avatar,
  Box,
  Button,
  Fade,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { editPatientDetail } from 'api/patient';
import { FormGenderInput, FormTextField } from 'common';
import Alert from 'common/Alert';
import DropdownMonthDayYear from 'common/DropdownMonthDayYear';
import FormMaskedPhoneInput from 'common/form/FormMaskedPhoneInput';
import LoadingIndicator from 'common/LoadingIndicator';
import { useCalendarDate } from 'hooks/useCalendarDate';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { IPatientProfileForm, IPatientProfileSchema } from 'interfaces/patient';
import { PatientProfileSchema } from 'schemas/patient';
import { selectPatient, setPatient } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getDaysInMonth } from 'utils/common';
import { getDobFromMonthDayYear, getMonthDayYearFromDob } from 'utils/date';
import { formatPatientProfilePayload } from 'utils/patient';

const defaultValues: IPatientProfileForm = {
  imageUrl: '',
  newImageData: '',
  gender: '',
  firstName: '',
  middleName: '',
  lastName: '',
  month: '',
  day: '',
  year: '',
  email: '',
  phone: '',
};

interface IProps {
  fallbackName: string;
}

// const { REQUIRED, INVALID_DATE, MAX_AGE, MIN_AGE } = validationMessage;

const ImagePicker = ({ fallbackName = '' }: IProps) => {
  const { watch, setValue } = useFormContext();
  const currentImageUrl = watch('imageUrl');
  const newImageData = watch('newImageData');

  const onImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length < 1) {
      return;
    }

    const preview = URL.createObjectURL(e.target.files[0]);
    const img = e.target.files[0];
    const imageData = {
      fileName: img.name,
      contentType: img.type,
      // base64: await convertFileToBase64(img),
      size: img.size,
      filePath: `image/agentimage/${(+new Date())?.toString()}_${img.name}`,
    };

    setValue('newImageData', { preview, imageData });
  };

  const getImageSrcToDisplay = () => {
    if (newImageData?.preview) {
      return newImageData.preview;
    }

    if (currentImageUrl && currentImageUrl.length) {
      return currentImageUrl;
    }

    return null;
  };

  return (
    <Box height="110px" position="relative" rounded="md" width="110px">
      <Avatar
        bg="teal.500"
        boxSize={{ base: '100%', md: '100%', lg: '100%' }}
        color="accentText.default"
        name={fallbackName ?? 'Rashil Ulak'}
        size="2xl"
        src={getImageSrcToDisplay()}
      />
      {/* <Box
        alignItems="center"
        backgroundColor="accentText.default"
        bottom="2px"
        display="flex"
        height={6}
        justifyContent="center"
        position="absolute"
        right="2px"
        rounded="2xl"
        width={6}
      >
        <Icon as={Camera} />
      </Box> */}
      <Input
        accept="image/*"
        aria-hidden="true"
        cursor="pointer"
        display="none" // Temporary
        height="100%"
        left="0"
        onChange={onImageUpload}
        opacity="0"
        position="absolute"
        top="0"
        type="file"
        width="100%"
      />
    </Box>
  );
};

const PatientProfileForm = () => {
  const dispatch = useAppDispatch();
  const patientData = useAppSelector(selectPatient);

  const methods = useForm<IPatientProfileForm>({
    defaultValues,
    resolver: yupResolver(PatientProfileSchema),
  });
  const { dateArray, setDynamicDays } = useCalendarDate();

  const {
    formState: { isDirty },
    watch,
    getValues,
  } = methods;

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });

  const {
    month,
    day,
    year,
    email,
    firstName,
    middleName,
    imageUrl,
    lastName,
    newImageData,
    phone,
    gender,
  } = watch();

  // TODO: Fix date picker

  // const dobWrapperRef = useRef<HTMLDivElement>();
  // const [dob, setDob] = useState<string | null>(null);
  // const [isDobDatepickerOpen, setIsDobDatepickerOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // const { loading, data } = useAPIService<IPatient>({
  //   api: useCallback(() => getPatientDetail(), []),
  // });

  const clearAlertMessage = () =>
    setAlertMessage({
      message: '',
      status: 'error',
    });

  useEffect(() => {
    function newDaysInMonth() {
      const getEnteredYear = getValues('year');
      const getEnteredMonth = getValues('month');
      const getDays = getDaysInMonth(getEnteredMonth, getEnteredYear);
      setDynamicDays(getDays.toString());
    }
    newDaysInMonth();
  }, [getValues, year, month, setDynamicDays]);

  useEffect(() => {
    if (
      month === '' ||
      day === '' ||
      year === '' ||
      email === '' ||
      firstName === '' ||
      middleName === '' ||
      imageUrl === '' ||
      lastName === '' ||
      newImageData === '' ||
      phone ||
      gender
    ) {
      clearAlertMessage();
    }
  }, [
    email,
    firstName,
    middleName,
    imageUrl,
    lastName,
    newImageData,
    phone,
    gender,
    month,
    day,
    year,
  ]);

  useEffect(() => {
    if (patientData) {
      const {
        month: newMonth,
        day: newDay,
        year: newYear,
      } = getMonthDayYearFromDob(patientData?.dob);

      methods.reset({
        firstName: patientData.firstName ?? '',
        middleName: patientData.middleName ?? '',
        lastName: patientData.lastName ?? '',
        month: newMonth === undefined ? '' : newMonth,
        day: newDay,
        year: newYear,
        email: patientData.email ?? '',
        phone: patientData.phone ?? '',
        gender: patientData.gender ?? '',
      });
    }
  }, [patientData, methods]);

  // FIXME: Date Picker issue

  // useEffect(() => {
  //   if (isDobDatepickerOpen) {
  //     const rhfDOBValue = getValues('dob') || null;
  //     if (!rhfDOBValue) {
  //       setDob(null);
  //       return;
  //     }
  //     if (!checkIfDateIsValid(rhfDOBValue)) {
  //       setDob(null);
  //       return;
  //     }
  //     setDob(new Date(rhfDOBValue));
  //   }
  // }, [isDobDatepickerOpen, getValues]);

  // const onDobChange = (date: string) => {
  //   setDob(date);
  //   setValue('dob', formatDate({ date }) ?? '');
  // };

  const onSubmit = async (data: IPatientProfileForm) => {
    if (!patientData) return;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { imageUrl, newImageData, ...rest } = data;
    const { dob } = getDobFromMonthDayYear({
      month: data?.month,
      day: data?.day,
      year: data?.year,
    });

    const rawPayload: IPatientProfileSchema = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      dob,
      gender: data.gender,
    };
    try {
      clearAlertMessage();
      setIsSubmitting(true);
      const payload = formatPatientProfilePayload(rawPayload);

      const res = await editPatientDetail({ data: payload });
      dispatch(
        setPatient({
          ...patientData,
          ...rest,
          dob,
        })
      );
      setAlertMessage({
        message: res?.message,
        status: 'success',
      });
    } catch (error) {
      setAlertMessage({
        message: (error as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fade in unmountOnExit>
      <Heading as="h2" size="heading2">
        Personal Information
      </Heading>
      {!patientData ? (
        <LoadingIndicator containerHeight="30rem" />
      ) : (
        <Fade in unmountOnExit>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Alert
                autoClose
                mb={2}
                mt={5}
                onClose={clearAlertMessage}
                status={alertMessage.status}
                text={alertMessage.message}
              />
              {/* <Alert
                autoClose
                mt={6}
                onClose={clearAlertMessage}
                status={alertMessage.status}
                text={alertMessage.message}
              /> */}

              <Stack spacing={3} width="100%">
                <Box my={5}>
                  <ImagePicker
                    fallbackName={`${patientData.firstName} ${patientData.lastName}`}
                  />
                </Box>
                <FormGenderInput />

                <FormTextField
                  label="Legal First Name"
                  name="firstName"
                  showHelperText
                />
                <FormTextField
                  label="Legal Middle Name"
                  name="middleName"
                  showHelperText
                />
                <FormTextField
                  label="Legal Last Name"
                  name="lastName"
                  showHelperText
                />
                {/* TODO: Fix date picker issue */}
                {/* <Box>
                  <Box
                    height={0}
                    key={dob ? dob.toString() : ''}
                    ref={dobWrapperRef as RefObject<HTMLDivElement>}
                  >
                    <DatePicker
                      isOpen={isDobDatepickerOpen}
                      onChange={onDobChange}
                      value={dob}
                    />
                  </Box>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <NumberFormat
                        customInput={FormTextField}
                        // errorMsg={errors?.dob?.message}
                        format="##/##/#####"
                        hasDatePicker
                        id="date-of-birth"
                        isInvalid={!!errors?.dob}
                        label="Date of Birth"
                        mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                        onCalendarClick={() =>
                          setIsDobDatepickerOpen((prevState) => !prevState)
                        }
                        placeholder="DD/MM/YYYY"
                        style={{ backgroundColor: 'white' }}
                        {...field}
                      />
                    )}
                    rules={{
                      required: REQUIRED,
                      validate: (value) => {
                        if (!checkIfDateIsValid(value)) return INVALID_DATE;
                        const age = getAge(value);
                        if (age < 18) return MIN_AGE;
                        if (age > 100) return MAX_AGE;
                        return true;
                      },
                    }}
                  />
                </Box> */}

                <FormLabel>Birth Date</FormLabel>
                <Stack direction="row">
                  {dateArray.map((item: any) => (
                    <DropdownMonthDayYear
                      key={item.id}
                      name={item.name}
                      placeholder={item.placeholder}
                      showHelperText
                      timeArray={item.rawTimeArray}
                    />
                  ))}
                </Stack>

                <FormTextField label="Email" name="email" showHelperText />

                <FormMaskedPhoneInput
                  label="Phone Number"
                  name="phone"
                  placeholder="Enter Your Phone Number"
                  showHelperText
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    disabled={!isDirty || isSubmitting}
                    isLoading={isSubmitting}
                    mt={{ base: '1.5rem', xl: '2rem', '3xl': '2.5rem' }}
                    type="submit"
                    variant="primaryLg"
                    width={{ base: '100%', md: 'auto' }}
                  >
                    Update
                  </Button>
                </Box>
              </Stack>
            </form>
          </FormProvider>
        </Fade>
      )}
    </Fade>
  );
};

export default PatientProfileForm;
