import { extendedColors } from '../colors';
import { fontWeights } from '../fonts';

const { text } = extendedColors;

const Text = {
  sizes: {
    bodyTextLarge: {
      fontSize: '16px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.normal,
    },

    bodyTextLargeSemibold: {
      fontSize: '16px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.semibold,
    },

    bodyTextMedium: {
      fontSize: '14px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.normal,
    },
    bodyTextMediumSemibold: {
      fontSize: '14px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.semibold,
    },
    bodyTextSmall: {
      fontSize: '12px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.normal,
    },

    bodyTextSmallSemibold: {
      fontSize: '12px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.semibold,
    },

    // Depreciate this (not included in the style guide)
    md: {
      color: 'text.faded',
    },
  },
};

export default Text;
