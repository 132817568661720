import { useEffect, useState } from 'react';

import { Badge, Box, Switch, Text } from '@chakra-ui/react';
import { deletePatientCard, updatePatientCardState } from 'api/patient';
import { ConfirmDeleteDialog } from 'common/ConfirmDeleteDialog';
import { PaymentType } from 'enum/PaymentStatus';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { IPatientPaymentDetail } from 'interfaces/patient';
import { extendedColors } from 'styles/customTheme/colors';
import { getCardExpiryText } from 'utils/payment';

interface IProps {
  data: IPatientPaymentDetail;
  patientName: string;
  paymentType: string;
  merchantCardId: string;
  setToggleFetchPaymentDetails: (value: React.SetStateAction<boolean>) => void;
  setCardAddDeleteAlertMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      status: AlertStatusType;
    }>
  >;
}

const PaymentDetail = ({
  data,
  patientName,
  paymentType,
  merchantCardId,
  setToggleFetchPaymentDetails,
  setCardAddDeleteAlertMessage,
}: IProps) => {
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (paymentType === PaymentType.PRIMARY) {
      setIsPrimary(true);
    } else {
      setIsPrimary(false);
    }
  }, [paymentType]);

  const deleteCard = async () => {
    try {
      setIsSubmitting(true);
      const response = await deletePatientCard(merchantCardId);

      setToggleFetchPaymentDetails((prev) => !prev);
      setCardAddDeleteAlertMessage({
        message: response?.message,
        status: 'success',
      });
    } catch (error) {
      setCardAddDeleteAlertMessage({
        message: (error as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCardState = async () => {
    try {
      setIsSubmitting(true);
      const response = await updatePatientCardState(merchantCardId);
      setToggleFetchPaymentDetails((prev) => !prev);
      setCardAddDeleteAlertMessage({
        message: response?.message,
        status: 'success',
      });
    } catch (error) {
      setCardAddDeleteAlertMessage({
        message: (error as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '4px',
        padding: 4,
        background: 'neutral.lighter',
        display: 'flex',
      }}
    >
      <Box pr={4}>
        <Badge colorScheme="blue">{data.brand}</Badge>
      </Box>
      <Box w="100%">
        <Box display="flex" justifyContent="space-between" w="100%">
          <Text mb={1} size="bodyTextLargeSemibold">
            {patientName}
          </Text>
          <Box display="flex">
            <Text
              color={extendedColors.primary.default}
              mb={2}
              mr={2}
              size="bodyTextSmallSemibold"
            >
              {!isPrimary ? 'Make Primary' : 'Primary'}
            </Text>
            <Switch
              disabled={isPrimary || isSubmitting}
              isChecked={isPrimary}
              onChange={() => {
                setIsPrimary((prev) => !prev);
                updateCardState();
              }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" w="100%">
          <Text mb={4} size="bodyTextLargeSemibold">
            **** **** **** {data.last4Digit}
          </Text>
        </Box>
        <Box display="flex" justifyContent="space-between" w="100%">
          <Text color="text.faded" mb={1} size="bodyTextSmallSemibold">
            Expires on {getCardExpiryText(data.expiryMonth, data.expiryYear)}
          </Text>
          <ConfirmDeleteDialog
            deleteCard={deleteCard}
            isPrimary={isPrimary}
            isSubmitting={isSubmitting}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetail;
