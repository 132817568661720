import { Heading, Image, Text, VStack } from '@chakra-ui/react';
import { IDrug } from 'interfaces/drugOffering';

interface IProps {
  data: IDrug;
}

const DrugInfo = ({ data }: IProps) => (
  <VStack spacing={8}>
    <Image
      alt={data.brandName}
      bg="background.default"
      border="1px solid"
      borderColor="primary.light"
      boxSize="240px"
      objectFit="cover"
      src={data.imageUrl}
    />
    <VStack spacing={3}>
      <Heading size="heading1">{data.brandName}</Heading>
      <Text size="bodyTextMedium">{data.description}</Text>
    </VStack>
  </VStack>
);

export default DrugInfo;
