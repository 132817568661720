import { Menu } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Menu as ChakraMenu,
  Flex,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import LogoWithTagline from 'common/LogoWithTagline';
import { spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import PatientPortalDrawer from './PatientPortalDrawer';

const { CONTAINER_X_PADDING, MAX_WIDTH } = spacing;

const ProfileHeader = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const patientData = useAppSelector(selectPatient);

  return (
    <Box
      as="header"
      bg="white"
      boxShadow="md"
      position="fixed"
      top="0"
      width="full"
      zIndex="10"
    >
      <Flex
        align="center"
        height="5rem"
        justifyContent="space-between"
        marginX="auto"
        maxWidth={MAX_WIDTH}
        paddingX={CONTAINER_X_PADDING}
        sx={{
          a: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <LogoWithTagline />
        <Box
          alignItems="center"
          display="flex"
          gap={6}
          justifyContent="center"
          mr={2}
        >
          <Box display={{ base: 'none', md: 'block' }}>
            <ChakraMenu>
              <MenuButton as={Box} cursor="pointer">
                <Avatar
                  bg="teal.500"
                  color="white.default"
                  name={`${patientData?.firstName} ${patientData?.lastName}`}
                />
              </MenuButton>
              <MenuList>
                <MenuItem
                  as={RouterLink}
                  to={uiRoutes.patientDashboard.profile}
                >
                  My Profile
                </MenuItem>
                <MenuItem as={RouterLink} to={uiRoutes.logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </ChakraMenu>
          </Box>

          <Box
            alignItems="center"
            as={Menu}
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
          />
        </Box>
      </Flex>
      <PatientPortalDrawer isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
export default ProfileHeader;
