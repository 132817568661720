const apiRoutes = {
  auth: {
    login: '/patients/auth/login',
    forgetPassword: '/patients/auth/forget-password',
    changePassword: '/patients/auth/change-password',
    resetPassword: '/patients/auth/reset-password',
  },
  patient: {
    patients: '/patients',
    editDetail: '/patients',
    editPatientBillingshipping: '/patients/addresses',
    messages: {
      changeMessageStatus: '/messages/change-status',
      getAll: '/messages',
      getUnreadMessageCount: '/messages/unread-count',
      sendMessage: '/messages',
    },
    notificationSettings: {
      update: '/patients',
    },
    signup: '/patients/register',
    getDetail: '/patients/self',
    saveEssentials: '/patients/save-essential',
    surveys: '/patients/surveys',
    highRisk: '/patients/high-risk',
    checkout: '/patients/confirm-order',
    orders: {
      getAll: '/patients/orders',
    },
    paymentDetails: {
      getAll: '/patients/cards',
      addNewCard: 'patients/cards',
      updatePrimary: '/patients/cards/:merchantCardId/set-primary',
      deleteCard: 'patients/cards/:merchantCardId',
    },
    patientDocuments: {
      uploadDocument: '/patients/documents',
      getAllUploadedDocuments: '/patients/documents',
      deleteDocument: '/patients/documents/:id',
      shareDocumentToAdmin: '/patients/documents/share',
      downloadDocument: '/patients/documents/:id/download',
    },
  },
  otp: {
    generate: '/otps/generate',
    verify: '/otps/verify',
  },
  drugOfferings: {
    get: '/drug-offerings',
  },
  merchant: {
    getMerchantKey: '/merchants/sources/:source',
  },
  endpoints: {
    activityLogs: {
      getAll: '/activity-logs',
      save: '/activity-logs',
    },
  },
};

export default apiRoutes;
