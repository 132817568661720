import { extendTheme } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import colors from './colors';
import Button from './components/button';
import FormLabel from './components/formLabel';
import Heading from './components/heading';
import Input from './components/input';
import Text from './components/text';
import Textarea from './components/textarea';
import { fonts, fontWeights } from './fonts';

const customTheme = extendTheme({
  breakpoints,
  fonts,
  fontWeights,
  colors,
  components: {
    Button,
    Heading,
    Text,
    Input,
    FormLabel,
    Textarea,
  },
});

export default customTheme;
