import { Button, ButtonProps, useMediaQuery } from '@chakra-ui/react';
import breakpoints from 'styles/customTheme/breakpoints';
import { extendedColors } from 'styles/customTheme/colors';

type IProps = {
  text: string;
  isActive?: boolean;
} & ButtonProps;

const buttonStyle = {
  display: 'block',
  borderRadius: '40px',
  width: '100%',
  paddingTop: '14px',
  paddingBottom: '14px',
  height: 'auto',
  fontSize: '16px',
  textAlign: 'left',
  color: extendedColors.text.default,
  backgroundColor: extendedColors.white.default,
  boxShadow: 'none',
  border: '1px solid #B3B3B3',
  textTransform: 'none',
  whiteSpace: 'normal',
};

const buttonStyleHoveredMobile = {
  color: `${extendedColors.text.default} !important`,
  backgroundColor: `${extendedColors.white.default} !important`,
  boxShadow: 'none !important',
};

const buttonStyleHovered = {
  color: `${extendedColors.text.default} !important`,
  fontWeight: 'semibold !important',
  backgroundColor: '#E4EDFA !important',
  boxShadow: 'none !important',
};

const buttonStyleSelected = {
  color: `${extendedColors.primary.default} !important`,
  fontWeight: 'semibold !important',
  backgroundColor: '#E4EDFA !important',
  boxShadow: 'none !important',
  border: `1px solid ${extendedColors.primary.default} !important`,
};

const Option = ({ text, isActive = false, ...others }: IProps) => {
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.lg})`);

  return (
    <Button
      _hover={
        !isActive && isMobile
          ? { ...buttonStyleHoveredMobile }
          : { ...buttonStyleHovered }
      }
      sx={{ ...buttonStyle, ...(isActive && buttonStyleSelected) }}
      {...others}
    >
      {text}
    </Button>
  );
};

Option.defaultProps = {
  isActive: false,
};

export default Option;
