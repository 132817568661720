import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Fade, Heading, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from 'api/auth';
import Alert from 'common/Alert';
import FormPasswordField from 'common/form/FormPasswordField';
import uiRoutes from 'constants/uiRoutes';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { IChangePasswordForm } from 'interfaces/patient';
import { ChangePasswordSchema } from 'schemas/auth';

const defaultValues: IChangePasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const ChangePasswordForm = () => {
  const methods = useForm<IChangePasswordForm>({
    defaultValues,
    resolver: yupResolver(ChangePasswordSchema),
  });

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });
  const navigate = useNavigate();

  const {
    formState: { isSubmitting },
    watch,
  } = methods;

  const clearAlertMessage = () =>
    setAlertMessage({
      message: '',
      status: 'error',
    });

  const { confirmNewPassword, newPassword, oldPassword } = watch();

  useEffect(() => {
    if (confirmNewPassword === '' || newPassword === '' || oldPassword === '') {
      clearAlertMessage();
    }
  }, [confirmNewPassword, newPassword, oldPassword]);

  const onSubmit = async (data: IChangePasswordForm) => {
    try {
      const res = await changePassword(data);
      setAlertMessage({
        message: res.message,
        status: 'success',
      });
      setTimeout(() => {
        navigate(uiRoutes.logout);
      }, 2000);
    } catch (err) {
      setAlertMessage({
        message: (err as IComposeError)?.message,
        status: 'error',
      });
    }
  };

  const clearError = () => clearAlertMessage();

  return (
    <Fade in unmountOnExit>
      <Heading as="h2" size="heading2">
        Change Password
      </Heading>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Alert
            autoClose
            mt={6}
            onClose={clearError}
            status={alertMessage.status}
            text={alertMessage.message}
          />

          <Stack mt={8} spacing={3} width="100%">
            <FormPasswordField
              label="Current Password"
              name="oldPassword"
              placeholder="Enter your current password"
              showHelperText
            />

            <FormPasswordField
              label="New Password"
              name="newPassword"
              placeholder="Enter your new password"
              showHelperText
            />
            <FormPasswordField
              label="Confirm Password"
              name="confirmNewPassword"
              placeholder="Re-enter your new password"
              showHelperText
            />

            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                mt={{ base: '1.5rem', xl: '2rem', '3xl': '2.5rem' }}
                type="submit"
                variant="primaryLg"
                width={{ base: '100%', md: 'auto' }}
              >
                Save Changes
              </Button>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </Fade>
  );
};

export default ChangePasswordForm;
