import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import {
  IGenerateOtpResponse,
  IGenerateOtpSchema,
  IVerifyOtpResponse,
  IVerifyOtpSchema,
} from 'interfaces/otp';
import { baseRequest } from 'utils/axios';

const { otp } = apiRoutes;

export const generateOtpToken = async (
  data: IGenerateOtpSchema
): Promise<IResponse<IGenerateOtpResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.generate,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const verifyOtpToken = async (
  data: IVerifyOtpSchema
): Promise<IResponse<IVerifyOtpResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.verify,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
