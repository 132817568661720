/* eslint-disable import/prefer-default-export */
const FADED_TEXT = 'text.faded';

export const spacing = {
  MARGIN_X: {
    base: '1rem',
    md: '1.5rem',
    lg: 0,
  },
  // MAX_WIDTH change it to 896px
  MAX_WIDTH: '1170px',
  CONTAINER_Y_PADDING: {
    base: '20',
    lg: '24',
  },
  CONTAINER_X_PADDING: {
    base: '3',
    md: '6',
    lg: '8',
    xl: '0',
  },
};

export const signUpSpacing = {
  MARGIN_X: {
    base: '1rem',
    md: '1.5rem',
    lg: 0,
  },
  MAX_WIDTH: '536px',
  CONTAINER_Y_PADDING: {
    base: '20',
    lg: '24',
  },
  CONTAINER_X_PADDING: {
    base: '3',
    md: '6',
    lg: '8',
    xl: '0',
  },
};

export const sectionTitleSize = {
  base: '3rem',
  xl: '4.5rem', // 72px
};

export const continueAssementTitle = {
  fontSize: {
    base: '2rem', // 32px
    md: '2.25rem', // 48px
    '3xl': '2.25rem', // 54px
  },
  fontWeight: 700,
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: 'center',
};

export const sectionTitle = {
  fontWeight: 'bold',
  fontSize: {
    base: '3.125rem', // 50px
    xl: '3.5rem', // 56px
    '3xl': '4.5rem', // 72px
  },
  marginBottom: {
    base: '0.5rem',
    xl: '1rem', // 16px
    '3xl': '1.5rem', // 24px
  },
};

export const sectionTitleSmall = {
  fontSize: {
    base: '2rem', // 32px
    md: '3rem', // 48px
    '3xl': '3rem', // 54px
  },
  fontWeight: 700,
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: 'center',
};

export const primaryHeading = {
  fontSize: {
    base: '1rem', // 16px
    md: '1.875rem',
    xl: '1.875rem', // 30px
  },
  fontWeight: 700,
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: 'center',
};

export const optTitleSmall = {
  fontSize: {
    base: '1rem', // 16px
    md: '2rem', // 32px
    '3xl': '2rem', // 32px
    sm: '1.5rem',
  },
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: 'center',
};

export const sectionSubtitleFocused = {
  fontFamily: 'Open Sans',
  fontWeight: 'medium',
  color: FADED_TEXT,
  fontSize: {
    base: '1.125rem', // 18px
    xl: '1.5rem', // 24px
    '3xl': '1.625rem', // 26px
  },
  marginBottom: {
    base: '0.5rem',
    md: '1rem', // 16px
    '3xl': '1.5rem', // 24px
  },
  lineHeight: {
    base: '32px',
    xl: '35px',
    '3xl': '42px',
  },
};

export const sectionSubtitle = {
  fontFamily: 'Open Sans',
  fontWeight: 'regular',
  color: FADED_TEXT,
  fontSize: {
    base: '1rem', // 16px
    md: '1.125rem', // 18px
  },
};

export const bodyText = {
  fontFamily: 'Open Sans',
  fontWeight: 'regular',
  color: FADED_TEXT,
  fontSize: {
    base: '0.875rem', // 14px
    xl: '1rem', // 16px
  },
};

export const questionText = {
  fontWeight: 'bold',
  fontSize: {
    base: '1.5rem',
    xl: '2rem',
    '3xl': '2.25rem', // 72px
  },
  marginBottom: {
    base: '0.5rem',
    xl: '1rem', // 16px
    '3xl': '1.5rem', // 24px
  },
  lineHeight: {
    base: '32px',
    xl: '42px',
    '3xl': '48px',
  },
};

export const containerStyles = {
  height: '100vh',
  scrollSnapPointsY: 'repeat(100vh)',
  scrollSnapType: 'y mandatory',
  overflowY: 'scroll',
};

export const sectionStyles = {
  height: '100vh',
  scrollSnapAlign: 'start',
};

export const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: '6px',
    borderRadius: '10px',
    backgroundColor: `gray.100`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `primary.default`,
    borderRadius: '20px',
  },
};

export const formSimpleGridGap = { base: '3', md: '5' };

export const dividerStyles = {
  height: {
    base: '80px',
    md: '100px',
    lg: '140px',
  },
};
