import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Alert as ChakraAlert,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as patientAPI from 'api/patient';
import Alert from 'common/Alert';
import { formSimpleGridGap } from 'constants/styles';
import { AddressType } from 'enum/AddressType';
import { AlertStatusType } from 'interfaces/alert';
import { IError } from 'interfaces/http';
import { IAddressForm, IPatientAddressUpdateSchema } from 'interfaces/patient';
import BillingAddress from 'pages/payment/BillingAddress';
import BillingContact from 'pages/payment/BillingContact';
import ShippingAddress from 'pages/payment/ShippingAddress';
import ShippingContact from 'pages/payment/ShippingContact';
import { CheckoutSchema } from 'schemas/patient';
import { selectPatient, togglePatientDetailFetchFlag } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const defaultValues = {
  // Shipping address
  shippingAddressLine1: '',
  shippingAddressLine2: '',
  shippingCity: '',
  shippingState: '',
  shippingZipCode: '',
  // Shipping contact
  shippingEmail: '',
  shippingPhone: '',
  // Billing address
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  // Billing contact
  billingEmail: '',
  billingPhone: '',
};

const UpdateAddress = () => {
  const dispatch = useDispatch();
  const patient = useAppSelector(selectPatient);
  const patientBillingAddress = patient?.formattedAddress?.billingAddress[0];
  const patientShippingAddress = patient?.formattedAddress?.shippingAddress[0];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });

  const clearAlertMessage = () => {
    setAlertMessage({
      message: '',
      status: 'error',
    });
  };

  const methods = useForm<IAddressForm>({
    defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(CheckoutSchema),
  });

  const { reset } = methods;

  useEffect(() => {
    if (patientBillingAddress && patientShippingAddress) {
      reset({
        // Shipping address
        shippingAddressLine1: patientShippingAddress.addressLine1,
        shippingAddressLine2: patientShippingAddress.addressLine2,
        shippingCity: patientShippingAddress.city,
        shippingState: patientShippingAddress.state,
        shippingZipCode: patientShippingAddress.zipCode,
        // Shipping contact
        shippingEmail: patientShippingAddress.email,
        shippingPhone: patientShippingAddress.phone,
        // Billing address
        billingAddressLine1: patientBillingAddress.addressLine1,
        billingAddressLine2: patientBillingAddress.addressLine2,
        billingCity: patientBillingAddress.city,
        billingState: patientBillingAddress.state,
        billingZipCode: patientBillingAddress.zipCode,
        // Billing contact
        billingEmail: patientBillingAddress.email,
        billingPhone: patientBillingAddress.phone,
      });
    }
  }, [patient, patientBillingAddress, patientShippingAddress, reset]);

  const onSubmit = async (data: IAddressForm) => {
    setIsSubmitting(true);
    const billingAddress = {
      id: patientBillingAddress?.id,
      addressType: AddressType.BILLING,
      isDefault: true,
      addressLine1: data.billingAddressLine1,
      addressLine2: data.billingAddressLine2,
      city: data.billingCity,
      state: data.billingState,
      zipCode: data.billingZipCode,
      phone: data.billingPhone,
      email: data.billingEmail,
    };

    const shippingAddress = {
      id: patientShippingAddress?.id,
      addressType: AddressType.SHIPPING,
      isDefault: true,
      addressLine1: data.shippingAddressLine1,
      addressLine2: data.shippingAddressLine2,
      city: data.shippingCity,
      state: data.shippingState,
      zipCode: data.shippingZipCode,
      phone: data.shippingPhone,
      email: data.shippingEmail,
    };

    const payload: IPatientAddressUpdateSchema = {
      billingAddress: [billingAddress],
      shippingAddress: [shippingAddress],
    };
    try {
      // API
      const response = await patientAPI.editPatientBillingShipping(payload);
      await dispatch(togglePatientDetailFetchFlag());

      setAlertMessage({
        message: response.message,
        status: 'success',
      });
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack>
      <Alert
        autoClose
        mb={2}
        mt={5}
        onClose={clearAlertMessage}
        status={alertMessage.status}
        text={alertMessage.message}
      />
      {!!errorMessage && (
        <ChakraAlert mb={4} status="error">
          <AlertIcon />
          <AlertTitle>{errorMessage}</AlertTitle>
        </ChakraAlert>
      )}
      <Box
        display={
          patientBillingAddress || patientShippingAddress ? 'block' : 'none'
        }
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="8">
              <Stack spacing={formSimpleGridGap}>
                <BillingAddress />
                <BillingContact />
              </Stack>
              <Stack spacing={formSimpleGridGap}>
                <ShippingAddress />
                <ShippingContact />
              </Stack>
            </Stack>

            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                mt={{ base: '1.5rem', xl: '2rem', '3xl': '2.5rem' }}
                type="submit"
                variant="primaryLg"
                width={{ base: '100%', md: 'auto' }}
              >
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
      <Box
        display={
          patientBillingAddress || patientShippingAddress ? 'none' : 'block'
        }
      >
        <Heading fontWeight="600" marginBottom={4} size="heading2">
          Billing Address
        </Heading>
        <Box
          sx={{
            borderRadius: '4px',
            padding: 4,
            background: 'neutral.lighter',
            // display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            color="text.faded"
            size="bodyTextSmallSemibold"
            textAlign="center"
          >
            No Billing details found.
          </Text>
        </Box>
      </Box>
    </Stack>
  );
};

export default UpdateAddress;
