import React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

interface IProps {
  isPrimary: boolean;
  deleteCard: VoidFunction;
  isSubmitting: boolean;
}

export const ConfirmDeleteDialog = ({
  isPrimary,
  deleteCard,
  isSubmitting,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  return (
    <>
      <Text
        _hover={{ textDecoration: 'underline' }}
        color="red"
        cursor="pointer"
        display={isPrimary ? 'none' : 'block'}
        m={0}
        onClick={() => onOpen()}
        size="bodyTextMediumSemibold"
      >
        Remove
      </Text>

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={{ base: 'sm', md: 'md', lg: 'lg' }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Card ?
            </AlertDialogHeader>

            <AlertDialogBody>
              This action is irreversible. Are you sure you want to delete the
              card?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef} variant="primaryLinkLg">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                ml={3}
                onClick={deleteCard}
                variant="primaryLg"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
