import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Upload } from 'react-feather';

import { Box, Button, Heading, Text } from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { parseHtml } from 'utils/parseHtml';

interface IProps {
  onClose: VoidFunction;
  setToggleFetchDocumentDetails: (value: React.SetStateAction<boolean>) => void;
  setAlertMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      status: AlertStatusType;
    }>
  >;
  setIsSubmittingForModalCloseButton: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const DropZoneCss = () => (
  <Box border="1px solid #0D47A1" borderStyle="dashed">
    <Box alignItems="center" display="flex" flexDirection="column" p={4}>
      <Upload />
      <Heading mt={2} size="heading3">
        Drag and Drop
      </Heading>
      <Heading mt={2} size="heading3">
        or
      </Heading>
      <Heading
        _hover={{ textDecoration: 'underline' }}
        color="#0D47A1"
        cursor="pointer"
        mt={2}
        size="heading3"
      >
        Browse file
      </Heading>
    </Box>
  </Box>
);

const types = [
  // Images
  'png',
  'jpeg',
  'jpg',
  'svg',
  'gif',
  // Excel
  'xls',
  'xlsx',
  'xlt',
  'xlsm',
  'xlb',
  'xlc',
  'xlsb',
  // Word
  'doc',
  'docm',
  'dotx',
  'dotm',
  'dot',
  'docx',
  // PDF
  'pdf',
  // Text
  'txt',
  'rtf',
  'csv',
  'tsv',
  'xml',
  'json',
];

const DropZoneFileUploadedCss = ({ file }: { file: any }) => (
  <Box border="1px solid #0D47A1" borderStyle="dashed">
    <Box alignItems="center" display="flex" flexDirection="column" p={4}>
      <Upload />
      <Heading
        _hover={{ textDecoration: 'underline' }}
        color="#0D47A1"
        cursor="pointer"
        mt={2}
        size="heading3"
      >
        <u>{parseHtml(file[0]?.name, 40)}</u>
      </Heading>
    </Box>
  </Box>
);

export const DocumentDragandDrop = ({
  onClose,
  setToggleFetchDocumentDetails,
  setAlertMessage,
  setIsSubmittingForModalCloseButton,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const handleChange = (handleChangeFile: any) => {
    setFile(handleChangeFile);
  };

  const onSubmit = async () => {
    setLoading(true);
    setIsSubmittingForModalCloseButton(true);
    const formData = new FormData();

    formData.append('documents', file[0]);
    try {
      const res = await patientAPI.uploadDocument(formData);
      setToggleFetchDocumentDetails((prev) => !prev);
      setAlertMessage({
        message: res.message,
        status: 'success',
      });
    } catch (err) {
      setAlertMessage({
        message: (err as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setIsSubmittingForModalCloseButton(false);
    }

    onClose();
  };

  return (
    <div className="App">
      <FileUploader
        // eslint-disable-next-line react/no-children-prop
        children={
          !file ? <DropZoneCss /> : <DropZoneFileUploadedCss file={file} />
        }
        handleChange={handleChange}
        maxSize={100}
        multiple
        name="file"
        onDrop={(item: any) => {
          setFile(item);
        }}
        types={types}
      />

      <Text mt={4} size="bodyTextLarge">
        Filesize limit is 100MB
      </Text>

      <Button
        colorScheme="blue"
        disabled={file === null || file?.length < 1}
        isLoading={loading}
        mr={3}
        mt={8}
        onClick={onSubmit}
        variant="primaryLg"
        width="100%"
      >
        Upload
      </Button>
    </div>
  );
};
