import { extendedColors } from '../colors';
import { fontWeights } from '../fonts';

const { text } = extendedColors;

const Heading = {
  sizes: {
    heading1: {
      fontSize: '30px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.bold,
    },

    heading2: {
      fontSize: '24px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.semibold,
    },
    heading3: {
      fontSize: '20px',
      lineHeight: 1.5,
      color: text.default,
      fontWeight: fontWeights.semibold,
    },

    // Depreciate these (not included in the style guide)
    '2xl': {
      fontSize: '2.5rem',
      letterSpacing: '0.73px',
    },
    lg: {
      fontFamily: 'Open Sans',
      fontWeight: 'medium',
      color: 'text.faded',
      fontSize: '1.5rem',
    },
    md: {
      fontFamily: 'Open Sans',
      fontWeight: 'semibold',
    },
    //----------------------------------------------------
  },
};

export default Heading;
