import { DeepPartial, Theme } from '@chakra-ui/react';

export const fonts: DeepPartial<Theme['fonts']> = {
  body: 'Open Sans',
  heading: 'Open Sans',
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
};
