import { useState } from 'react';

import { Td, Text, Tr } from '@chakra-ui/react';
import * as patientAPI from 'api/patient';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { formatDate } from 'utils/date';

import { ConfirmDeleteDocumentDialog } from './ConfirmDeleteDocument';
import { ConfirmDownloadDocumentDialog } from './ConfirmDownloadDocumentDialog';
import { ConfirmShareDocumentDialog } from './ConfirmShareDocumentDialog';

interface IProps {
  filename: string;
  filedate: string;
  status: boolean;
  documentId: string;
  setToggleFetchDocumentDetails: (value: React.SetStateAction<boolean>) => void;
  setAlertMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      status: AlertStatusType;
    }>
  >;
}

const DocumentDetailListItem = ({
  filename,
  filedate,
  status,
  documentId,
  setToggleFetchDocumentDetails,
  setAlertMessage,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const shareFile = async () => {
    setLoading(true);
    const payload = {
      isShared: true,
      id: documentId,
    };
    try {
      const response = await patientAPI.shareDocument(payload);
      setToggleFetchDocumentDetails((prev) => !prev);
      setAlertMessage({
        message: response.message,
        status: 'success',
      });
    } catch (err) {
      setAlertMessage({
        message: (err as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async () => {
    setLoading(true);
    try {
      const response = await patientAPI.deleteDocument(documentId);
      setToggleFetchDocumentDetails((prev) => !prev);
      setAlertMessage({
        message: response.message,
        status: 'success',
      });
    } catch (err) {
      setAlertMessage({
        message: (err as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tr>
      <Td>
        <ConfirmDownloadDocumentDialog
          documentId={documentId}
          filename={filename}
          isDocumentFileName
        />
      </Td>
      <Td>
        <Text color="neutral.default" fontSize="14px">
          {formatDate(filedate)}
        </Text>
      </Td>
      <Td>
        <ConfirmShareDocumentDialog
          filename={filename}
          isSubmitting={loading}
          shareCard={shareFile}
          status={status}
        />
      </Td>
      <Td>
        <ConfirmDeleteDocumentDialog
          deleteFile={deleteFile}
          filename={filename}
          isSubmitting={loading}
        />
      </Td>
    </Tr>
  );
};

export default DocumentDetailListItem;
