import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ComingSoon } from 'common';
import uiRoutes from 'constants/uiRoutes';
import ResetPassword from 'pages/auth/ResetPassword';
import SurveySuccess from 'pages/online-assessment/SurveySuccess';
import BillingInformation from 'pages/patient/BillingInformation';
import ChangePasswordForm from 'pages/patient/ChangePasswordForm';
import Documents from 'pages/patient/Documents';
import Messages from 'pages/patient/Messages';
import NotificationSettings from 'pages/patient/NotificationSettings';
import MyOrder from 'pages/patient/Order';
import PatientProfileForm from 'pages/patient/PatientProfileForm';
import CheckoutSuccess from 'pages/payment/CheckoutSuccess';
import Disclaimer from 'pages/static-pages/disclaimer';
import FAQ from 'pages/static-pages/FAQ';
import PagenotFound from 'pages/static-pages/page-not-found';
import PrivacyPolicy from 'pages/static-pages/privacy-policy';
import ReturnPolicy from 'pages/static-pages/return-policy';
import ShippingPolicy from 'pages/static-pages/shipping-policy';
import TermsOfService from 'pages/static-pages/terms-of-service';
import { getLocal } from 'utils/storage';

// TODO: Lazyload route level components (imported in this way as there are some issues on transition while lazyloading components)
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import HighRiskPatient from './pages/high-risk-patient';
import MessageSentSuccess from './pages/high-risk-patient/MessageSentSuccess';
import Home from './pages/home';
import OnlineAssessment from './pages/online-assessment';
import PatientDashboard from './pages/patient';
import Payment from './pages/payment';
import ServiceAvailablity from './pages/service-availability';
import AccountCreationSuccess from './pages/signup/AccountCreationSuccess';
import Essentials from './pages/signup/Essentials';
import OptInSms from './pages/signup/OptInSms';
import OtpPin from './pages/signup/OtpPin';
import PreSignup from './pages/signup/PreSignup';
import Signup from './pages/signup/Signup';
import ProceedToCheckout from './pages/subscription/ProceedToCheckout';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const isAuthenticated = !!getLocal('jwtToken');
  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate replace state={{ from: location }} to={uiRoutes.home} />;
  }

  return children;
};

const NotRequiredAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const isAuthenticated = !!getLocal('jwtToken');
  if (isAuthenticated) {
    // Redirect them to the /patient/profile page, if the user is Authenticated
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={uiRoutes.patientDashboard.profile}
      />
    );
  }
  return children;
};

const Router = (): JSX.Element => (
  <Routes>
    <Route element={<Home />} path={uiRoutes.home} />
    <Route
      element={
        <NotRequiredAuth>
          <Login />
        </NotRequiredAuth>
      }
      path={uiRoutes.login}
    />
    <Route element={<Logout />} path={uiRoutes.logout} />
    <Route
      element={
        <NotRequiredAuth>
          <ServiceAvailablity />
        </NotRequiredAuth>
      }
      path={uiRoutes.serviceAvailability}
    />
    <Route element={<OnlineAssessment />} path={uiRoutes.onlineAssessment} />

    <Route
      element={
        <RequireAuth>
          <PatientDashboard />
        </RequireAuth>
      }
    >
      <Route
        element={<PatientProfileForm />}
        path={uiRoutes.patientDashboard.profile}
      />
      <Route
        element={<ChangePasswordForm />}
        path={uiRoutes.patientDashboard.changePassword}
      />
      <Route
        element={<BillingInformation />}
        path={uiRoutes.patientDashboard.billingInformation}
      />
      <Route
        element={<NotificationSettings />}
        path={uiRoutes.patientDashboard.notificationSettings}
      />
      <Route element={<MyOrder />} path={uiRoutes.patientDashboard.myOrders} />
      <Route
        element={<Documents />}
        path={uiRoutes.patientDashboard.documents}
      />
      <Route element={<Messages />} path={uiRoutes.patientDashboard.messages} />
      <Route
        element={<ComingSoon />}
        path={uiRoutes.patientDashboard.appointments}
      />
    </Route>

    <Route
      element={
        <RequireAuth>
          <Payment />
        </RequireAuth>
      }
      path={uiRoutes.payment}
    />
    <Route
      element={
        <NotRequiredAuth>
          <PreSignup />
        </NotRequiredAuth>
      }
      path={uiRoutes.preSignup}
    />
    <Route
      element={
        <NotRequiredAuth>
          <Signup />
        </NotRequiredAuth>
      }
      path={uiRoutes.signup}
    />
    <Route
      element={
        <RequireAuth>
          <Essentials />
        </RequireAuth>
      }
      path={uiRoutes.essentials}
    />
    <Route
      element={
        <RequireAuth>
          <OptInSms />
        </RequireAuth>
      }
      path={uiRoutes.optInSms}
    />
    <Route
      element={
        <RequireAuth>
          <OtpPin />
        </RequireAuth>
      }
      path={uiRoutes.otpPin}
    />
    <Route
      element={
        <RequireAuth>
          <AccountCreationSuccess />
        </RequireAuth>
      }
      path={uiRoutes.accountCreationSuccess}
    />
    <Route
      element={
        <NotRequiredAuth>
          <ResetPassword />
        </NotRequiredAuth>
      }
      path={uiRoutes.resetPassword}
    />
    <Route
      element={<MessageSentSuccess />}
      path={uiRoutes.messageSentSuccess}
    />
    <Route
      element={
        <RequireAuth>
          <SurveySuccess />
        </RequireAuth>
      }
      path={uiRoutes.surveySuccess}
    />
    <Route
      element={
        <RequireAuth>
          <ProceedToCheckout />
        </RequireAuth>
      }
      path={uiRoutes.proceedToCheckout}
    />
    <Route
      element={
        <RequireAuth>
          <Payment />
        </RequireAuth>
      }
      path={uiRoutes.checkout}
    />
    <Route
      element={
        <RequireAuth>
          <HighRiskPatient />
        </RequireAuth>
      }
      path={uiRoutes.highRiskPatient}
    />
    <Route
      element={
        <RequireAuth>
          <CheckoutSuccess />
        </RequireAuth>
      }
      path={uiRoutes.checkoutSuccess}
    />
    <Route element={<TermsOfService />} path={uiRoutes.termsOfService} />
    <Route element={<PrivacyPolicy />} path={uiRoutes.privacyPolicy} />
    <Route element={<ShippingPolicy />} path={uiRoutes.shippingPolicy} />
    <Route element={<ReturnPolicy />} path={uiRoutes.returnPolicy} />
    <Route element={<Disclaimer />} path={uiRoutes.disclaimer} />
    <Route element={<FAQ />} path={uiRoutes.faq} />

    <Route element={<PagenotFound />} path="*" />
  </Routes>
);

export default Router;
