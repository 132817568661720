const validationMessage = {
  REQUIRED: 'This field is Required',
  INVALID: 'Invalid',
  INVALID_EMAIL: 'Please enter a valid email address',
  PASSWORD_NOT_MATCHED: 'Passwords do not match',
  PASSWORD_REQUIREMENT:
    'Password must have at least 1 digit or 1 special character and minimum 8 characters long',
  GENDER_REQUIRED: 'Please select your gender',
  INVALID_DATE: 'Please provide a valid date',
  INVALID_PHONE: 'Please provide a valid phone',
  MIN_AGE: 'Minimum age should be 18',
  MAX_AGE: 'Maximum age is less than 100',
  DOB_REQUIRED: 'Birth date is required',
  DOB_MIN_AGE_VALIDATION: 'You must be at least 18 years to qualify',
  LEGAL_FIRST_NAME_REQUIRED: 'Legal first name is required',
  LEGAL_MIDDLE_NAME_REQUIRED: 'Legal middle name is required',
  LEGAL_LAST_NAME_REQUIRED: 'Legal last name is required',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
  CURRENT_PASSWORD_REQUIRED: 'Old Password is required',
  NEW_PASSWORD_REQUIRED: 'New Password is required',
  ZIP_CODE_REQUIRED: 'Zipcode is required',
  SERVICE_NOT_AVAILABLE: 'Service is not available in your area',
  PHONE_NUMBER_REQUIRED: 'Phone Number is required',
  BILLING_CITY_REQUIRED: 'Billing City is required',
  SHIPPING_CITY_REQUIRED: 'Shipping City is required',
  BILLING_STATE_RQUIRED: 'Billing State is required',
  SHIPPING_STATE_REQUIRED: 'Shipping state is required',
};

export default validationMessage;
