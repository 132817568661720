import { File, Inbox, LogOut, ShoppingCart, User } from 'react-feather';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Icon,
  Text,
} from '@chakra-ui/react';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import { useAppSelector } from 'stores/hooks';
import { selectUnreadMessageCount } from 'stores/patient/messages';
import { extendedColors } from 'styles/customTheme/colors';

const { MESSAGE_MENU_TEXT } = misc;

// TODO: Use the common constant for menu items (from 'constants/patientPortalMenus.ts')
const MENU_ITEMS = [
  {
    id: 1,
    defaultIsOpen: true,
    title: 'Profile',
    icon: User,
    children: [
      {
        id: 11,
        title: 'Personal Information',
        url: uiRoutes.patientDashboard.profile,
      },
      {
        id: 12,
        title: 'Change Password',
        url: uiRoutes.patientDashboard.changePassword,
      },
      {
        id: 13,
        title: 'Billing Information',
        url: uiRoutes.patientDashboard.billingInformation,
      },
      {
        id: 14,
        title: 'Notification Settings',
        url: uiRoutes.patientDashboard.notificationSettings,
      },
    ],
  },
  {
    id: 2,
    title: 'My Orders',
    icon: ShoppingCart,
    children: null,
    url: uiRoutes.patientDashboard.myOrders,
  },
  {
    id: 3,
    title: 'Documents',
    icon: File,
    children: null,
    url: uiRoutes.patientDashboard.documents,
  },
  {
    id: 4,
    title: MESSAGE_MENU_TEXT,
    icon: Inbox,
    children: null,
    url: uiRoutes.patientDashboard.messages,
  },
  // {
  //   id: 5,
  //   title: 'All Appointments',
  //   icon: Calendar,
  //   children: null,
  //   url: uiRoutes.patientDashboard.appointments,
  // },
  {
    id: 6,
    title: 'Logout',
    icon: LogOut,
    children: null,
    url: uiRoutes.logout,
  },
];

const SideNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const unreadMessageCount = useAppSelector(selectUnreadMessageCount);

  const defaultIndex = MENU_ITEMS.reduce((acc, { defaultIsOpen }, index) => {
    if (defaultIsOpen) {
      acc.push(index);
    }
    return acc;
  }, [] as number[]);

  const handleMenuSelect = (menu: any) => () => {
    if (menu?.children?.[0]) {
      navigate(menu.children[0].url);
      return;
    }
    navigate(menu.url);
  };

  const handleMenuItemSelect = (url: string) => () => {
    navigate(url);
  };

  const checkIsMenuActive = (url: string) => location.pathname === url;

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="row"
      height="100%"
      overflowY="auto"
    >
      <Box
        borderRight={`1px solid ${extendedColors.neutral.light} `}
        display={{ base: 'none', md: 'block' }}
        height="100%"
        overflowY="auto"
        width="35%"
      >
        <Accordion
          allowToggle
          borderBottomWidth="none"
          boxShadow="none"
          defaultIndex={defaultIndex}
          // height="calc(100vh - 215px)"
          // height="100%"
          mt={5}
        >
          {MENU_ITEMS.map((menu) => (
            <AccordionItem border="none" key={menu.id}>
              <AccordionButton
                _focus={{
                  boxShadow: 'none',
                }}
                onClick={handleMenuSelect(menu)}
                sx={{
                  backgroundColor:
                    menu.url && checkIsMenuActive(menu.url)
                      ? 'primary.lighter'
                      : '',
                  borderRight:
                    menu.url && checkIsMenuActive(menu.url)
                      ? `2px solid ${extendedColors.primary.default}`
                      : '',
                  outline: 'none',
                  pl: 10,
                }}
              >
                <Icon as={menu.icon} mr={4} />
                <Text
                  noOfLines={1}
                  size="bodyTextLargeSemibold"
                  textAlign="left"
                  title={menu.title}
                  width="100%"
                >
                  {menu.title === MESSAGE_MENU_TEXT ? (
                    <>
                      {menu.title}
                      {unreadMessageCount > 0 && (
                        <Badge
                          alignItems="center"
                          borderRadius="50%"
                          colorScheme="red"
                          display="inline-flex"
                          fontSize="10px"
                          height="15px"
                          justifyContent="center"
                          ml={0.5}
                          variant="subtle"
                          width="15px"
                        >
                          {unreadMessageCount}
                        </Badge>
                      )}
                    </>
                  ) : (
                    menu.title
                  )}
                </Text>
              </AccordionButton>
              {!!menu.children?.length && (
                <AccordionPanel
                  sx={{
                    p: 0,
                    my: 2,
                  }}
                >
                  {menu.children?.map((item) => (
                    <Box
                      key={item.id}
                      onClick={handleMenuItemSelect(item.url)}
                      sx={{
                        backgroundColor: checkIsMenuActive(item.url)
                          ? 'primary.lighter'
                          : '',
                        borderRight: checkIsMenuActive(item.url)
                          ? `2px solid ${extendedColors.primary.default}`
                          : '',
                        py: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <Text
                        color="neutral.default"
                        key={item.id}
                        marginLeft="70px"
                        noOfLines={2}
                        size="bodyTextMediumSemibold"
                      >
                        {item.title}
                      </Text>
                    </Box>
                  ))}
                </AccordionPanel>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      <Box
        // height="calc(100vh - 215px)"
        height="100%"
        overflow="auto"
        p={{ base: 0, md: 8 }}
        pb={1}
        pt={{ base: 8, md: 8 }}
        width="100%"
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default SideNavigation;
