import { Link as RouterLink } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';
import externalRoutes from 'constants/externalRoutes';
import { bodyText, spacing } from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { IFooter } from 'interfaces/cosmic';
import { getCurrentYear } from 'utils/date';

const { MAX_WIDTH, CONTAINER_X_PADDING } = spacing;

const ACCENT_COLOR = 'accent.default';

interface Props {
  data: IFooter;
}

const SurveyFooter = (props: Props) => {
  const { data } = props;
  return (
    <Box
      marginX="auto"
      maxWidth={MAX_WIDTH}
      paddingX={CONTAINER_X_PADDING}
      pb="12"
      pt="3"
      width="100%"
    >
      <Flex
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
        justifyContent="space-between"
        lineHeight="8"
        marginX="auto"
        w="80%"
      >
        <RouterLink target="_blank" to={uiRoutes.termsOfService}>
          <Text
            _hover={{ color: ACCENT_COLOR }}
            color="text.default"
            fontSize="sm"
            fontWeight="medium"
          >
            Terms of Service
          </Text>
        </RouterLink>
        <RouterLink target="_blank" to={uiRoutes.privacyPolicy}>
          <Text
            _hover={{ color: ACCENT_COLOR }}
            color="text.default"
            fontSize="sm"
            fontWeight="medium"
          >
            Privacy Policy
          </Text>
        </RouterLink>
        <RouterLink target="_blank" to={uiRoutes.shippingPolicy}>
          <Text
            _hover={{ color: ACCENT_COLOR }}
            color="text.default"
            fontSize="sm"
            fontWeight="medium"
          >
            Shipping Policy
          </Text>
        </RouterLink>
        <RouterLink target="_blank" to={uiRoutes.returnPolicy}>
          <Text
            _hover={{ color: ACCENT_COLOR }}
            color="text.default"
            fontSize="sm"
            fontWeight="medium"
          >
            Return Policy
          </Text>
        </RouterLink>
        <RouterLink target="_blank" to={uiRoutes.disclaimer}>
          <Text
            _hover={{ color: ACCENT_COLOR }}
            color="text.default"
            fontSize="sm"
            fontWeight="medium"
          >
            Disclaimer
          </Text>
        </RouterLink>
        <Text
          _hover={{ color: ACCENT_COLOR }}
          color="text.default"
          fontSize="sm"
          fontWeight="medium"
        >
          <a href={externalRoutes.FAQs} rel="noreferrer" target="_blank">
            FAQs
          </a>
        </Text>
      </Flex>
      <Text
        mt="8"
        sx={{ ...bodyText, color: 'text.default', fontWeight: 'medium' }}
        textAlign="center"
      >
        © {getCurrentYear()} {data?.metadata?.copyright_text}
      </Text>
    </Box>
  );
};

export default SurveyFooter;
