import { Box, Heading, Image, Text } from '@chakra-ui/react';
import mail from 'assets/images/svg/mail.svg';

const PasswordRecoveryConfirmPage = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    pb={16}
    pt={14}
  >
    <Image src={mail} />
    <Heading mb={2} mt={4} size="heading1">
      Please check your email
    </Heading>
    <Text>We have sent a password recovery link to your email.</Text>
  </Box>
);

export default PasswordRecoveryConfirmPage;
