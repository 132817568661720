import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { IMerchantKey } from 'interfaces/merchant';
import { baseRequest } from 'utils/axios';

const { merchant } = apiRoutes;

export const getMerchantKey = async (
  source: string
): Promise<IResponse<IMerchantKey>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: merchant.getMerchantKey.replace(':source', source),
  });

  if (error) {
    return Promise.reject(error);
  }

  return response?.data;
};
