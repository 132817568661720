import { FileText } from 'react-feather';

import { Box, Td, Text, Tr } from '@chakra-ui/react';
import { formatDate } from 'utils/date';
import { parseHtml } from 'utils/parseHtml';

import { ConfirmDownloadDocumentDialog } from './ConfirmDownloadDocumentDialog';

interface IProps {
  filename: string;
  filedate: string;
  documentId: string;
}

const DocumentFromAdminListItem = ({
  filename,
  filedate,
  documentId,
}: IProps) => (
  <Tr>
    <Td>
      <Box display="flex" p={1}>
        <FileText />
        <Text fontSize="14px" ml={2} size="bodyTextLargeSemibold">
          {parseHtml(filename, 40)}
        </Text>
      </Box>
    </Td>
    <Td>
      <Text color="neutral.default">{formatDate(filedate)}</Text>
    </Td>

    <Td>
      <ConfirmDownloadDocumentDialog
        documentId={documentId}
        filename={filename}
      />
    </Td>
  </Tr>
);
export default DocumentFromAdminListItem;
