import { ChevronLeft } from 'react-feather';

import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import check from 'assets/images/svg/check.svg';
import SurveyLayout from 'layouts/SurveyLayout';

const ProceedToCheckout = () => (
  <SurveyLayout>
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '90vh',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxW="524px"
      >
        <Image height="60px" src={check} width="60px" />
        <Box as={Heading} mt={8} size="heading1">
          Well done!
        </Box>
        <Text
          align="center"
          color="neutral.dark"
          mt={8}
          size="bodyTextLargeSemibold"
        >
          Based on your responses to the assessment questions, we believe you
          could benefit from our FDA-approved medication. Once you complete
          checkout, we can get your medication on its way to you - without a
          trip to the doctor and/or pharmacy, but delivered quickly, discreetly
          and conveniently - directly to your door.
        </Text>
        <Button mt={10} variant="primaryLg" width="100%">
          Proceed to Checkout!
        </Button>
        <Button
          leftIcon={<ChevronLeft />}
          mt={2}
          variant="primaryTextLg"
          width="100%"
        >
          Go Back
        </Button>
      </Box>
    </Box>
  </SurveyLayout>
);

export default ProceedToCheckout;
