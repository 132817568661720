/* eslint-disable no-restricted-syntax */
import SUBDOMAIN from 'constants/subdomain.json';

export const getSubDomain = () => {
  /**
   *  Workaround for https://mat-rx.com
   * 
   *  Initially, the setup was done considering the prod URL will also have subdomains such as
   *  “https://npss.mat-rx.com” and “https://mctwf.mat-rx.com”
   * 
   *  As the prod url ('https://mat-rx.com') doesn't have a subdomain, it is harcoded to live which
   *  fetches for NPSS (check subdomain.json)
   */
  if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
    return 'live';
  }
  return window.location.host?.split('.')[0]?.toLowerCase();
};

/**
 * Get client based on subdomain
 * 
 * @example
 * Input = 'qa-mctwf' for 
 *         {
 *            MCTWF: {
                dev: {
                  subdomain: "mctwf",
                  cosmicObjectTypeSlug: 'a1',
                  cosmicObjectTypeSlugSurvey: 'a2',
                qa: {
                  subdomain: "qa-mctwf",
                  cosmicObjectTypeSlug: 'b1',
                  cosmicObjectTypeSlugSurvey: 'b2',
                }
              },
              NPSS: {
                dev: {
                  subdomain: "npss",
                  cosmicObjectTypeSlug: 'c1',
                  cosmicObjectTypeSlugSurvey: 'c2', 
                },
                local: {
                  subdomain: "qa-npss" ,
                  cosmicObjectTypeSlug: 'd1',
                  cosmicObjectTypeSlugSurvey: 'd2',
                },
 *            }           
 *          } 
 * Output = {
 *  client: 'MCTWF',
 *  cosmicObjectTypeSlug = 'b1'
 *  cosmicObjectTypeSlugSurvey = 'b2'
 * }
 * 
 * @param {string} subdomain :: subdomain 
 * @returns {obj} :: client
 */
export const getClient = (
  subdomain: string
): {
  client: string;
  cosmicObjectTypeSlug: string;
  cosmicObjectTypeSlugSurvey: string;
} => {
  const clientObj = {
    client: '',
    cosmicObjectTypeSlug: '',
    cosmicObjectTypeSlugSurvey: '',
  };

  for (const [parentKey, parentValue] of Object.entries(SUBDOMAIN)) {
    for (const childKey of Object.values(parentValue as { [s: string]: any })) {
      if (childKey.subdomain === subdomain) {
        clientObj.client = parentKey;
        clientObj.cosmicObjectTypeSlug = childKey.cosmic_object_key;
        clientObj.cosmicObjectTypeSlugSurvey =
          childKey.cosmic_object_key_survey;
      }
    }
  }

  return clientObj;
};
