import { Stack } from '@chakra-ui/react';
import { Option } from 'common';
import { ISurvey, ISurveyAnswer } from 'interfaces/onlineAssessment';

interface IProps {
  data: ISurvey;
  onSelectOption: (
    option: ISurveyAnswer,
    updateId: string | undefined,
    { multipleSelect }: { multipleSelect: boolean },
    { noneOfTheOptionSelect }: { noneOfTheOptionSelect: number | null },
    { allOfTheOptionSelect }: { allOfTheOptionSelect: number | null }
  ) => void;
  isDisabled?: boolean;
}

const RadioOptions = ({ data, onSelectOption, isDisabled = false }: IProps) => {
  const { answers } = data;
  const { options } = data.metadata;

  return (
    <Stack spacing={4}>
      {options.map((item) => (
        <Option
          disabled={isDisabled}
          isActive={answers?.some((ans) => ans.id === item.id)}
          key={item.id}
          onClick={() =>
            onSelectOption(
              {
                id: item.id,
                value: item.value,
                followUpQuestion: item.followup_question,
              },
              undefined,
              { multipleSelect: false },
              { noneOfTheOptionSelect: null },
              { allOfTheOptionSelect: null }
            )
          }
          text={item.value}
        />
      ))}
    </Stack>
  );
};

RadioOptions.defaultProps = {
  isDisabled: false,
};

export default RadioOptions;
