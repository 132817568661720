import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import checklist from 'assets/images/svg/checklist.svg';
import doctor from 'assets/images/svg/doctor.svg';
import mobilecommunication from 'assets/images/svg/mobilecommunication.svg';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import SurveyHeader from 'layouts/SurveyHeader';

const PreSignup = () => {
  const navigate = useNavigate();

  const onContinueClick = () => {
    navigate(uiRoutes.signup);
  };

  const onBackClick = () => {
    navigate(
      `${uiRoutes.onlineAssessment.replace(
        ':id',
        misc.PRE_SIGNUP_STEP.toString()
      )}`
    );
  };

  return (
    <>
      <SurveyHeader />
      <Box
        mt="120px"
        p="20px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        w="100%"
      >
        <Heading fontWeight="700" size="heading2" textAlign="center">
          Thanks for checking out MATRx
        </Heading>
        <Heading mt={2} size="heading3" textAlign="center">
          Here&apos;s how safe, easy, private and convenient it is.
        </Heading>
        <Grid
          gap="60px"
          mt={10}
          templateColumns={{
            base: '1fr',
            md: '2fr 2fr 2fr',
          }}
        >
          <GridItem
            alignItems="center"
            display="flex"
            flexDirection="column"
            maxWidth={{
              base: '305px',
              sm: '380px',
              md: '305px',
            }}
          >
            <Image borderRadius="full" boxSize="80px" src={checklist} />
            <Text fontWeight="700" mt={8} size="bodyTextLarge">
              Set up your account, then respond to health questions
            </Text>
            <Text mt={4} size="bodyTextLarge">
              You inform us about any symptoms and answer some medical history &
              lifestyle questions.
            </Text>
          </GridItem>
          <GridItem
            alignItems="center"
            display="flex"
            flexDirection="column"
            maxWidth={{
              base: '305px',
              sm: '380px',
              md: '305px',
            }}
          >
            <Image borderRadius="full" boxSize="80px" src={doctor} />
            <Text fontWeight="700" mt={8} size="bodyTextLarge">
              Your response will be reviewed by a physician.
            </Text>
            <Text mt={4} size="bodyTextLarge">
              After setting up your account and completing the online
              assessment, one of our doctors, physicians assistants or nurse
              practitioners will review your assessment and you may be offered
              our FDA-approved medication, mailed directly to your registered
              shipping address.
            </Text>
          </GridItem>
          <GridItem
            alignItems="center"
            display="flex"
            flexDirection="column"
            maxWidth={{
              base: '305px',
              sm: '380px',
              md: '305px',
            }}
          >
            <Image
              borderRadius="full"
              boxSize="80px"
              src={mobilecommunication}
            />
            <Text
              fontWeight="700"
              mt={8}
              size="bodyTextLarge"
              textAlign="start"
              w="100%"
            >
              Stay Connected
            </Text>
            <Text mt={4} size="bodyTextLarge">
              Your Care Team will send a confidential follow up message to your
              MATRx account regarding your assessment. For security, you‘ll need
              to login to your MATRx account to view these private messages. An
              email will alert you when you have new messages in your MATRx
              account. In this way you can securely “stay connected” to your
              information and your Care Team.
            </Text>
          </GridItem>
        </Grid>
        <Grid maxW="536px" mt={8} width="100%">
          <Button
            mt={8}
            onClick={onContinueClick}
            type="button"
            variant="primaryLg"
          >
            Continue to Signup
          </Button>
          <Button
            leftIcon={<ChevronLeft />}
            onClick={onBackClick}
            type="button"
            variant="primaryLinkLg"
          >
            Go back
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default PreSignup;
