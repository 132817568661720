import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  sectionSubtitle,
  sectionSubtitleFocused,
  spacing,
} from 'constants/styles';
import uiRoutes from 'constants/uiRoutes';
import { ISupervisorInformation } from 'interfaces/cosmic';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { MAX_WIDTH } = spacing;

const TEXT_DEFAULT = 'text.default';

const TEXT_ALIGN = {
  base: 'center',
  md: 'left',
};

interface Props {
  data: ISupervisorInformation;
}

const SupervisorInfo = (props: Props) => {
  const { data } = props;

  const patientData = useAppSelector(selectPatient);
  const isLoggedIn = !!patientData?.id;

  return (
    <Box
      display="flex"
      marginX="auto"
      maxWidth={MAX_WIDTH}
      paddingX={{ base: '3', md: '6', lg: '8', xl: '130px' }}
    >
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacingX={{ base: '0px', xl: '50px' }}
      >
        <Box
          height={{ base: '200px', md: '350px', xl: '380px' }}
          marginX={{ base: 'auto', md: '0' }}
          pr={{ base: '0', md: '70px', xl: '50px' }}
          width={{ base: '200px', md: 'auto' }}
        >
          <Image
            alt="supervisor"
            boxSize="100%"
            objectFit="cover"
            objectPosition="center"
            src={data?.supervisor_image?.url}
          />
        </Box>
        <Box mt={{ base: '30px' }} w="100%">
          <Heading
            sx={{
              ...sectionSubtitleFocused,
              color: TEXT_DEFAULT,
              fontWeight: '600',
              marginBottom: {
                base: '2px', // 14px
                sm: '0px',
              },
              textAlign: TEXT_ALIGN,
              fontSize: { base: '12px', sm: '12px', md: '16px' },
            }}
          >
            {data?.supervised_by_text}
          </Heading>
          <Heading
            sx={{
              ...sectionSubtitleFocused,
              color: TEXT_DEFAULT,
              fontWeight: 'bold',
              fontSize: {
                base: '20px', // 24px
                md: '24px', // 32px
                lg: '32px', // 36px
              },
              textAlign: TEXT_ALIGN,
              marginBottom: '14px',
            }}
          >
            {data?.supervisor_name}
          </Heading>
          <Text
            sx={{
              ...sectionSubtitle,
              color: TEXT_DEFAULT,
              fontWeight: '600',
              textAlign: TEXT_ALIGN,
              fontSize: { base: '12px', sm: '12px', md: '16px' },
            }}
          >
            {data?.supervisor_certification}
          </Text>
          <Box
            mt={{ base: '10', xl: '12' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <RouterLink
              to={
                isLoggedIn
                  ? uiRoutes.patientDashboard.profile
                  : uiRoutes.serviceAvailability
              }
            >
              <Button variant="primaryLg">
                <Text as="span" mr="3">
                  {data?.button_text}
                </Text>
              </Button>
            </RouterLink>
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default SupervisorInfo;
