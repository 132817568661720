import React, { useEffect, useState } from 'react';

import { Box, Stack, Switch, Text } from '@chakra-ui/react';
import { editNotificationSetting } from 'api/notification';
import LoadingIndicator from 'common/LoadingIndicator';
import { IComposeError } from 'interfaces/http';
import { INotificationSettings, IPatient } from 'interfaces/patient';
import { selectPatient, setPatient } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

interface IProps {
  setting: INotificationSettings;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const SettingCard = ({ setting, setErrorMessage }: IProps) => {
  const dispatch = useAppDispatch();
  const patientData = useAppSelector(selectPatient);

  const [isChecked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { apiSignatureKey, body, title } = setting;

  useEffect(() => {
    if (patientData) {
      const key = setting.apiSignatureKey as string;
      setChecked(patientData[key as keyof IPatient] as boolean);
    }
  }, [patientData, setting.apiSignatureKey]);

  const onSwitchChange = async () => {
    if (!patientData) return;
    try {
      setErrorMessage('');
      setIsSubmitting(true);
      await editNotificationSetting({
        [apiSignatureKey]: !isChecked,
      });
      dispatch(setPatient({ ...patientData, [apiSignatureKey]: !isChecked }));
    } catch (err) {
      setErrorMessage((err as IComposeError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Box
        alignItems="center"
        backgroundColor="primary.light"
        display="flex"
        justifyContent="space-between"
        p={3}
      >
        <Text size="bodyTextLargeSemibold">{title}</Text>
        <Box>
          {isSubmitting && (
            <LoadingIndicator
              containerHeight="100%"
              size="sm"
              spinnerProps={{
                mr: 2,
                thickness: '2px',
              }}
            />
          )}

          <Switch
            id={`${setting.id}`}
            isChecked={isChecked}
            onChange={onSwitchChange}
            size="md"
            sx={{
              display: isSubmitting ? 'none' : 'block',
            }}
          />
        </Box>
      </Box>
      <Text color="neutral.default" px={3} size="bodyTextLarge">
        {body.replaceAll('{{phone}}', patientData?.phone || '')}
      </Text>
    </Stack>
  );
};
