import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleGrid } from '@chakra-ui/react';
import { FormTextField } from 'common';
import FormMaskedPhoneInput from 'common/form/FormMaskedPhoneInput';
import { formSimpleGridGap } from 'constants/styles';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const BillingContact = () => {
  const { setValue, getValues } = useFormContext();

  const patient = useAppSelector(selectPatient);

  // Populate billing zip code, email and phone if not populated alreaddy
  useEffect(() => {
    if (!patient?.email || !patient.phone) {
      return;
    }

    const { billingEmail, billingPhone } = getValues();

    setValue('billingEmail', billingEmail || patient.email);
    setValue('billingPhone', billingPhone || patient.phone);
  }, [getValues, patient?.email, patient?.phone, setValue]);

  return (
    <SimpleGrid columns={{ base: 1 }} gap={formSimpleGridGap}>
      <FormTextField label="Email Address" name="billingEmail" />
      <FormMaskedPhoneInput label="Phone Number" name="billingPhone" />
    </SimpleGrid>
  );
};

export default BillingContact;
