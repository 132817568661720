/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'stores';

interface IState {
  isLoading: boolean;
  availableStates: Array<string>;
}

const initialState: IState = {
  isLoading: false,
  availableStates: [''],
};

export const slice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAvailableState: (state, action: PayloadAction<Array<string>>) => {
      state.availableStates = action.payload;
    },
  },
});

// Actions
export const { setLoadingState, setAvailableState } = slice.actions;

// Selectors
export const selectLoadingState = (state: RootState) => state.misc.isLoading;
export const selectAvailableState = (state: RootState) =>
  state.misc.availableStates;

// Reducer
export default slice.reducer;
