import { memo, useEffect, useState } from 'react';

import { Avatar, Box, Center, HStack, Text } from '@chakra-ui/react';
import { LoadingIndicator } from 'common';
import { IMessage } from 'interfaces/message';
import { selectUserId } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { extendedColors } from 'styles/customTheme/colors';
import { getFullName } from 'utils/common';
import { getFullDateFormat } from 'utils/date';

interface IProps {
  message: IMessage;
  showCreatedDate: boolean;
}

const MessageCard = ({ message, showCreatedDate }: IProps) => {
  const userId = useAppSelector(selectUserId);
  const [type, setType] = useState<string>('to');

  useEffect(() => {
    if (userId) {
      setType(userId === message.createdByUser.id ? 'to' : 'from');
    }
  }, [userId, message]);

  if (!userId) return <LoadingIndicator />;

  return (
    <Box px={3}>
      <Center mb={4}>
        {showCreatedDate && (
          <Text
            color={extendedColors.neutral.default}
            fontSize="xs"
            size="bodyTextSmallSemibold"
          >
            {getFullDateFormat(message.createdAt)}
          </Text>
        )}
      </Center>
      <HStack
        alignItems="flex-start"
        justifyContent={type !== 'from' ? 'flex-end' : 'flex-start'}
        spacing={4}
        width="100%"
      >
        {type === 'from' && (
          <Avatar
            bg="teal.500"
            color="white.default"
            name={`${message.createdByUser.firstName} ${message.createdByUser.lastName}`}
            size="sm"
          />
        )}
        <div>
          <Box
            bg={
              type === 'from'
                ? extendedColors.neutral.lighter
                : extendedColors.primary.lighter
            }
            border={`1px solid ${extendedColors.primary.light}`}
            borderRadius={4}
            overflow="auto"
            p={3}
            px={4}
          >
            <Text as="p" size="bodyTextLarge" whiteSpace="pre-line">
              {message.message}
            </Text>
          </Box>
          <Text
            align={type === 'from' ? 'start' : 'end'}
            color={extendedColors.neutral.default}
            fontSize="xs"
            style={{ marginTop: 4 }}
          >
            Sent by{' '}
            {getFullName(
              message?.createdByUser?.firstName,
              message?.createdByUser?.lastName,
              message?.createdByUser?.middleName ?? ''
            )}
          </Text>
        </div>
        {type === 'to' && (
          <Avatar
            bg="teal.500"
            color="white.default"
            name={`${message.createdByUser.firstName} ${message.createdByUser.lastName}`}
            size="sm"
          />
        )}
      </HStack>
    </Box>
  );
};

export default memo(MessageCard);
