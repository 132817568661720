const uiRoutes = {
  disclaimer: '/disclaimer',
  essentials: '/essentials',
  home: '/',
  login: '/login',
  logout: '/logout',
  onlineAssessment: '/online-assessment/:id',
  otpPin: '/otp-pin',
  patientDashboard: {
    profile: '/patient/profile',
    changePassword: '/patient/change-password',
    billingInformation: '/patient/billing-information',
    notificationSettings: '/patient/notification-settings',
    myOrders: '/patient/my-orders',
    documents: '/patient/documents',
    messages: '/patient/messages',
    appointments: '/patient/appointments',
  },
  payment: '/payment',
  preSignup: '/pre-signup',
  privacyPolicy: '/privacy-policy',
  returnPolicy: '/return-policy',
  serviceAvailability: '/service-availability',
  shippingPolicy: '/shipping-policy',
  signup: '/signup',
  termsOfService: '/terms-of-service',
  optInSms: '/opt-in-sms',
  accountCreationSuccess: '/account-creation-success',
  messageSentSuccess: '/message-sent-success',
  proceedToCheckout: '/proceed-to-checkout',
  highRiskPatient: '/high-risk-patient',
  checkout: '/checkout',
  checkoutSuccess: '/checkout-success',
  resetPassword: '/reset-password',
  surveySuccess: '/survey-success',
  faq: '/faqs',
};

export default uiRoutes;
