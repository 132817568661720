import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { IDrugOffering } from 'interfaces/drugOffering';
import { formatCurrency } from 'utils/common';

interface IProps {
  data: IDrugOffering;
}

const SubscriptionPlanCard = ({ data }: IProps) => (
  <Box
    bg="white.default"
    border="2px solid"
    borderColor="primary.default"
    cursor="pointer"
    px="3"
    py="5"
    rounded="md"
    width="100%"
  >
    <Flex alignItems="center" justifyContent="space-between" mb="2">
      <Text size="bodyTextLargeSemibold">Subscription</Text>
      <Text color="primary.default" size="bodyTextLargeSemibold">
        {formatCurrency(data.cost)}
        <Text as="span" size="bodyTextMediumSemibold">
          / {data.refillInterval} days
        </Text>
      </Text>
    </Flex>
    <Stack minHeight={{ base: '0', md: '20' }}>
      <Text color="text.faded" size="bodyTextMediumSemibold">
        {data.refillInterval} days prescription drug
      </Text>
      <Text color="text.faded" size="bodyTextMediumSemibold">
        Delivered every {data.refillInterval} days
      </Text>
      <Text color="text.faded" size="bodyTextMediumSemibold">
        Billed every {data.refillInterval} days
      </Text>
    </Stack>
  </Box>
);

export default SubscriptionPlanCard;
