import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Progress } from '@chakra-ui/react';
import * as cosmicAPI from 'api/cosmic';
import * as messageAPI from 'api/message';
import { objectSlug } from 'constants/cosmic';
import misc from 'constants/misc';
import uiRoutes from 'constants/uiRoutes';
import { IQuestionaire } from 'interfaces/cosmic';
import { selectPatient, selectPatientDetailFetchToggle } from 'stores/auth';
import { selectFooterContent, setFooterContent } from 'stores/cms-content';
import { useAppSelector } from 'stores/hooks';
import { selectLoadingState, setAvailableState } from 'stores/misc';
import {
  selectQuestionaires,
  selectSource,
  setQuestionaires,
  setSource,
} from 'stores/online-assessment';
import {
  selectUnreadMessageFetchToggle,
  setUnreadMessageCount,
} from 'stores/patient/messages';
import { setAuthState } from 'utils/auth';
import { buildObjectSlug, getCosmicEnvFromEnv } from 'utils/cosmic';
import { isTokenExpired } from 'utils/jwt';
import { getClient, getSubDomain } from 'utils/misc';
import { clearLocal, clearSession, getLocal, getSession } from 'utils/storage';

const { SURVEY_BEFORE_SIGNUP_LOCAL_KEY, PRE_SIGNUP_STEP } = misc;

interface IProps {
  children: JSX.Element;
}

const AppWrapper = ({ children }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useAppSelector(selectLoadingState);
  const patientDetail = useAppSelector(selectPatient);
  const questionaires = useAppSelector(selectQuestionaires);
  const patientData = useAppSelector(selectPatient);
  const patientDetailFetchToggle = useAppSelector(
    selectPatientDetailFetchToggle
  );
  const unreadMessageFetchToggle = useAppSelector(
    selectUnreadMessageFetchToggle
  );
  const footerContent = useAppSelector(selectFooterContent);
  const source = useAppSelector(selectSource);

  /**
   * Populate the patient detail in case the patient is logged in
   */
  useEffect(() => {
    if (patientDetail?.id) {
      return;
    }

    const token = getLocal('jwtToken');

    if (!token) {
      return;
    }

    if (isTokenExpired(token)) {
      clearLocal();
      clearSession();
      setAuthState({
        token: '',
        dispatch,
      });
      navigate(uiRoutes.login);
      return;
    }

    setAuthState({
      token,
      dispatch,
    });
  }, [patientDetail, dispatch, navigate]);

  // Fetch patient detail and update the patient in the store if the flag 'patientDetailFetchToggle' changes
  useEffect(() => {
    const token = getLocal('jwtToken');

    if (token) {
      setAuthState({
        token,
        dispatch,
      });
    }
  }, [patientDetailFetchToggle, dispatch]);

  /**
   * Fetch questionaires from the CMS (cosmic) and update the store if it isn't populated already
   */
  useEffect(() => {
    const getContent = async () => {
      const token = getLocal('jwtToken');
      const isLoggedIn = !!token;

      // Need to update the questionaire with answers in case of logged in patient
      if (isLoggedIn && !patientData) {
        return;
      }

      const subdomain = getSubDomain();
      const { cosmicObjectTypeSlugSurvey } = getClient(subdomain);

      // TODO: Handle env (dev and prod) for questionaire
      const data = await cosmicAPI.getContent({
        type: cosmicObjectTypeSlugSurvey,
      });

      const questions = data?.objects
        ?.reverse()
        ?.map((item: IQuestionaire, index: number) => {
          const questionIndex = index + 1;

          let surveyAnswer = patientData?.surveys?.find(
            (survey) => +survey.questionId === questionIndex
          );

          /**
           * Answers for the questions before sign up are persisted in local storage
           * and cleared after signup.
           *
           * If not logged in, update the questioaire with data from local storage if exists.
           *
           */
          if (
            !patientData &&
            !surveyAnswer &&
            questionIndex <= PRE_SIGNUP_STEP
          ) {
            let locallyPersistedData = getSession(
              SURVEY_BEFORE_SIGNUP_LOCAL_KEY
            ) as any;
            if (locallyPersistedData) {
              try {
                locallyPersistedData = JSON.parse(locallyPersistedData);
              } catch {
                locallyPersistedData = [];
              }
              surveyAnswer = locallyPersistedData?.find(
                (survey: any) => +survey.questionId === questionIndex
              );
            }
          }

          return {
            ...item,
            id: surveyAnswer?.id ?? undefined,
            questionId: questionIndex,
            question: item.title,
            answers: surveyAnswer?.answers ?? undefined,
          };
        });

      if (!questions?.length) {
        return;
      }

      dispatch(setQuestionaires(questions));
    };

    if (questionaires?.length < 1) {
      getContent();
    }
  }, [dispatch, questionaires, patientData]);

  // Fetch CMS content
  useEffect(() => {
    const getContent = async () => {
      const subdomain = getSubDomain();
      const { client, cosmicObjectTypeSlug } = getClient(subdomain);
      const clientLowerCased = client.toLowerCase();
      const cosmicEnv = getCosmicEnvFromEnv(
        process.env.REACT_APP_ENVIRONMENT || 'DEV'
      );

      const data = await cosmicAPI.getContent({ type: cosmicObjectTypeSlug });

      if (!data?.objects?.length) {
        return;
      }

      const content: any = {};

      data?.objects?.forEach((item: any) => {
        switch (item.slug) {
          case buildObjectSlug(objectSlug.FOOTER, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.footer = item;
            break;

          case buildObjectSlug(objectSlug.AVAILABLE_STATES, {
            client: clientLowerCased,
            cosmicEnv,
          }):
            content.availablestates = item;
            break;

          default:
            break;
        }
      });

      if (content) {
        dispatch(setFooterContent(content.footer));
        dispatch(setAvailableState(content.availablestates?.metadata?.states));
      }
    };

    if (!footerContent) {
      getContent();
    }
  }, [dispatch, footerContent]);

  // Fetch patient detail and update the patient in the store if the flag 'patientDetailFetchToggle' changes
  useEffect(() => {
    const fetchUnreadMessageCount = async () => {
      const response = await messageAPI.getUnreadMessageCount();
      dispatch(setUnreadMessageCount(response.data));
    };

    if (patientDetail?.id) {
      fetchUnreadMessageCount();
    }
  }, [unreadMessageFetchToggle, patientDetail, dispatch]);

  /**
   * Set client (source) in the store if not populated already
   */
  useEffect(() => {
    if (!source) {
      const subdomain = getSubDomain();
      const { client } = getClient(subdomain);
      dispatch(setSource(client));
    }
  }, [source, dispatch]);

  return (
    <Box>
      {isLoading && (
        <Progress
          isIndeterminate
          left="0"
          position="fixed"
          right="0"
          size="sm"
          top="0"
          zIndex="15"
        />
      )}
      {children}
    </Box>
  );
};

export default AppWrapper;
