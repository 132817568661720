const convertMonthToDoubleDigit = (month: string) => {
  if (month && month.length === 1) return `0${month}`;
  return month;
};

const convertYearToDoubleDigit = (year: string) => {
  if (year && year.length > 2) return year.slice(-2);
  return year;
};

export const getCardExpiryText = (expiryMonth: string, expiryYear: string) =>
  `${convertMonthToDoubleDigit(expiryMonth)}/${convertYearToDoubleDigit(
    expiryYear
  )}`;
