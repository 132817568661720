import { useCallback, useEffect } from 'react';

import { Box, Center, Fade, Stack, Text, VStack } from '@chakra-ui/react';
import { changeMessageStatus, getMessages } from 'api/message';
import AlwaysScrollToBottom from 'common/AlwaysScrollToBottom';
import LoadingIndicator from 'common/LoadingIndicator';
import useAPIService from 'hooks/useAPIService';
import { IListResponse } from 'interfaces/http';
import { IMessage } from 'interfaces/message';
import { selectUserId } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  selectMessages,
  setMessages,
  toggleUnreadMessageFetchFlag,
} from 'stores/patient/messages';
import { formatDate } from 'utils/date';

import MessageCard from './MessageCard';
import MessageInput from './MessageInput';

const Messages = () => {
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectMessages);
  const userId = useAppSelector(selectUserId);

  const { loading, data } = useAPIService<IListResponse<IMessage>>({
    api: useCallback(() => getMessages(), []),
  });

  // Change message status to 'read' for the user and update the unread count in store
  useEffect(() => {
    const handleChangeMessageStatus = async (id: string) => {
      await changeMessageStatus(id);
      dispatch(toggleUnreadMessageFetchFlag());
    };

    if (userId) {
      handleChangeMessageStatus(userId);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (data?.data) {
      dispatch(setMessages(data?.data?.rows?.reverse()));
    }
  }, [data, dispatch]);

  const showCreatedDate = (index: number) => {
    const initialMessageDate = messages[index]?.createdAt;
    const prevMessageDate = messages[index - 1]?.createdAt;
    const nextMessageDate = messages[index + 1]?.createdAt;

    const iMD = formatDate(initialMessageDate);
    const pID = formatDate(prevMessageDate);
    const nID = formatDate(nextMessageDate);

    if (iMD === pID) return false;

    if (iMD === pID && iMD === nID) {
      return false;
    }
    return true;
  };

  if (loading) return <LoadingIndicator containerHeight="30rem" />;

  return (
    <Box as={Fade} height="100%" in unmountOnExit>
      <VStack
        display="flex"
        height="100%"
        justifyContent="space-between"
        overflow="auto"
        spacing={10}
      >
        <Stack
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#ccc',
              borderRadius: '24px',
            },
          }}
          flex="1"
          overflow="auto"
          spacing={8}
          width="100%"
        >
          {messages?.length ? (
            messages?.map((message, index) => (
              <MessageCard
                key={message.id}
                message={message}
                showCreatedDate={showCreatedDate(index)}
              />
            ))
          ) : (
            <Center height="100%">
              <Text color="text.faded" size="bodyTextSmall">
                There are no messages to display
              </Text>
            </Center>
          )}
          <AlwaysScrollToBottom />
        </Stack>
        <Box px={3} width="100%">
          <MessageInput />
        </Box>
      </VStack>
    </Box>
  );
};

export default Messages;
