import React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

interface IProps {
  shareCard: VoidFunction;
  isSubmitting: boolean;
  status: boolean;
  filename: string;
}

export const ConfirmShareDocumentDialog = ({
  shareCard,
  isSubmitting,
  status,
  filename,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  return (
    <>
      <Text
        _hover={{ textDecoration: !status ? 'underline' : 'none' }}
        color={!status ? 'primary.default' : 'neutral.default'}
        cursor={!status ? 'pointer' : 'default'}
        fontSize="14px"
        m={0}
        onClick={() => {
          if (!status) {
            onOpen();
          }
        }}
        size="bodyTextMediumSemibold"
      >
        {!status ? 'Share' : 'Shared'}
      </Text>

      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={{ base: 'sm', md: 'md', lg: 'lg' }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Share file with MAT-Rx Provider ?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to share {`${filename}`} file with MAT-Rx
              Provider?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef} variant="primaryLinkLg">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                ml={3}
                onClick={() => {
                  shareCard();
                  onClose();
                }}
                variant="primaryLg"
              >
                Share
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
