import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormLabel,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as otpAPI from 'api/otp';
import * as patientAPI from 'api/patient';
import { FormGenderInput, FormMaskedZipInput, FormTextField } from 'common';
import DropdownMonthDayYear from 'common/DropdownMonthDayYear';
import FormMaskedPhoneInput from 'common/form/FormMaskedPhoneInput';
import uiRoutes from 'constants/uiRoutes';
import { useCalendarDate } from 'hooks/useCalendarDate';
import { IDateArray } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { IGenerateOtpSchema } from 'interfaces/otp';
import { IEssentialsForm, IEssentialsSchema } from 'interfaces/patient';
import SurveyLayout from 'layouts/SurveyLayout';
import { EssentialsSchema } from 'schemas/patient';
import { selectPatient, updatePatient } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getDaysInMonth } from 'utils/common';
import { getDobFromMonthDayYear, getMonthDayYearFromDob } from 'utils/date';
import { formatPatientEssentialsPayload } from 'utils/patient';
import { setLocal } from 'utils/storage';

const defaultValues: IEssentialsForm = {
  gender: '',
  month: '',
  day: '',
  year: '',
  zipCode: '',
  phone: '',
};

const Essentials = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const patient = useAppSelector(selectPatient);
  const { dateArray, setDynamicDays } = useCalendarDate();

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<IEssentialsForm>({
    defaultValues,
    resolver: yupResolver(EssentialsSchema),
  });
  const { watch, reset, getValues } = methods;

  const { gender, month, day, year, zipCode, phone } = watch();

  const onSubmit = async (data: IEssentialsForm) => {
    if (!patient) {
      return;
    }

    const { dob } = getDobFromMonthDayYear({
      month: data.month,
      day: data.day,
      year: data.year,
    });

    const rawPayload: IEssentialsSchema = {
      dob,
      gender: data.gender,
      phone: data.phone,
      zipCode: data.zipCode,
    };
    setIsSubmitting(true);
    try {
      const payload = formatPatientEssentialsPayload(rawPayload);
      await patientAPI.savePatientEssentials(payload);

      dispatch(updatePatient(payload));

      const generateOtpPayload: IGenerateOtpSchema = {
        patientId: patient.id,
        phone: data.phone,
      };
      const generateOtpResponse = await otpAPI.generateOtpToken(
        generateOtpPayload
      );
      if (generateOtpResponse.data?.otpToken) {
        setLocal('otpToken', generateOtpResponse.data.otpToken);
        navigate(uiRoutes.otpPin);
      }
    } catch (err) {
      setErrorMessage((err as IError)?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    function newDaysInMonth() {
      const getEnteredYear = getValues('year');
      const getEnteredMonth = getValues('month');
      const getDays = getDaysInMonth(getEnteredMonth, getEnteredYear);
      setDynamicDays(getDays.toString());
    }
    newDaysInMonth();
  }, [getValues, year, month, setDynamicDays]);

  // Reset error message whenever any of the input goes empty
  useEffect(() => {
    if (
      gender === '' ||
      month === '' ||
      day === '' ||
      year === '' ||
      zipCode === '' ||
      phone === ''
    ) {
      setErrorMessage('');
    }
  }, [gender, zipCode, phone, month, day, year]);

  useEffect(() => {
    if (patient) {
      const {
        day: newDay,
        month: newMonth,
        year: newYear,
      } = getMonthDayYearFromDob(patient?.dob);

      reset({
        gender: patient.gender || '',
        month: newMonth === undefined ? '' : newMonth,
        day: newDay,
        year: newYear,
        zipCode: patient.zipCode || '',
        phone: patient.phone || '',
      });
    }
  }, [patient, reset]);

  const showFreeTextPhoneInput =
    process.env.REACT_APP_ENVIRONMENT === 'DEV' ||
    process.env.REACT_APP_ENVIRONMENT === 'LOCAL';

  return (
    <SurveyLayout>
      <Box mt="120px">
        <Heading textAlign="center" variant="heading1">
          The Essentials
        </Heading>

        {!!errorMessage && (
          <Alert mb={4} status="error">
            <AlertIcon />
            <AlertTitle>{errorMessage}</AlertTitle>
          </Alert>
        )}
        <Box>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack mt={8} spacing={2}>
                <FormGenderInput />
                <FormLabel>Birth Date</FormLabel>
                <Stack direction="row">
                  {dateArray.map((item: IDateArray) => (
                    <DropdownMonthDayYear
                      key={item.id}
                      name={item.name}
                      placeholder={item.placeholder}
                      showHelperText
                      timeArray={item.rawTimeArray}
                    />
                  ))}
                </Stack>

                <FormMaskedZipInput showHelperText />
                {showFreeTextPhoneInput ? (
                  <FormTextField
                    label="Phone Number"
                    name="phone"
                    showHelperText
                  />
                ) : (
                  <FormMaskedPhoneInput
                    label="Phone Number"
                    name="phone"
                    showHelperText
                  />
                )}

                <Stack pt={6} spacing={4}>
                  <Button
                    isLoading={isSubmitting}
                    size="lg"
                    type="submit"
                    variant="primaryBlock"
                    width="100%"
                  >
                    Next
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </SurveyLayout>
  );
};

export default Essentials;
