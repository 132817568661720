import { Box, Text } from '@chakra-ui/react';

interface IProps {
  containerHeight?: string;
}

const ComingSoon = ({ containerHeight }: IProps) => (
  <Box
    sx={{
      width: '100%',
      height: containerHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text>Coming Soon</Text>
  </Box>
);

ComingSoon.defaultProps = {
  containerHeight: '20rem',
};

export default ComingSoon;
