import { Stack } from '@chakra-ui/react';

import PaymentDetails from './PaymentDetails';
import UpdateAddress from './UpdateAddress';

const BillingInformation = () => (
  <Stack spacing={8}>
    <PaymentDetails />
    <UpdateAddress />
  </Stack>
);

export default BillingInformation;
