import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Checkbox, Heading, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as merchantAPI from 'api/merchant';
import * as patientAPI from 'api/patient';
import Alert from 'common/Alert';
import { AlertStatusType } from 'interfaces/alert';
import { IComposeError } from 'interfaces/http';
import { IMerchantKey } from 'interfaces/merchant';
import {
  IPatientAddNewCardForm,
  IPatientAddNewCardSchema,
  IPatientPaymentDetail,
} from 'interfaces/patient';
import CardPaymentForm from 'pages/payment/CardPaymentForm';
import { AddNewCard } from 'schemas/patient';
import { selectPatient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { selectSource } from 'stores/online-assessment';
import { getFullName } from 'utils/common';

import PaymentDetail from './PaymentDetail';

const defaultValues = {
  cardToken: '',
};

const PaymentDetails = () => {
  const [paymentDetails, setPatientDetails] = useState<IPatientPaymentDetail[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toggleFetchPaymentDetails, setToggleFetchPaymentDetails] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [check, setCheck] = useState<boolean>(false);
  const [showCardInformation, setShowCardInformation] =
    useState<string>('none');
  const [merchantKey, setMerchantKey] = useState<IMerchantKey | null>(null);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });
  const [cardAddDeleteAlertMessage, setCardAddDeleteAlertMessage] = useState<{
    message: string;
    status: AlertStatusType;
  }>({
    message: '',
    status: 'error',
  });
  const [resetAddNewCardForm, setResetAddNewCardForm] =
    useState<boolean>(false);

  const patient = useAppSelector(selectPatient);
  const source = useAppSelector(selectSource);

  const methods = useForm<IPatientAddNewCardForm>({
    defaultValues,
    resolver: yupResolver(AddNewCard),
  });

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      setIsLoading(true);
      const response = await patientAPI.getPaymentDetails();
      setPatientDetails(response.data.rows);
      setIsLoading(false);
    };

    fetchPaymentDetails();
  }, [toggleFetchPaymentDetails]);

  // Fetch merchant key (for stripe)
  useEffect(() => {
    const fetchMerchantKey = async () => {
      setIsLoading(true);
      const response = await merchantAPI.getMerchantKey(source);
      setMerchantKey(response.data);
      setIsLoading(false);
    };

    if (source) {
      fetchMerchantKey();
    }
  }, [source]);

  const stripePromise = useMemo(() => {
    if (merchantKey?.publishableKey) {
      return loadStripe(merchantKey.publishableKey);
    }
    return null;
  }, [merchantKey]);

  const onSubmit = async (data: IPatientAddNewCardForm) => {
    const payload: IPatientAddNewCardSchema = {
      cardToken: data.cardToken,
      isPrimary: check,
    };

    try {
      setIsSubmitting(true);
      const response = await patientAPI.addPatientNewCard(payload);
      setShowCardInformation('none');
      setToggleFetchPaymentDetails((prev) => !prev);

      setAlertMessage({
        message: response?.message,
        status: 'success',
      });
      methods.reset(defaultValues);
      setResetAddNewCardForm((prev) => !prev);
      setCheck(false);
    } catch (error) {
      setAlertMessage({
        message: (error as IComposeError)?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearAlertMessage = () => {
    setAlertMessage({
      message: '',
      status: 'error',
    });
    setCardAddDeleteAlertMessage({
      message: '',
      status: 'error',
    });
  };

  return (
    <Box>
      <Alert
        autoClose
        mb={2}
        mt={5}
        onClose={clearAlertMessage}
        status={cardAddDeleteAlertMessage.status}
        text={cardAddDeleteAlertMessage.message}
      />
      <Heading mb={4} size="heading2">
        Payment Details
      </Heading>

      <Stack spacing={2}>
        {patient &&
          paymentDetails?.map((item) => (
            <PaymentDetail
              data={item}
              key={item.id}
              merchantCardId={item.id}
              patientName={getFullName(patient.firstName, patient.lastName)}
              paymentType={item.paymentType}
              setCardAddDeleteAlertMessage={setCardAddDeleteAlertMessage}
              setToggleFetchPaymentDetails={setToggleFetchPaymentDetails}
            />
          ))}
      </Stack>

      <Alert
        autoClose
        mb={2}
        mt={5}
        onClose={clearAlertMessage}
        status={alertMessage.status}
        text={alertMessage.message}
      />
      <Box display={showCardInformation}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box mt={6}>
              <Elements stripe={stripePromise}>
                <CardPaymentForm resetForm={resetAddNewCardForm} />
              </Elements>
              <Box display="flex" mt={4}>
                <Checkbox
                  isChecked={check}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setCheck(true);
                    } else {
                      setCheck(false);
                    }
                  }}
                  size="lg"
                >
                  <Text ml={2} size="bodyTextMediumSemibold">
                    Make this my primary payment method
                  </Text>
                </Checkbox>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button
                  onClick={() => {
                    setShowCardInformation('none');
                    setResetAddNewCardForm((prev) => !prev);
                    setCheck(false);
                  }}
                  variant="primaryOutlinedLg"
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={!methods.getValues('cardToken')}
                  isLoading={isSubmitting}
                  ml={4}
                  type="submit"
                  variant="primaryLg"
                >
                  Save Card
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
      <Box display="flex">
        <Text
          _hover={{ textDecoration: 'underline' }}
          color="blue"
          cursor="pointer"
          display={
            showCardInformation === 'block' || paymentDetails?.length < 1
              ? 'none'
              : 'block'
          }
          onClick={() => setShowCardInformation('block')}
          pl={1}
          pt={2}
          size="bodyTextMediumSemibold"
          w="fit-content"
        >
          + Add Card
        </Text>
      </Box>

      {!isLoading && paymentDetails?.length < 1 && (
        <Box
          sx={{
            borderRadius: '4px',
            padding: 4,
            background: 'neutral.lighter',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            color="text.faded"
            size="bodyTextSmallSemibold"
            textAlign="center"
          >
            No payment details found.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default PaymentDetails;
